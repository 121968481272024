import React from 'react'
import PropTypes from 'prop-types'

import CheckIcon from '../../Svg/CheckIcon'
import classNames from 'classnames/bind'


const ButtonAddTask = ({ active, classActive, prev, next, onClick, type, ...props }) => {
  const content = <button
      type={type}
      {...props}
      className={classNames('button_check', { active })}
      onClick={onClick}>
      <CheckIcon style={active ? {opacity: '1'}:{opacity: '0'}}/>
      <div style={active ? {opacity: '0'}:{opacity: '1'}} className='check_dummy'></div>
    </button>
  return content
}

ButtonAddTask.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool
}

ButtonAddTask.defaultProps = { 
  type: 'button',
  onClick: () => {}
}

export default ButtonAddTask