import React from 'react'


class CurrencyPicker extends React.Component {


    render () {
        const { onClick, currencyes } = this.props
        return (
            <ul>
                {currencyes.map(currency => (
                    <li key={currency.id}
                        onClick={onClick.bind(this,currency.id)}
                    >
                        <span className="symbol"> {currency.symbol} </span>
                        <span className="name"> {currency.key} </span>
                    </li>
                ))}
            </ul>
        )
    }
}

export default CurrencyPicker