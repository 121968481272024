import React, { Component } from 'react';
import TextField from "@material-ui/core/TextField";

import iconPassHide from '../../../assets/pass_hide_icon.svg'
import iconPassShow from '../../../assets/pass_show_icon.svg'

  class PasswordInput extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        passwordIsMasked: true,
        eyeClass: "icon-icon_eye_disabled",
      };
    }
    togglePasswordMask = () => {
      this.setState(prevState => ({
        passwordIsMasked: !prevState.passwordIsMasked,
        eyeClass: prevState.eyeClass === "icon-icon_eye_disabled"?"icon-icon_eye":"icon-icon_eye_disabled",
      }));
    };
  
    render() {
      const { passwordIsMasked } = this.state;
      const { 
        touched,
        errors,
        inputName,
        change,
        value,
        style,
        label
      } = this.props
      return (
        <div className="password__input" style={style}>
          <TextField
            id={inputName}
            name={inputName}
            type={passwordIsMasked ? 'password' : 'text'}
            label={label}
            className="auth_input"
            helperText={touched ? errors : ""}
            error={touched && Boolean(errors)}
            value={value}
            onChange={change.bind(null, inputName)}
            InputProps={{
              endAdornment:
                <span 
                style={{
                  position: 'absolute',
                  right: '0',
                  cursor: 'pointer'
                }}
                className={"password_eye_icon"} 
                onClick={this.togglePasswordMask}>
                  {passwordIsMasked ?
                    <img src={iconPassShow} alt="eye"/> 
                     : 
                     <img src={iconPassHide} alt="eye"/> }
                </span>
            }}
          />
        </div>
      );
    }
  }
  
  

export default PasswordInput;