import React from "react"
import Button from "../../../UI/Button"
import { ReactComponent as CheckIcon } from "../../../../assets/check_icon_blue.svg"

const UpdateSuccess = ({ onOk }) => {
  return (
    <div
      style={{
        maxWidth: "300px",
      }}
      className="update_success"
    >
      <CheckIcon className="update_success_text" />
      <p className="update_success_text">Update uploaded!</p>
      <Button
        className="button"
        type="button"
        color="primary"
        style={{ minWidth: "300px", marginBottom: "24px" }}
        onClick={onOk}
      >
        Ok
      </Button>
    </div>
  )
}

export default UpdateSuccess
