import React from 'react'
import PropTypes from 'prop-types'

import Sidebar from '../Sidebar'


const Layout = ({ content, ...props }) => (
  <main className="layout">
    <Sidebar />
    <div className="body" >
      {content}
    </div>
  </main>
)

Layout.propTypes = {
  content: PropTypes.oneOfType([PropTypes.node])
}

export default Layout

