import * as types from '../types'

const initialState = {
  isUserInfoCompleted: true,
  loading: false,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER_INFORMATION_COMPLETED_STATUS:
      return { ...state, isUserInfoCompleted: action.status }
    case types.COMPLETE_USER_INFORMATION_REQUEST:
      return { ...state, loading: true, error: null }
    case types.COMPLETE_USER_INFORMATION_SUCCESS:
      return { ...state, loading: false, isUserInfoCompleted: true }
    case types.COMPLETE_USER_INFORMATION_FAILURE:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}
