import React from "react"
import { TextField } from "@material-ui/core"

import FileInput from "../../../UI/FileInput"
import Button from "../../../UI/Button"
import Preloader from "../../../UI/Preloader"

export const Form = (props) => {
  const {
    values: { fileName, currentVersion, newVersion },
    errors,
    touched,
    isValid,
    setFieldValue,
    handleSubmit,
    handleChange,
    loading,
    errorsApi,
  } = props

  const onFileSelect = (e) => {
    const fileNameValue = e.target.files[0] ? e.target.files[0].name : ""
    setFieldValue("fileName", fileNameValue)
    setFieldValue("file", e.target.files[0])
  }

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        id="currentVersion"
        name="currentVersion"
        label="Current version"
        style={{ marginBottom: "14px" }}
        className="auth_input"
        helperText={touched.currentVersion ? errors.currentVersion : ""}
        error={touched.currentVersion && Boolean(errors.currentVersion)}
        value={currentVersion}
        disabled
      />
      <FileInput fileName={fileName} onChange={(e) => onFileSelect(e)} />
      {Boolean(errorsApi) && (
        <p className="auth_errors">
          {errorsApi.data.message || errorsApi.data.msg}
        </p>
      )}
      <TextField
        id="newVersion"
        name="newVersion"
        label="New version"
        style={{ marginBottom: "33px" }}
        className="auth_input"
        helperText={touched.newVersion ? errors.newVersion : ""}
        error={touched.newVersion && Boolean(errors.newVersion)}
        onChange={handleChange}
        value={newVersion}
        disabled={!fileName}
      />
      <Button
        className="button"
        type="submit"
        color="primary"
        style={{ minWidth: "300px", marginBottom: "24px" }}
        disabled={!isValid || loading || !fileName}
      >
        {loading ? <Preloader mini /> : "Start updating"}
      </Button>
    </form>
  )
}
