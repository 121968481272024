import React from 'react'
import PropTypes from 'prop-types'
import {Tooltip} from 'react-tippy'

import TaskDetails from '../../../UI/TaskDetails'
import classNames from 'classnames/bind'

import { getCurrencyInfoFromId } from '../../../../helpers'

const WeekModule = ({ 
  children, 
  date, 
  time, 
  money, 
  onClick, 
  value, 
  ratio,
  disabled, 
  tasksInfo, 
  taskSettingsView, 
  taskId, 
  week,
  mode,
  currencyes,
  selectedcurrency,
  taskOnClick,
  ...props }) => {

  return (
  <div
    {...props}
    className={classNames('week_module report', { 'dummy':!tasksInfo.length }, {disabled})}
    onClick={onClick}>
    <div className = 'week_module_date_placeholder'>
      <span className="week_module_date">Week {date}</span>
      <div className="flex flex-v">
        {mode === 'time' && (value[0] === '--' ? <span className="week_module_content_label">—</span> : <div className='week_module_content'>
        <span>
          {value[0]}<span className="week_module_content_label">h </span>
        </span>
        <span>
          {value[1]}<span className="week_module_content_label">m</span>
        </span>
      </div>)}
      </div>
      {mode === 'money' && (
        week.totalPaymentByCurrencies.length ? 
        week.totalPaymentByCurrencies.map(currency => {
          return <div key={currency.currencyId} className='week_module_content'>
            { 
              <span>
                <span className="week_module_content_label">{getCurrencyInfoFromId(currency.currencyId).symbol}</span>
                <span>{currency.value}</span>
              </span>
            }
          </div>
          }) : <span className="week_module_content_label">—</span>
      )}
    </div>
    {
      tasksInfo[0] &&
      <div 
        className='week_module_data_placeholder'
        style={{flexBasis: 'calc((100% - 119px)*'+ratio+')'}}
      >
        {tasksInfo.map((task,index) => { 
          const html = <TaskDetails 
            taskIcon = {task.task.icon}
            taskTime = {task.totalTime}
            taskTitle = {task.task.name}
            billable = {task.task.billable}
            priority = {task.task.highPriority}
            taskRelativeValue = {mode === 'time' ? task.totalTimeRatio:task.totalPaymentRatio}
            taskColor = {task.task.color}
            style = {{margin: '-8px'}}
            taskObj = {task}
            mode = {mode}
            currencyes = {currencyes}
          />
          return <Tooltip
                title="datepicker"
                position="top"
                html={html}
                arrow={true}
                followCursor={true}
                key={index}
                className='week_module_task_display_block'
                style={{
                  flexBasis:mode === 'time' ? 
                    task.totalTimeRatio*100+'%':
                    ((task.totalPaymentRatio*100<1 && task.totalPaymentRatio*100>0) ?
                      '1%':
                      task.totalPaymentRatio*100+'%'),
                  background: task.task.color
                  }}
              >
              <div
                style={{ height: '100%', cursor: 'pointer' }}
                onClick={taskOnClick.bind(this, { taskId: task.task.id, week: week})}
              >
              </div>
          </Tooltip>
          }
          ) 
        }
      </div>
    }
    {!tasksInfo[0] &&
      <div 
        className='week_module_data_placeholder'
        style={{flexBasis: 'calc((100% - 119px) * 1)'}}
      ></div>}
  </div>

)
}

WeekModule.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  date: PropTypes.number,
  time: PropTypes.bool,
  money: PropTypes.bool,
  content: PropTypes.array,
  taskOnClick: PropTypes.func
}

WeekModule.defaultProps = { 
  onClick: () => {},
  taskOnClick: () => {}
}

export default WeekModule
