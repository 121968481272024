import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars'

import TaskDetails from '../../../UI/TaskDetails'
import clockIcon from '../../../../assets/clock.svg'
import moneyIcon from '../../../../assets/money_icon.svg'
import { getCurrencyInfoFromId } from '../../../../helpers'
import * as appActions from '../../../../App/actions'


class TasksPanel extends React.Component {
    
    handleClick = payload => {
        this.props.setTasksLoading()
        this.props.history.push("tasksettings/"+payload.taskId)
    }

    render () {
        const { mode, currencyes, pickedDay, pickedDayObj } = this.props
        const date = {
            date: moment(pickedDay,'YYYY-MM-DD').date(), 
            month: moment(pickedDay,'YYYY-MM-DD').format('MMMM')}
        const time = pickedDayObj.totalTime ? pickedDayObj.totalTime : [0,0]
        const tasks = pickedDayObj.tasksInfo
        return (
            <div className="tasks_panel_placeholder">
                <div className='tasks_panel_info'>
                    <span className='tasks_panel_selected_day'>
                        {date.date}&nbsp;
                        <span className="mutted">
                            {date.month}
                        </span>
                    </span>
                    {mode === 'time' ?                     
                        <span className='tasks_panel_selected_hours'>
                            <img src={clockIcon} alt="clock-icon" style={{marginRight: '8px'}}/>
                            {time[0]}<span className="mutted">h&nbsp;</span>
                            {time[1]}<span className="mutted">m&nbsp;</span>
                        </span> : 
                        <span className='tasks_panel_selected_hours'>
                            <img src={moneyIcon} alt="clock-icon" style={{marginRight: '8px'}}/>
                            <div style={{textAlign: 'left'}}>
                                {pickedDayObj && pickedDayObj.totalPaymentByCurrencies.map(currency => {
                                    if (currency) {
                                        return <p key={currency.currencyId}><span className="mutted">{getCurrencyInfoFromId(currency.currencyId).symbol}</span>{currency.value}</p>   
                                    } else {
                                        return ''
                                    }
                                })}
                            </div>
                        </span>
                    }
                </div>
                {tasks && tasks.length > 9 ? 
                    <Scrollbars 
                        renderTrackVertical={props => <div 
                            {...props} 
                            style={{
                                ...props.style, 
                                backgroundColor: 'rgba(255,255,255,0.1)', 
                                width:'3px',
                                right: '4px',
                                height: '100%',
                                borderRadius: '2px'
                            }}/>}
                        renderThumbVertical={props => <div {...props} style={{...props.style, backgroundColor: 'rgba(255,255,255,0.2)', borderRadius: '2px'}}/>}
                        style={{height: '520px', width: 'calc(100% + 10px)'}}>
                        <ul style={{listStyle: 'none', width: 'calc(100% - 10px)'}}>
                            {tasks && tasks.map((task,index) => <li key={task.task.id}>
                                {
                                    task &&
                                    <TaskDetails 
                                        taskIcon = {task.task.icon}
                                        taskTime = {task.totalTime}
                                        taskTitle = {task.task.name}
                                        billable = {task.task.billable}
                                        priority = {task.task.highPriority}
                                        taskRelativeValue = {task.totalTimeRatio}
                                        taskColor = {task.task.color}
                                        taskId =  {task.task.id}
                                        taskObj = {task}
                                        style = {{cursor: "default"}}
                                        mode={mode}
                                        currencyes={currencyes}
                                    />
                                }
                                </li>
                            )}
                        </ul>
                    </Scrollbars> :
                    <ul style={{listStyle: 'none', width: '300px'}}>
                        {tasks && tasks.map((task,index) => <li key={task.task.id}>
                            {
                                task &&
                                <TaskDetails 
                                    taskIcon = {task.task.icon}
                                    taskTime = {task.totalTime}
                                    taskTitle = {task.task.name}
                                    billable = {task.task.billable}
                                    priority = {task.task.highPriority}
                                    taskRelativeValue = {task.totalTimeRatio}
                                    taskColor = {task.task.color}
                                    taskId =  {task.task.id}
                                    taskObj = {task}
                                    style = {{cursor: "default"}}
                                    mode={mode}
                                    currencyes={currencyes}
                                />
                            }
                            </li>
                        )}
                    </ul>
                }
            </div>
        )
    }
}


const mapStateToProps = state => ({
    appState: state.app.appState,
    timeSheetView: state.app.appState.timeSheetView,
    database: state.app.database,
    pickedDay: state.app.appState.pickedDay,
    pickedDayObj: state.app.appState.pickedDayObj,
    mode: state.filtersPanel.tasksDisplayMode,
    currencyes: state.app.database.currencies
  })

const mapDispatchToProps = dispatch => ({ 
    selectTask: data => dispatch(appActions.selectTask(data)),
    getReportTasksRequest: () => dispatch(appActions.getReportTasksRequest()),
    setTasksLoading: () => dispatch(appActions.setTasksLoading())
})

export default withRouter(connect(
        mapStateToProps,
        mapDispatchToProps
    )(TasksPanel))
