import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames/bind'

import { getCurrencyInfoFromId } from '../../../helpers'

const WeekModule = ({ 
  children, 
  date, 
  time, 
  money, 
  onClick, 
  value, 
  ratio,
  disabled, 
  report, 
  tasksInfo, 
  taskSettingsView, 
  taskId, 
  week,
  mode,
  currencyes,
  selectedcurrency,
  ...props }) => {

  return (
  <div
    {...props}
    className={classNames('week_module', { 'dummy':!value }, {disabled}, {report})}
    onClick={onClick}>
    <div className = 'week_module_date_placeholder'>
      <span className="week_module_date">Week {date}</span>
      <div className="flex flex-v">
        {mode === 'time' && ((value[0] === '--' && value[1] === '--') ? <span className="week_module_content_label">—</span> : <div className='week_module_content'>
          <span>
            {value[0]}<span className="week_module_content_label">h </span>
          </span>
          <span>
            {value[1]}<span className="week_module_content_label">m</span>
          </span>
        </div>)}
      </div>
      {mode === 'money' && (
        week.totalPaymentByCurrencies.length ? 
        week.totalPaymentByCurrencies.map(currency => {
          return <div key={currency.currencyId} className='week_module_content'>
            { 
              <span>
                <span className="week_module_content_label">{getCurrencyInfoFromId(currency.currencyId).symbol}</span>
                <span>{currency.value}</span>
              </span>
            }
          </div>
          }) : <span className="week_module_content_label">—</span>
      )}
    </div>
  </div>
  )
}

WeekModule.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  date: PropTypes.number,
  time: PropTypes.bool,
  money: PropTypes.bool,
  content: PropTypes.array
}

WeekModule.defaultProps = { 
  onClick: () => {}
}

export default WeekModule