import React from 'react'
import PropTypes from 'prop-types'
import Truncate from 'react-truncate'
import { Tooltip } from 'react-tippy'

import { ICONS } from '../../../../constants'
import ButtonAddTask from '../../../UI/ButtonAddTask'

import classNames from 'classnames/bind'


class TaskDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: this.props.active
    }
  }

  componentDidMount = () => {
    this.setState({
      checked: true
    })
  }

  handleClick= () => {
    this.setState({
      checked: !this.state.checked
    })
  }

  render() {
    const {
      onClick,
      taskObj,
      active,
      style} = this.props
    return (
<div 
    style={style}
    className={classNames("task_details",{taskTime: "dummy"})}
    onClick={onClick.bind(this,{taskId:taskObj.id,taskObj:taskObj})}
  >
    <div className="task_details_placeholder">
      <div className="task_details_left">
        {ICONS[taskObj.icon]}
        {taskObj.name.length > 25 ? 
          <Tooltip 
          interactive={true}
          html={
            <p className="task_details_title" style={{width: '200px'}}>
                {taskObj.name}
            </p>
          }
          position={'top'}
          arrow={true}
          theme={'truncate-tooltip-filter'}
          style={{
            width: '136px',
            marginRight: '8px'
          }}
          >
            <Truncate
              lines={1}
              width={0} 
            >
              {taskObj.name}
            </Truncate>
          </Tooltip>
          : <p className="task_details_title">
            {taskObj.name}
            {!!taskObj.tag && <span>#{taskObj.tag}</span>}
            </p>}

      </div>
      <div className="task_deatils_right">
        <ButtonAddTask active={active} disabled={false}/>
      
        
      </div>
    </div>
    <div className="task_deatails_statusbar" style={{width: '100%', background: taskObj.color}}></div>
  </div>
    )
  }
}

TaskDetails.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func
}

TaskDetails.defaultProps = { 
  onClick: () => {}
}

export default TaskDetails