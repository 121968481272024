import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import PasswordForm from './components'
import SignInForm from '../SignIn/components'

import Logo from '../../../assets/logo.svg'


class PasswordChange extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLinkSubmitted: false
    }
  }

  componentDidUpdate = () => {
    const {error} = this.props 
    if (error) {
      this.props.history.push('/error?error='+encodeURI(error))
    }
  }

  render() {
    const { changeSuccess } = this.props
    const message = changeSuccess ? 
      'You can now log in with your new password' : 
      'Enter your new password'
    const title = changeSuccess ? 'Password changed' : 'Password reset'
    return (
      <main className="layout">
        <div className="auth">
          <div className="auth_placeholder">
            <img src={Logo} alt="logo" className="logo auth_logo"/>
            <p className="auth_intro" style={{marginBottom: '26px'}}>{title}</p>
            <p style={{marginBottom: '43px'}}>{message}</p>
            {changeSuccess ? <SignInForm /> : <PasswordForm />}
            {!changeSuccess && <Link to="/auth">Back to Log In</Link>
            }
            <p style={{marginTop: 'auto'}}>
              Don’t have an account?&nbsp;
              <Link to="/register">
                Sign up
              </Link>
            </p>
          </div>
        </div>
      </main>
    )
  }
}

const mapStateToProps = state => ({
  changeSuccess: state.passwordChange.success,
  error: state.passwordChange.error
})
const mapDispatchToProps = dispatch => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordChange)


