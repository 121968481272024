import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'react-tippy'

import TagCheckBox from '../../../UI/TagCheckBox'
import Button from '../../../UI/Button'
import classNames from 'classnames/bind'

const PriorityControls = ({ onClick, filters, disabled, onTagSelect, ...props }) => {
  const [isOpenTagByHover, setIsOpenTagTagByHover] = useState(false)
  const [isOpenTagByClick, setIsOpenTagByClick] = useState(false)
  const clickOutside = () => {
    if (!isOpenTagByHover) {
      setIsOpenTagTagByHover(false)
      setIsOpenTagByClick(false)
    }
  }
  const isOpenTag = isOpenTagByClick || isOpenTagByHover
  return <div {...props} className={classNames('controls controls_secondary', {})}>
    <Button
      className={classNames('button', {})}
      active={filters.all}
      onClick={onClick.bind(this, 'all')}
    >
      All
    </Button>
    <Button
      className={classNames('button', {})}
      active={filters.priority}
      onClick={onClick.bind(this, 'priority')}
    >
      Priority
    </Button>
    <Button
      className={classNames('button', {})}
      active={filters.billable}
      onClick={onClick.bind(this, 'billable')}
    >
      Billable
    </Button>

      <Tooltip
          html={
            Object.keys(filters.tag).map(key => {
            return <TagCheckBox 
              key={key}
              tagTitle={key}
              isChecked={filters.tag[key]}
              onChange={() => onTagSelect(key)}
            />
            })
          }
          position="bottom"
          interactive={true}
          interactiveBorder={2}
          arrow={true}
          animation="perspective"
          theme='tag-filter'
          trigger='manual'
          open={isOpenTag}
          onRequestClose={clickOutside}
          disabled={!Object.keys(filters.tag).length}
          
        >  
          <div
            className={`controls_secondary_tooltip ${Object.keys(filters.tag).filter(key => filters.tag[key]).length ? 'selected' : ''}`}
            onClick={()=>setIsOpenTagByClick(!isOpenTagByClick)}
            onMouseEnter={()=>setIsOpenTagTagByHover(true)}
            onMouseLeave={()=>setIsOpenTagTagByHover(false)}
          >
            Tag
          </div>
      </Tooltip>
    
  </div>
}

PriorityControls.propTypes = {
  onClick: PropTypes.func,
  onTagSelect: PropTypes.func,
}

PriorityControls.defaultProps = {
  onClick: () => {},
  onTagSelect: () => {},
}

export default PriorityControls
