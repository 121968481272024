import * as types from '../types'

export const setTasksLoading = () => {
  return {
    type: types.SET_TASKS_LOADING
  }
}

export const setCurrentUser = user => ({
  type: types.SET_CURRENT_USER,
  user
})

export const logout = () => ({
  type: types.LOGOUT
})

export const saveToken = token => ({
  type: types.SAVE_TOKEN,
  payload: token
})

export const deleteAccount = () => ({
  type: types.DELETE_ACCOUNT,
})

export const deleteAccountSuccess = () => ({
  type: types.DELETE_ACCOUNT_SUCCESS
})

export const deleteAccountFailure = (error) => ({
  type: types.DELETE_ACCOUNT_FAILURE,
  payload: error
})

export const clearError = () => ({
  type: types.SET_ERROR
})

export const setRole = role => ({
  type: types.SET_ROLE,
  payload: role
})

export const autoSignIn = () => ({
  type: types.AUTO_SIGN_IN_REQUEST
})

export const autoSignInFailure = error => ({
  type: types.AUTO_SIGN_IN_FAILURE,
  payload: error
})

export const autoSignInSuccess = () => ({
  type: types.AUTO_SIGN_IN_SUCCESS
})

export const resetDateRange = () => {
  return {
    type: types.RESET_DATE_RANGE
  }
}

export const selectDaysToView = dates => ({
  type: types.SELECT_DAYS_TO_VIEW,
  payload: dates
})

export const switchTimeMoneyMode = () => ({
  type: types.SWITCH_TIME_MONEY_MODE
})

export const getReportTasksRequest = data => ({
  type: types.GET_REPORT_TASKS_INFO_REQUEST,
  data
})

export const getReportTasks = data => {
  return {
    type: types.GET_REPORT_TASKS_INFO,
    data
  }
}

export const getReportTasksFailure = error => ({
  type: types.GET_REPORT_TASKS_INFO_FAILURE,
  payload: error
})

export const getReportTasksSuccess = dateRange => ({
  type: types.GET_REPORT_TASKS_INFO_SUCCESS,
  dateRange
})

export const setReportTasksEmpty = () => ({
  type: types.SET_REPORT_TASKS_EMPTY
})

export const getReportWeeklyFilter = ids => ({
  type: types.GET_REPORT_WEEKLY_FILTER,
  ids
})

export const getReportTasksSettings = data => {
  return {
    type: types.GET_REPORT_TASKS_SETTINGS,
    data
  }
}

export const getReportTasksSettingsFailure = error => ({
  type: types.GET_REPORT_TASKS_SETTINGS_FAILURE,
  payload: error
})

export const getReportTasksSettingsSuccess = dateRange => ({
  type: types.GET_REPORT_TASKS_SETTINGS_SUCCESS,
  dateRange
})

export const selectTask = payload => ({
  type: types.SELECT_TASK,
  payload: {
    taskId: payload.taskId,
    taskObj: payload.taskObj
  }
})

export const setSettingsAppLoading = () => ({
  type: types.SET_SIDEBAR_LOADING
})

export const setCurrencyes = currencyes => ({
  type: types.SET_CURRENCYES,
  currencyes
})

export const getSettingsTaskDaily = data => ({
  type: types.GET_SETTINGS_TASK_DAILY,
  data
})

export const getTaskById = id => ({
  type: types.GET_TASK_BY_ID,
  id
})
export const getTaskByIdFailure = error => ({
  type: types.GET_TASK_BY_ID_FAILURE,
  error
})
export const getTaskByIdSuccess = task => ({
  type: types.GET_TASK_BY_ID_SUCCESS,
  task
})

export const getAllTasks = () => ({
  type: types.GET_ALL_TASKS
})
export const getAllTasksSuccess = data => ({
  type: types.GET_ALL_TASKS_SUCCESS,
  data: data.data
})
export const getAllTasksFailure = error => ({
  type: types.GET_ALL_TASKS_FAILURE,
  error
})

export const setEditTaskPriorityLoading = () => ({
  type: types.SET_EDIT_TASK_PRIORITY_LOADING
})

export const setEditTaskNameLoading = () => ({
  type: types.SET_EDIT_TASK_NAME_LOADING
})
export const setEditTaskIconLoading = () => ({
  type: types.SET_EDIT_TASK_ICON_LOADING
})
export const setEditTaskColorLoading = () => ({
  type: types.SET_EDIT_TASK_COLOR_LOADING
})
export const setEditTaskCurrencyLoading = () => ({
  type: types.SET_EDIT_TASK_CURRENCY_LOADING
})
export const setEditTaskHourlyRateLoading = () => ({
  type: types.SET_EDIT_TASK_HOURLY_RATE_LOADING
})
export const setEditTaskPomodoroTimeLoading = () => ({
  type: types.SET_EDIT_TASK_POMODORO_TIME_LOADING
})
export const setEditTaskTagLoading = () => ({
  type: types.SET_EDIT_TASK_TAG_LOADING
})

export const setTaskSettingsViewModeWeek = () => ({
  type: types.SET_TASK_SETTINGS_VIEW_MODE_WEEK
})
export const setTaskSettingsViewModeDay = () => ({
  type: types.SET_TASK_SETTINGS_VIEW_MODE_DAY
})
