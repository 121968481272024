import React from 'react'

import noDataIcon from '../../assets/noData.svg'


class NoData extends React.Component {

    render () {
        return (
            <div className="no_data_placeholder">
                <img className='no_data_placeholder_img' src={noDataIcon} alt='no data'/>
                <p className='no_data_placeholder_title'>Sorry, no data to display...</p>
                <p className='no_data_placeholder_text'>Try a different time range, or check filters</p>

            </div>
        )
    }
}

export default NoData
