import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { getCurrencyInfoFromId } from '../../../helpers'
import classNames from 'classnames/bind'


const DayModule = ({ children, date, dateObj, mode, timeSheetView, reportView, onClick, value, active, disabled, selectedcurrency, currencyes, ...props }) => {
  const onClickHandler = !disabled ? onClick.bind(this,{date,dateObj}) : ()=>{}
  let displayValue = ''
  if (mode === 'time') {
    if (value[0] !== '--')  {
      if (timeSheetView ) {
        displayValue = <div className='day_module_content'>
            <span>
              {value[0]}<span className="day_module_content_label">h </span>
            </span>
            <span>
              {value[1]}<span className="day_module_content_label">m</span>
            </span>
          </div>
      } else if (reportView ) {
        displayValue = <span className="day_module_content">{value}</span>
      }
    } else {
      displayValue = <span className="day_module_content_label">—</span>
    }
  } else {
    displayValue = dateObj.totalPaymentByCurrencies.length ?
        dateObj.totalPaymentByCurrencies.map(currency => {
          return <div key={currency.currencyId} className='day_module_content'> 
            <span>
              <span className="day_module_content_label">{getCurrencyInfoFromId(currency.currencyId).symbol}</span>
              {currency.value}
            </span>
          </div>
        }) : <span className="day_module_content_label">—</span>
  }

  return (
  <div
    {...props}
    className={classNames('day_module', { 'dummy':!value }, {disabled}, {active})}
    onClick={onClickHandler}
    >
    <span className="day_module_date">{moment(date).format('D')}</span>
    <div className="flex flex-v">
      {displayValue}
    </div>
  </div>

)}

DayModule.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  date: PropTypes.string,
  time: PropTypes.bool,
  money: PropTypes.bool,
  content: PropTypes.array
}

DayModule.defaultProps = { 
  onClick: () => {}
}

export default DayModule