import React from "react"
import { Formik } from "formik"
import { Form } from "./Form"

const UpdateForm = ({ currentVersion, onSubmit }) => {
  const values = { file: "", currentVersion, newVersion: "" }
  return (
    <div
      style={{
        maxWidth: "300px",
      }}
    >
      <Formik
        render={(props) => <Form {...props} />}
        initialValues={values}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            onSubmit({ file: values.file, version: values.newVersion })
            setSubmitting(false)
          }, 400)
        }}
      />
    </div>
  )
}

export default UpdateForm
