import React from 'react'
import PropTypes from 'prop-types'

import TimeSheetImg from '../../Svg/TimeSheetImg'
import ReportImg from '../../Svg/ReportImg'
import IntegrationsImg from '../../Svg/IntegrationsImg'
import LogOutImg from '../../Svg/LogOutImg'
import classNames from 'classnames/bind'
import DeleteIcon from '../../Svg/DeleteIcon'


const ButtonTimeSheet = ({ timesheet, report, integrations, remove, logout, children, active, onClick, type, ...props }) => (
  <button
    type={type}
    {...props}
    className={classNames('button_nav', { active })}
    onClick={onClick}>
    {timesheet && <TimeSheetImg active={active}/>}
    {report && <ReportImg active={active}/>}
    {integrations && <IntegrationsImg active={active}/>}
    {remove && <DeleteIcon active={active}/>}
    {logout && <React.Fragment>
      <LogOutImg active={active} />
      {children}
    </React.Fragment>
    }
  </button>
)

ButtonTimeSheet.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool
}

ButtonTimeSheet.defaultProps = { 
  type: 'button',
  onClick: () => {}
}

export default ButtonTimeSheet