import React from 'react'
import { connect } from 'react-redux'
import AutosizeInput from 'react-input-autosize'

import ButtonEdit from '../../../../../UI/ButtonEdit'
import Preloader from '../../../../../UI/Preloader'
import * as actions from '../../actions'

class EditTaskIcon extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      readonly: null,
      hourlyRate: '',
    }
  }

  componentDidMount = () => {
    this.setState({
      readonly: true,
      hourlyRate: this.props.hourlyRate || '',
    })
  }

  handleClick = () => {
    this.setState({
      readonly: false,
    })
  }
  handleSubmit = () => {
    const prevRate = this.props.hourlyRate
    const nextRate = parseFloat(String(this.state.hourlyRate)).toFixed(2)
    if (isNaN(nextRate) && prevRate === 0) {
      this.setState({
        readonly: true,
      })
      return false
    }
    if (prevRate !== nextRate) {
      this.props.editTaskHourlyRate(isNaN(nextRate) ? '0' : nextRate)
      this.setState({
        readonly: true,
        hourlyRate: isNaN(nextRate) ? '' : nextRate,
      })
    } else {
      this.setState({
        readonly: true,
      })
    }
  }

  handleOnChange = (value) => {
    const newValue = value.replace(/[^0-9.]/, '')
    this.setState({
      hourlyRate: newValue,
    })
  }

  render() {
    const { loading } = this.props

    return (
      <div className='task_settings_item'>
        <div className='task_settings_item_left'>
          <p className='task_settings_item_left_label'>Rate</p>
        </div>
        <div className='task_settings_item_right'>
          <div className='task_settings_item_form'>
            <AutosizeInput
              className='task_settings_item_form_name'
              name='form-field-name'
              readOnly={this.state.readonly}
              value={this.state.hourlyRate}
              type='text'
              onChange={(event) => this.handleOnChange(event.target.value)}
            />
            {loading && <Preloader mini />}
            {!loading &&
              (this.state.readonly ? (
                <ButtonEdit onClick={this.handleClick} />
              ) : (
                <ButtonEdit
                  active
                  onClick={this.handleSubmit}
                  disabled={this.state.isSubmitting}
                />
              ))}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  hourlyRate: state.app.appState.selectedTaskToEditObj.task.hourlyRate,
  currencyId: state.app.appState.selectedTaskToEditObj.task.currencyId,
  loading: state.app.appState.editHourlyRateLoading,
})

const mapDispatchToProps = (dispatch) => ({
  editTaskHourlyRate: (newHourlyRate) =>
    dispatch(actions.editTaskHourlyRate(newHourlyRate)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditTaskIcon)
