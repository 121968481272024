import { takeEvery, select, put, call, all } from 'redux-saga/effects'

import * as types from '../types'

import * as actions from '../actions'
import * as appActions from '../../../../../App/actions'
import Api from '../../../../../services/Api'
import { handleSagaError } from '../../../../../helpers'
import { API_ENDPOINTS } from '../../../../../constants/index'

function* editTaskTimeAsync(time) {
  try {
    yield put(appActions.setTasksLoading())
    const selectedDateRange = yield select(
      state => state.filtersPanel.selectedDateRange
    )
    const taskId = yield select(state => state.app.appState.selectedTaskToEdit)
    const query = {
      beginDate: selectedDateRange[0],
      endDate: selectedDateRange[1],
      taskId: taskId,
      time: time.payload
    }
    yield call(Api.put, API_ENDPOINTS.tasksAllocateTime, '', query)

    const viewModeWeekOrDay = yield select(
      state => state.app.appState.taskSettingsViewMode
    )
    if (viewModeWeekOrDay === 'day') {
      yield put(appActions.getSettingsTaskDaily(taskId))
    } else {
      yield put(appActions.getReportTasksSettings(taskId))
    }

    yield put(actions.editTaskTimeSuccess())
  } catch (error) {
    yield handleSagaError(error, actions.editTaskTimeError(error))
  }
}
function* editTaskPriorityAsync(data) {
  try {
    yield put(appActions.setEditTaskPriorityLoading())
    const taskId = yield select(state => state.app.appState.selectedTaskToEdit)
    yield call(
      Api.put,
      'tasks/' + taskId + '/newIsHighPriority/' + String(data.payload)
    )
    yield put(appActions.getTaskById(taskId))
    yield put(actions.editTaskPrioritySuccess())
  } catch (error) {
    yield handleSagaError(error, actions.editTaskPriorityError(error))
  }
}

function* editTaskBillableAsync(data) {
  try {
    yield put(appActions.setEditTaskPriorityLoading())
    const taskId = yield select(state => state.app.appState.selectedTaskToEdit)
    yield call(
      Api.put,
      'tasks/' + taskId + '/newIsBillable/' + String(data.payload)
    )
    yield put(appActions.getTaskById(taskId))
    yield put(actions.editTaskBillableSuccess())
  } catch (error) {
    yield handleSagaError(error, actions.editTaskBillableError(error))
  }
}

function* editTaskNameAsync(data) {
  try {
    yield put(appActions.setEditTaskNameLoading())
    const taskId = yield select(state => state.app.appState.selectedTaskToEdit)
    yield call(Api.put, 'tasks/' + taskId + '/newName', { name: data.payload })
    yield put(actions.editTaskNameSuccess())
    yield put(appActions.getTaskById(taskId))
  } catch (error) {
    yield handleSagaError(error, actions.editTaskNameError(error))
  }
}
function* editTaskIconAsync(data) {
  try {
    yield put(appActions.setEditTaskIconLoading())
    const taskId = yield select(state => state.app.appState.selectedTaskToEdit)
    yield call(Api.put, 'tasks/' + taskId + '/newIcon/' + data.payload)
    yield put(actions.editTaskIconSuccess())
    yield put(appActions.getTaskById(taskId))
  } catch (error) {
    yield handleSagaError(error, actions.editTaskIconError(error))
  }
}
function* editTaskColorAsync(data) {
  try {
    yield put(appActions.setEditTaskColorLoading())
    const taskId = yield select(state => state.app.appState.selectedTaskToEdit)
    yield call(Api.put, 'tasks/' + taskId + '/newColor', {
      color: String(data.payload)
    })
    yield put(actions.editTaskColorSuccess())
    yield put(appActions.getTaskById(taskId))
  } catch (error) {
    yield handleSagaError(error, actions.editTaskColorError(error))
  }
}
function* editTaskCurrencyAsync(data) {
  try {
    yield put(appActions.setEditTaskCurrencyLoading())
    const taskId = yield select(state => state.app.appState.selectedTaskToEdit)
    yield call(Api.put, 'tasks/' + taskId + '/newCurrencyId/' + data.payload)
    yield put(actions.editTaskCurrencySuccess())
    yield put(appActions.getTaskById(taskId))
  } catch (error) {
    yield handleSagaError(error, actions.editTaskCurrencyError(error))
  }
}
function* editTaskHourlyRateAsync(data) {
  const taskId = yield select(state => state.app.appState.selectedTaskToEdit)
  try {
    yield put(appActions.setEditTaskHourlyRateLoading())
    yield call(Api.put, 'tasks/' + taskId + '/newHourlyRate/' + data.payload)
    yield put(actions.editTaskHourlyRateSuccess())
    yield put(appActions.getTaskById(taskId))
  } catch (error) {
    yield put(appActions.getTaskById(taskId))
    yield handleSagaError(error, actions.editTaskHourlyRateError(error))
  }
}

function* editTaskPomodoroStatusAsync(data) {
  try {
    const taskId = yield select(state => state.app.appState.selectedTaskToEdit)
    if (data.payload) {
      yield put(actions.editTaskPomodoroTime(25))
      yield put(appActions.getTaskById(taskId))
    } else {
      yield put(appActions.setEditTaskPriorityLoading())
      yield call(Api.put, `tasks/${taskId}/newIsPomodoro/${data.payload}`)
      yield put(actions.editTaskPomodoroStatusSuccess())
      yield put(appActions.getTaskById(taskId))
    }
  } catch (error) {
    yield handleSagaError(error, actions.editTaskPomodoroStatusError(error))
  }
}

function* editTaskPomodoroTimeAsync(data) {
  try {
    yield put(appActions.setEditTaskPomodoroTimeLoading())
    const taskId = yield select(state => state.app.appState.selectedTaskToEdit)
    yield call(Api.put, `tasks/${taskId}/newPomodoroTime/${data.payload}`)
    yield put(actions.editTaskPomodoroTimeSuccess())
    yield put(appActions.getTaskById(taskId))
  } catch (error) {
    yield handleSagaError(error, actions.editTaskPomodoroTimeError(error))
  }
}

function* editTaskTagAsync(data) {
  try {
    yield put(appActions.setEditTaskTagLoading())
    const taskId = yield select(state => state.app.appState.selectedTaskToEdit)
    yield call(Api.put, 'tasks/' + taskId + '/newTag', { tag: data.payload })
    yield put(actions.editTaskTagSuccess())
    yield put(appActions.getTaskById(taskId))
  } catch (error) {
    yield handleSagaError(error, actions.editTaskTagError(error))
  }
}

export default function* editTaskSaga() {
  yield all([
    takeEvery(types.EDIT_TASK_TIME, editTaskTimeAsync),
    takeEvery(types.EDIT_TASK_PRIORITY, editTaskPriorityAsync),
    takeEvery(types.EDIT_TASK_BILLABLE, editTaskBillableAsync),
    takeEvery(types.EDIT_TASK_NAME, editTaskNameAsync),
    takeEvery(types.EDIT_TASK_ICON, editTaskIconAsync),
    takeEvery(types.EDIT_TASK_COLOR, editTaskColorAsync),
    takeEvery(types.EDIT_TASK_CURRENCY, editTaskCurrencyAsync),
    takeEvery(types.EDIT_TASK_HOURLY_RATE, editTaskHourlyRateAsync),
    takeEvery(
      types.EDIT_TASK_POMODORO_STATUS_REQUEST,
      editTaskPomodoroStatusAsync
    ),
    takeEvery(types.EDIT_TASK_POMODORO_TIME_REQUEST, editTaskPomodoroTimeAsync),
    takeEvery(types.EDIT_TASK_TAG_REQUEST, editTaskTagAsync)
  ])
}
