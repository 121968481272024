import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars'

import TaskDetails from '../../../UI/TaskDetails'
import { getCurrencyInfoFromId } from '../../../../helpers'
import clockIcon from '../../../../assets/clock.svg'
import moneyIcon from '../../../../assets/money_icon.svg'
import * as appActions from '../../../../App/actions'


class ReportTasksPanel extends React.Component {


    handleClick = payload => {
        this.props.setTasksLoading()
        this.props.setTaskSettingsViewModeWeek()
        this.props.history.push("tasksettings/"+payload.taskId)
    }

    render () {
        const { tasksInfoAvg, avgTime, avgPayment, mode, currencyes, selectedCurrency, loading } = this.props
        const avgTimeLoaded = avgTime ? avgTime : [0,0]
        let currentCurrencySymbol = ''
        Object.keys(selectedCurrency).map(currency => { // temp solution
            if (selectedCurrency[currency] && currency !== 'all') {
                const curr = currencyes.filter(val => {return val.key === currency})[0]
                if (curr && curr.symbol) {
                    currentCurrencySymbol = curr.symbol
                }
            }
            if (!currentCurrencySymbol) {
                currentCurrencySymbol = currencyes
            }
            return false
        })
        return (
            <div className="tasks_panel_placeholder">
                <div className='tasks_panel_info'>
                    <span className='report_tasks_panel_avg_label'>
                        <img src={mode === 'time' ? clockIcon : moneyIcon} alt="hours" style={{width:'20px', height:'20px',marginRight: '4px'}}/>
                        Average per day
                    </span>
                    {mode === 'time' ?
                    <span className='tasks_panel_selected_hours'>
                        <img src={clockIcon} alt="clock-icon" style={{marginRight: '8px'}}/>
                        {avgTimeLoaded[0]}<span className="mutted">h&nbsp;</span>
                        {avgTimeLoaded[1]}<span className="mutted">m&nbsp;</span>
                    </span>
                     : 
                     <div 
                     className='tasks_panel_selected_hours'
                     style={{
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                        }}
                     >
                        {avgPayment && avgPayment.map(currency =>                      
                            <p key={currency.currencyId} >
                                {!loading && 
                                    <span className="mutted">
                                        {getCurrencyInfoFromId(currency.currencyId).symbol}&nbsp;
                                    </span>
                                }
                                {currency.value}
                            </p>
                        )}
                     </div>
                     }

                </div>
                {tasksInfoAvg && tasksInfoAvg.length > 9 ? 
                <Scrollbars 
                    renderTrackVertical={props => <div 
                        {...props} 
                        style={{
                            ...props.style, 
                            backgroundColor: 'rgba(255,255,255,0.1)', 
                            width:'3px',
                            right: '4px',
                            height: '100%',
                            borderRadius: '2px'
                        }}/>}
                    renderThumbVertical={props => <div {...props} style={{...props.style, backgroundColor: 'rgba(255,255,255,0.2)', borderRadius: '2px'}}/>}
                    style={{height: '520px', width: 'calc(100% + 10px)'}}>
                    <ul style={{listStyle: 'none', width: 'calc(100% - 10px)'}}>
                        {tasksInfoAvg && tasksInfoAvg.map((task,index) => <li key={task.task.id}>
                            {
                                task.totalTime[0]!=='--' &&
                                <TaskDetails 
                                    taskIcon = {task.task.icon}
                                    taskTime = {task.totalTime}
                                    taskTitle = {task.task.name}
                                    billable = {task.task.billable}
                                    priority = {task.task.highPriority}
                                    taskRelativeValue = {task.totalTimeRatio}
                                    taskColor = {task.task.color}
                                    taskId =  {task.task.id}
                                    taskObj = {task}
                                    onClick={this.handleClick}
                                    mode={mode}
                                    currencyes={currencyes}
                                    selectedcurrency={selectedCurrency}
                                />
                            }
                            </li>
                        )}
                    </ul>
                </Scrollbars> : 
                <ul style={{listStyle: 'none'}}>
                    {tasksInfoAvg && tasksInfoAvg.map((task,index) => <li key={task.task.id}>
                        {
                            task.totalTime[0]!=='--' &&
                            <TaskDetails 
                                taskIcon = {task.task.icon}
                                taskTime = {task.totalTime}
                                taskTitle = {task.task.name}
                                billable = {task.task.billable}
                                priority = {task.task.highPriority}
                                taskRelativeValue = {task.totalTimeRatio}
                                taskColor = {task.task.color}
                                taskId =  {task.task.id}
                                taskObj = {task}
                                onClick={this.handleClick}
                                mode={mode}
                                currencyes={currencyes}
                                selectedcurrency={selectedCurrency}
                            />
                        }
                        </li>
                    )}
                </ul>
            }
            </div>
        )
    }
}


const mapStateToProps = state => ({
    loading: state.app.appState.loading,
    taskSettingsView: state.app.appState.taskSettingsView,
    timeSheetView: state.app.appState.timeSheetView,
    tasksInfoAvg: state.app.database.tasksInfoAvg,
    pickedDay: state.app.appState.pickedDayObj,
    avgTime: state.app.database.avgTime,
    mode: state.filtersPanel.tasksDisplayMode,
    avgPayment: state.app.database.avgPayment,
    currencyes: state.app.database.currencies,
    selectedCurrency: state.filtersPanel.filters.currencyes
  })

const mapDispatchToProps = dispatch => ({ 
    setTaskSettingsViewModeWeek: () =>
      dispatch(appActions.setTaskSettingsViewModeWeek()),
    selectTask: data => dispatch(appActions.selectTask(data)),
    getReportTasksRequest: () => dispatch(appActions.getReportTasksRequest()),
    setTasksLoading: () => dispatch(appActions.setTasksLoading())
})

export default withRouter(connect(
        mapStateToProps,
        mapDispatchToProps
    )(ReportTasksPanel))
