export const GET_USER_INFORMATION_COMPLETED_STATUS =
  'completeUserInformation/GET_USER_INFORMATION_COMPLETED_STATUS'
export const SET_USER_INFORMATION_COMPLETED_STATUS =
  'completeUserInformation/SET_USER_INFORMATION_COMPLETED_STATUS'

export const COMPLETE_USER_INFORMATION_REQUEST =
  'completeUserInformation/COMPLETE_USER_INFORMATION_REQUEST'
export const COMPLETE_USER_INFORMATION_SUCCESS =
  'completeUserInformation/COMPLETE_USER_INFORMATION_SUCCESS'
export const COMPLETE_USER_INFORMATION_FAILURE =
  'completeUserInformation/COMPLETE_USER_INFORMATION_FAILURE'
