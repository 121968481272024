import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import { Form } from './SignUpForm'
import * as Yup from 'yup'

import * as actions from '../actions'

const validationSchema = Yup.object({
  fullName: Yup.string('').required('Full name is required'),
  email: Yup.string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Password must contain at least 8 characters')
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      'Use large and small characters, as well as numbers and special characters like ? ! @ $ %'
    )
    .max(32, 'Password is too long')
    .required('Password is required')
})

class SignUpForm extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const values = { fullName: '', email: '', password: '' }
    const { signUp, loading, errorsApi } = this.props
    return (
      <React.Fragment>
        <div
          style={{
            maxWidth: '300px'
          }}>
          <Formik
            render={props => (
              <Form {...props} loading={loading} errorsApi={errorsApi} />
            )}
            initialValues={values}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                signUp(values)
                setSubmitting(false)
              }, 400)
            }}
          />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.signUp.loading,
  errorsApi: state.signUp.error
})

const mapDispatchToProps = dispatch => ({
  signUp: values => dispatch(actions.signUpFetch(values))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUpForm)
