import * as types from '../types'

export const getUserInfoCompletedStatus = () => ({
  type: types.GET_USER_INFORMATION_COMPLETED_STATUS
})
export const completeUserInfoRequest = info => ({
  type: types.COMPLETE_USER_INFORMATION_REQUEST,
  info
})
export const completeUserInfoSuccess = user => ({
  type: types.COMPLETE_USER_INFORMATION_SUCCESS,
  user
})
export const completeUserInfoFailure = error => ({
  type: types.COMPLETE_USER_INFORMATION_FAILURE,
  error
})
