import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as appActions from '../../App/actions'
import * as filtersActions from '../FiltersPanel/actions'

import ButtonNav from '../UI/ButtonNav'

import { GoogleLogout } from 'react-google-login'
import { GOOGLE_CLIENT_ID } from '../../constants'
import ReactModal from 'react-modal'

class Sidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      key: null,
      modalIsOpen: false,
    }
  }

  componentDidMount = () => {
    this.props.setTasksLoading()
  }

  handleClickTime = () => {
    const { mode, switchTimeMoneyMode, setSettingsAppLoading } = this.props
    setSettingsAppLoading()
    const history = this.props.history
    if (
      history.location.pathname !== '/report' &&
      history.location.pathname !== '/timesheet'
    ) {
      if (mode === 'money') {
        switchTimeMoneyMode()
      }
    }
    if (window.location.pathname !== '/timesheet') {
      this.props.history.push('/timesheet')
    } else {
      this.props.resetDateRange()
      this.props.getReportTasksRequest()
    }
  }
  handleClickReport = () => {
    const { mode, switchTimeMoneyMode, setSettingsAppLoading } = this.props
    const history = this.props.history
    if (
      history.location.pathname !== '/report' &&
      history.location.pathname !== '/timesheet'
    ) {
      if (mode === 'money') {
        switchTimeMoneyMode()
      }
    }
    if (window.location.pathname !== '/report') {
      setSettingsAppLoading()
      history.push('/report')
    }
  }

  handleClickIntegrations = () => {
    const { setSettingsAppLoading } = this.props
    const history = this.props.history
    if (window.location.pathname !== '/integrations') {
      setSettingsAppLoading()
      history.push('/integrations')
    }
  }

  handleOpenModal = () => {
    this.setState({ modalIsOpen: true });
  };

  handleCloseModal = () => {
    this.props.clearError();
    this.setState({ modalIsOpen: false });
  };

  render() {
    const { logout, deleteAccount, error } = this.props
    const { timeSheetView, reportView, integrationsView } = {
      timeSheetView: window.location.pathname === '/timesheet',
      reportView: ['/timesheet', '/integrations'].indexOf(window.location.pathname) === -1,
      integrationsView: window.location.pathname === '/integrations',
    }
    return (
      <>
        <aside className='sidebar'>
          <ButtonNav
            timesheet
            active={timeSheetView}
            onClick={this.handleClickTime}
            title={'Time Sheet'}
          />
          <ButtonNav
            report
            active={reportView}
            onClick={this.handleClickReport}
            title={'Report'}
          />
          <ButtonNav
            integrations
            active={integrationsView}
            onClick={this.handleClickIntegrations}
            title={'Integrations'}
          />
          <ButtonNav
            remove
            active={false}
            style={{ marginTop: 'auto' }}
            title={'Delete account'}
            onClick={this.handleOpenModal}
          />
          <ButtonNav
            logout
            active={false}
            onClick={logout}
            title={'Log out'}
          >
            <GoogleLogout
              clientId={GOOGLE_CLIENT_ID}
              buttonText='Logout'
              onLogoutSuccess={logout}
              render={(renderProps) => (
                <div
                  onClick={renderProps.onClick}
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: '0',
                    left: '0',
                  }}
                ></div>
              )}
            ></GoogleLogout>
          </ButtonNav>
        </aside>

        <ReactModal isOpen={this.state.modalIsOpen} overlayClassName={'modal-overlay'} className={'modal'} onRequestClose={this.handleCloseModal}>
          <div className="modal-title">Delete your account</div>
          <div className="modal-text">
            <p>We're sorry you're leaving</p>
            <p>Your Timeflip data will be permanently lost if you delete your account.</p>
          </div>
          <div className="modal-actions">
            <button onClick={this.handleCloseModal}>Cancel</button>
            <button className="del-btn" onClick={deleteAccount}>Continue</button>
          </div>
          {error && (
            <div className="error">{error}</div>
          )}
        </ReactModal>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  mode: state.filtersPanel.tasksDisplayMode,
  error: state.app.error,
})

const mapDispatchToProps = (dispatch) => ({
  clearError: () => dispatch(appActions.clearError()),
  setTasksLoading: () => dispatch(appActions.setTasksLoading()),
  logout: () => dispatch(appActions.logout()),
  deleteAccount: () => dispatch(appActions.deleteAccount()),
  switchTimeMoneyMode: () => dispatch(filtersActions.switchTimeMoneyMode()),
  setSettingsAppLoading: () => dispatch(appActions.setSettingsAppLoading()),
  getReportTasksRequest: () => dispatch(appActions.getReportTasksRequest()),
  resetDateRange: () => dispatch(appActions.resetDateRange())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar))
