import { delay, takeEvery, select, put, call, all } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import moment from 'moment'
import JWT from 'jsonwebtoken'

import * as types from '../types'

import * as appActions from '../actions'
import * as filterActions from '../../components/FiltersPanel/actions'
import Api from '../../services/Api'
import {
  convertWeeksReportTimeSecondsToHoursMinutes,
  convertDaysReportTimeSecondsToHoursMinutes,
  handleSagaError
} from '../../helpers'
import { API_ENDPOINTS, NO_DATA_EXCEPTION } from '../../constants/index'

function* tryAutoSignInAsync() {
  let token = localStorage.getItem('token'),
    tokenDecoded,
    isAdmin
  try {
    if (token) {
      const response = yield call(Api.get, API_ENDPOINTS.profile)
      const { user } = response.data
      if (user) {
        yield put(appActions.saveToken(token))
        yield put(appActions.setCurrentUser(user))
        tokenDecoded = JWT.decode(token)
        isAdmin = tokenDecoded.roles && tokenDecoded.roles.includes('ADMIN')
        yield put(appActions.setRole(isAdmin ? 'admin' : 'user'))
        yield put(appActions.autoSignInSuccess())
      } else {
        yield call([localStorage, localStorage.clear])
        yield put(appActions.autoSignInFailure())
      }
    } else {
      yield call([localStorage, localStorage.clear])
      yield put(appActions.autoSignInFailure())
    }
  } catch (error) {
    yield call([localStorage, localStorage.clear])
    yield put(appActions.autoSignInFailure(error))
  }
}

function* getReportTasksAsync() {
  // timesheet report
  try {
    const token = localStorage.getItem('token')
    yield put(appActions.setTasksLoading())
    if (token) {
      yield put(appActions.getAllTasks())
      const selectedDateRange = yield select(
        state => state.filtersPanel.selectedDateRange
      )
      const startOfMonth = selectedDateRange[0]
      const endOfMonth = selectedDateRange[1]
      const currentState = yield select(state => state.app.appState)
      const currentFiltersState = yield select(state => state.filtersPanel)
      const body = { tags: [] }
      const query = {
        timeOrPaymentSorting: currentFiltersState.tasksDisplayMode === 'time'
      }
      if (startOfMonth) query.beginDateStr = startOfMonth
      if (endOfMonth) query.endDateStr = endOfMonth
      const {
        all,
        priority,
        billable,
        pomodoro,
        tag
      } = currentFiltersState.filters.priority
      if (!all) {
        if (priority) {
          query['isHighPriority'] = priority
        }
        if (billable) {
          query['isBillable'] = billable
        }
        if (pomodoro) {
          query['isPomodoro'] = pomodoro
        }
        if (Object.keys(tag).filter(key => tag[key]).length) {
          for (let key in tag) {
            if (tag[key]) {
              body.tags.push(String(key))
            }
          }
        }
      }

      const currencies = yield call(Api.get, API_ENDPOINTS.currencies)
      yield put(filterActions.setListOfCurrencyes(currencies))
      let id
      const currencyesFormState = yield select(
        state => state.filtersPanel.filters.currencyes
      )
      if (billable && !currencyesFormState.all) {
        Object.keys(currencyesFormState).map(i => {
          if (currencyesFormState[i]) {
            currencies.data.map(val => {
              if (i === val.key) {
                id = val.id
              }
              return false
            })
            query['currencyId'] = id || ''
          }
          return false
        })
      }

      let response, preResponse
      if (window.location.pathname === '/timesheet' || moment(startOfMonth).week() === moment(endOfMonth).week()) {
        preResponse = yield call(
          Api.post,
          API_ENDPOINTS.reportDaily,
          query,
          body
        )
        response = convertDaysReportTimeSecondsToHoursMinutes(preResponse)
      } else {
        preResponse = yield call(
          Api.post,
          API_ENDPOINTS.reportWeekly,
          query,
          body
        )
        response = convertWeeksReportTimeSecondsToHoursMinutes(preResponse)
      }

      if (!response) yield put(appActions.getReportTasksFailure())

      const { data } = response

      yield put(appActions.getReportTasks(data))

      if (!currentState.reportView) {
        const database = yield select(state => state.app.database)
        let selectedDateObj = {}
        let newPickedDate = ''
        if (
          moment(currentState.pickedDay).isBetween(
            response.data.beginDateStr,
            response.data.endDateStr,
            null,
            '[]'
          )
        ) {
          newPickedDate = currentState.pickedDay //moment().format('YYYY-MM-DD')
        } else {
          newPickedDate = response.data.beginDateStr
        }
        database.weeks.map(week => {
          week.days.map(day => {
            if (day.dateStr === newPickedDate) {
              selectedDateObj = day
            }
            return false
          })
          return false
        })
        const dates = {
          date: newPickedDate,
          dateObj: selectedDateObj
        }
        yield put(appActions.selectDaysToView(dates))
      }

      yield delay(500)
      const dateRange = [response.data.beginDateStr, response.data.endDateStr]
      yield put(appActions.getReportTasksSuccess(dateRange))
    } else {
      yield put(appActions.getReportTasksFailure())
    }
  } catch (error) {
    if (error.message === NO_DATA_EXCEPTION) {
      yield handleSagaError(error, appActions.getReportTasksFailure(error))
      yield put(appActions.resetDateRange())
      yield put(appActions.getReportTasksRequest())
    } else {
      yield handleSagaError(error, appActions.getReportTasksFailure(error))
    }
  }
}

function* getSettingsTaskDailyAsync(taskId) {
  //settings daily

  try {
    const token = localStorage.getItem('token')
    yield put(appActions.setTasksLoading())
    if (token) {
      const currencyesFormState = yield select(
        state => state.app.database.currencyes
      )

      if (typeof currencyesFormState === "undefined" || currencyesFormState === null) {
        const currencies = yield call(Api.get, API_ENDPOINTS.currencies)
        yield put(appActions.setCurrencyes(currencies))
        yield put(filterActions.setListOfCurrencyes(currencies))
      }

      const startOfMonth = yield select(
        state => state.filtersPanel.selectedDateRange[0]
      )
      const endOfMonth = yield select(
        state => state.filtersPanel.selectedDateRange[1]
      )
      //const currentState = yield select(state => state.app.appState)
      const currentFiltersState = yield select(state => state.filtersPanel)
      const body = { tags: [] }
      const query = {
        timeOrPaymentSorting: currentFiltersState.tasksDisplayMode === 'time'
      }
      if (startOfMonth) query.beginDateStr = startOfMonth
      if (endOfMonth) query.endDateStr = endOfMonth
      const {
        all,
        priority,
        billable,
        tag
      } = currentFiltersState.filters.priority
      if (!all) {
        if (priority) {
          query['isHighPriority'] = priority
        }
        if (billable) {
          query['isBillable'] = billable
        }
        if (Object.keys(tag).filter(key => tag[key]).length) {
          for (let key in tag) {
            if (tag[key]) {
              body.tags.push(String(key))
            }
          }
        }
      }

      const endPoint = API_ENDPOINTS.reportDaily
      const selectedTaskId = window.location.pathname.replace(
        /\/tasksettings\//,
        ''
      )
      body.taskIds = [selectedTaskId]
      const preResponse = yield call(Api.post, endPoint, query, body)
      const response = convertDaysReportTimeSecondsToHoursMinutes(preResponse)
      const { data } = response
      yield put(appActions.getReportTasks(data))

      yield delay(500)
      const dateRange = [response.data.beginDateStr, response.data.endDateStr]
      yield put(appActions.getReportTasksSuccess(dateRange))
    } else {
      yield put(appActions.getReportTasksFailure())
    }
  } catch (error) {
    yield handleSagaError(error, appActions.getReportTasksFailure(error))
  }
}

function* getReportWeeklyAsync(ids) {
  //report filter weekly

  try {
    if (ids.ids && ids.ids.length && ids.ids[0] === 'none') {
      yield put(appActions.setReportTasksEmpty())
    } else {
      const token = localStorage.getItem('token')
      if (token) {
        yield put(appActions.getAllTasks())
        const startOfMonth = yield select(
          state => state.filtersPanel.selectedDateRange[0]
        )
        const endOfMonth = yield select(
          state => state.filtersPanel.selectedDateRange[1]
        )
        const currentFiltersState = yield select(state => state.filtersPanel)
        const body = { tags: [] }
        const query = {
          timeOrPaymentSorting: currentFiltersState.tasksDisplayMode === 'time'
        }
        if (startOfMonth) query.beginDateStr = startOfMonth
        if (endOfMonth) query.endDateStr = endOfMonth
        const {
          all,
          priority,
          billable,
          tag
        } = currentFiltersState.filters.priority
        if (!all) {
          if (priority) {
            query['isHighPriority'] = priority
          }
          if (billable) {
            query['isBillable'] = billable
          }
          if (Object.keys(tag).filter(key => tag[key]).length) {
            for (let key in tag) {
              if (tag[key]) {
                body.tags.push(String(key))
              }
            }
          }
        }
        const currencyes = yield call(Api.get, API_ENDPOINTS.currencies)
        yield put(filterActions.setListOfCurrencyes(currencyes))
        let id
        const currencyesFormState = yield select(
          state => state.filtersPanel.filters.currencyes
        )
        if (billable && !currencyesFormState.all) {
          Object.keys(currencyesFormState).map(i => {
            if (currencyesFormState[i]) {
              currencyes.data.map(val => {
                if (i === val.key) {
                  id = val.id
                }
                return false
              })
              query['currencyId'] = id || ''
            }
            return false
          })
        }
        const endPoint = API_ENDPOINTS.reportWeekly
        body.taskIds = ids.ids ? ids.ids : ids
        const preResponse = yield call(Api.post, endPoint, query, body)
        const response = convertWeeksReportTimeSecondsToHoursMinutes(preResponse)
        const { data } = response
        yield put(appActions.getReportTasks(data))
  
        yield delay(500)
        const dateRange = [response.data.beginDateStr, response.data.endDateStr]
        yield put(appActions.getReportTasksSuccess(dateRange))
      } else {
        yield put(appActions.getReportTasksFailure())
      }
    }
  } catch (error) {
    yield handleSagaError(error, appActions.getReportTasksFailure(error))
  }
}

function* getReportTasksSettingsAsync(taskId) {
  //settings weekly
  try {
    const token = localStorage.getItem('token')
    if (token) {
      const currentFiltersState = yield select(state => state.filtersPanel)
      const body = { tags: [] }
      let query
      if (
        currentFiltersState.selectedDateRange[0] === null &&
        currentFiltersState.selectedDateRange[1] === null
      ) {
        query = {
          timeOrPaymentSorting: currentFiltersState.tasksDisplayMode === 'time'
        }
      } else {
        query = {
          beginDateStr: currentFiltersState.selectedDateRange[0],
          endDateStr: currentFiltersState.selectedDateRange[1],
          timeOrPaymentSorting: currentFiltersState.tasksDisplayMode === 'time'
        }
      }
      const {
        all,
        priority,
        billable,
        tag
      } = currentFiltersState.filters.priority
      if (!all) {
        if (priority) {
          query['isHighPriority'] = priority
        }
        if (billable) {
          query['isBillable'] = billable
        }
        if (Object.keys(tag).filter(key => tag[key]).length) {
          for (let key in tag) {
            if (tag[key]) {
              body.tags.push(String(key))
            }
          }
        }
      }

      const currencyes = yield call(Api.get, API_ENDPOINTS.currencies)

      yield put(appActions.setCurrencyes(currencyes))
      yield put(filterActions.setListOfCurrencyes(currencyes))

      let id
      const currencyesFormState = yield select(
        state => state.filtersPanel.filters.currencyes
      )
      if (billable && !currencyesFormState.all) {
        Object.keys(currencyesFormState).map(i => {
          if (currencyesFormState[i]) {
            currencyes.data.map(val => {
              if (i === val.key) {
                id = val.id
              }
              return false
            })
            query['currencyId'] = id || ''
          }
          return false
        })
      }
      const endPoint = API_ENDPOINTS.reportWeekly
      const selectedTaskId = window.location.pathname.replace(
        /\/tasksettings\//,
        ''
      )
      body.taskIds = [selectedTaskId]
      const preResponse = yield call(Api.post, endPoint, query, body)
      const response = convertWeeksReportTimeSecondsToHoursMinutes(preResponse)
      const { data } = response
      yield put(appActions.getReportTasks(data))
      const tasks = yield select(state => state.app.database.tasksInfoAvg)
      const taskObj = tasks.filter(task => +task.task.id === +taskId.data)
      if (taskObj.length) {
        yield put(
          appActions.selectTask({
            taskId: taskId.data,
            taskObj: taskObj[0]
          })
        )
      } else {
        //yield put(push('/'))
      }

      yield delay(500)
      const dateRange = [
        preResponse.data.beginDateStr,
        preResponse.data.endDateStr
      ]
      yield put(appActions.getReportTasksSettingsSuccess(dateRange))
    } else {
      yield put(appActions.getReportTasksSettingsFailure())
    }
  } catch (error) {
    yield handleSagaError(
      error,
      appActions.getReportTasksSettingsFailure(error)
    )
  }
}

function* logoutAsync() {
  yield localStorage.removeItem('token')
  yield Api.removeToken()
  yield put(appActions.setRole(''))
  yield put(push('/'))
}

function* deleteAccount() {
  try {
    yield call(Api.post, API_ENDPOINTS.deleteAccount);
    yield put(appActions.logout());
  } catch (error) {
    yield put(appActions.deleteAccountFailure(error.data.error));
  }
}

function* getTaskByIdAsync(id) {
  try {
    const response = yield call(Api.get, API_ENDPOINTS.tasks + id.id)
    yield put(appActions.getTaskByIdSuccess(response))
  } catch (error) {
    yield handleSagaError(error, appActions.getTaskByIdFailure(error))
  }
}

function* getAllTasksAsync() {
  try {
    const currentState = yield select(state => state.filtersPanel)
    let query = {}
    if (
      currentState.selectedDateRange[0] === null &&
      currentState.selectedDateRange[1] === null
    ) {
      query = {}
    } else {
      query = {
        beginDateStr: currentState.selectedDateRange[0],
        endDateStr: currentState.selectedDateRange[1]
      }
    }
    const response = yield call(Api.get, API_ENDPOINTS.taskByPeriod, query)
    yield put(appActions.getAllTasksSuccess(response))
  } catch (error) {
    yield handleSagaError(error, appActions.getAllTasksFailure(error))
  }
}

export default function* appSaga() {
  yield all([
    takeEvery(types.AUTO_SIGN_IN_REQUEST, tryAutoSignInAsync),
    takeEvery(types.LOGOUT, logoutAsync),
    takeEvery(types.DELETE_ACCOUNT, deleteAccount),
    takeEvery(types.GET_REPORT_TASKS_INFO_REQUEST, getReportTasksAsync),
    takeEvery(types.SELECT_DATE_END, getReportTasksAsync),
    takeEvery(types.SELECT_DATE_END_SETTINGS, getReportTasksSettingsAsync),
    takeEvery(types.SWITCH_TIME_MONEY_MODE, getReportTasksAsync),
    takeEvery(types.GET_TASK_BY_ID, getTaskByIdAsync),
    takeEvery(types.GET_REPORT_TASKS_SETTINGS, getReportTasksSettingsAsync),
    takeEvery(types.GET_REPORT_WEEKLY_FILTER, getReportWeeklyAsync),
    takeEvery(types.GET_ALL_TASKS, getAllTasksAsync),
    takeEvery(types.GET_SETTINGS_TASK_DAILY, getSettingsTaskDailyAsync)
  ])
}
