import React from "react";
import TextField from "@material-ui/core/TextField";

import PasswordInput from '../../../../UI/PasswordInput'
import Button from '../../../../UI/Button'
import Preloader from '../../../../UI/Preloader'


export const Form = (props) => {
  const {
    values: { fullName, email, password },
    errors,
    touched,
    handleChange,
    isValid,
    setFieldTouched,
    handleSubmit,
    loading,
    errorsApi
  } = props;
  const change = (name, e) => {
    if (name === 'email') {
      e.target.value = e.target.value.replace(/ |[А-Яа-я]/g,"")
    }
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };
  return (
   <form 
    onSubmit={handleSubmit}
    >
     <TextField
       id="fullName"
       name="fullName"
       label="Your full name"
       style={{marginBottom:'14px'}}
       className="auth_input"
       helperText={touched.fullName ? errors.fullName : ""}
       error={touched.fullName && Boolean(errors.fullName)}
       value={fullName}
       onChange={change.bind(null, "fullName")}
     />
     <TextField
       id="email"
       name="email"
       label="Your email"
       style={{marginBottom:'14px'}}
       className="auth_input"
       helperText={touched.email ? errors.email : ""}
       error={touched.email && Boolean(errors.email)}
       value={email}
       onChange={change.bind(null, "email")}
     />
     <PasswordInput 
      touched = {touched.password}
      errors = {errors.password}
      value = {password}
      inputName = "password"
      label = "Password"
      change = {change}
      style={{marginBottom:'36px'}}
     />
     {errorsApi && <p className = "auth_errors">{errorsApi}</p>}
     <Button
       className="button"
       type="submit"
       color="primary"
       style={{minWidth:'300px', marginBottom:'24px'}} 
       disabled={!isValid || loading}
     >
      {loading ? <Preloader mini/>:'Create account'}
     </Button>
   </form>
 );
};

