import React from 'react'
import { connect } from 'react-redux'
import { Tooltip } from 'react-tippy'
import Truncate from 'react-truncate'

import Preloader from '../../../UI/Preloader'
import tasksIcon from '../../../../assets/tasks_list.svg'
import classNames from 'classnames/bind'


class TaskNameDisplay extends React.Component {

  render () {
    const { selectedTaskName } = this.props

    return (
      <div className={classNames('time_total_display')}>
        <img src={tasksIcon} alt="hours" style={{width:'20px', height:'20px',marginRight: '6px'}}/>
        <Tooltip
          html={
            <div style={{width: '300px'}}>{selectedTaskName}</div>
          }
          position="top"
          arrow={true}
          animation="perspective"
          theme='truncate-tooltip'
        >
          <p 
            className='time_total_display_text'
            style={{cursor: 'default', maxWidth: '300px'}}
            >{Boolean(selectedTaskName) ? 
              <Truncate
                lines={1}
                width={180}
              >{selectedTaskName}
              </Truncate> : <Preloader mini />}</p>
        </Tooltip>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedTaskName: state.app.appState.selectedTaskToEditObj.task.name
})


export default connect(
    mapStateToProps
  )(TaskNameDisplay)
