import React from 'react'
import { connect } from 'react-redux'

import TasksTotalDisplay from './components/TasksTotalDisplay'
import FiltersPanelSettingsPage from '../FiltersPanel/FiltersPanelSettingsPage'
import TimeMoneyToggler from './components/TimeMoneyToggler'
import SettingsTaskPanel from './components/SettingsTaskPanel'
import ReportWeeksView from './components/ReportWeeksView'
import Layout from '../Layout'
import Preloader from '../UI/Preloader'

import * as appActions from '../../App/actions'

class TaskSettings extends React.Component {
  componentDidMount = () => {
    const {
      getTaskById,
      getReportTasksSettings,
      getSettingsTaskDaily,
      viewModeWeekOrDay,
      setTaskSettingsViewModeDay,
      setTaskSettingsViewModeWeek
    } = this.props
    const taskId = this.props.match.params.taskId

    getTaskById(taskId)
    if (viewModeWeekOrDay === 'day') {
      setTaskSettingsViewModeDay()
      getSettingsTaskDaily(taskId)
    } else {
      setTaskSettingsViewModeWeek()
      getReportTasksSettings(taskId)
    }
  }

  render() {
    const { loading } = this.props
    const content = (
      <React.Fragment>
        <div className="main_workspace">
          <div className="main_workspace_header">
            <h1>Report</h1>
            <TasksTotalDisplay />
          </div>
          <FiltersPanelSettingsPage {...this.props} />
          {loading && <Preloader />}
          {!loading && <ReportWeeksView />}
        </div>
        <div className="main_controls">
          <TimeMoneyToggler />
          {loading && <Preloader />}
          {!loading && <SettingsTaskPanel />}
        </div>
      </React.Fragment>
    )
    return <Layout content={content} />
  }
}

const mapStateToProps = state => ({
  viewModeWeekOrDay: state.app.appState.taskSettingsViewMode,
  loading: state.app.appState.loading
})

const mapDispatchToProps = dispatch => ({
  getReportTasksSettings: taskId =>
    dispatch(appActions.getReportTasksSettings(taskId)),
  setTaskSettingsViewModeWeek: () =>
    dispatch(appActions.setTaskSettingsViewModeWeek()),
  setTaskSettingsViewModeDay: () =>
    dispatch(appActions.setTaskSettingsViewModeDay()),
  getSettingsTaskDaily: id => dispatch(appActions.getSettingsTaskDaily(id)),
  getTaskById: taskId => dispatch(appActions.getTaskById(taskId))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskSettings)
