import { put } from '@redux-saga/core/effects'
import { push } from 'connected-react-router'

import store from '../store'
import { NO_DATA_EXCEPTION } from '../constants'
import Api from '../services/Api'

export const base64Converter = file => {
  return new Promise((resolve, reject) => {
    let base64File = ''
    let reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = () => {
      base64File = reader.result
      resolve(base64File)
    }

    reader.onerror = error => {
      reject(error)
    }
  })
}

export const stringifyQuery = query => {
  const keys = Object.keys(query)
  let stringQuery = '?'
  keys.map((key, index) => {
    if (index - 1 === keys.length) {
      stringQuery += key + String(query[key])
    } else {
      stringQuery += key + '=' + query[key] + '&'
    }
    return false
  })

  stringQuery = stringQuery.slice(0, stringQuery.lastIndexOf('&'))

  return stringQuery === '?' ? '' : stringQuery
}

export const filterToggler = (currentState, setCurrency, singleChoice) => {
  const state = currentState
  if (setCurrency.payload === 'all') {
    for (let property in state) {
      state[String(property)] = false
    }
    state.all = true
  } else {
    if (state.all) {
      state.all = false
      state[setCurrency.payload] = !state[setCurrency.payload]
    } else {
      if (singleChoice) {
        Object.keys(state).map(i => {
          state[i] = false
          return false
        })
      }
      state[setCurrency.payload] = !state[setCurrency.payload]
      const keys = Object.keys(state)
      let i = 0
      while (i <= keys.length && !state[keys[i]]) {
        if (i === keys.length) {
          state.all = true
        }
        i++
      }
    }
  }
  return state
}

const objFirstLevelValsFalser = obj => {
  const result = { ...obj }
  for (let key in result) {
    if (typeof result[key] !== 'object') {
      result[key] = false
    }
  }
  return result
}

const objValsFalser = obj => {
  const result = { ...obj }
  for (let key in result) {
    if (typeof result[key] === 'object') {
      for (let nestedKey in result[key]) {
        result[key][nestedKey] = false
      }
    } else {
      result[key] = false
    }
  }
  return result
}

export const priorityFilterToggler = (
  currentState,
  setPriority,
  singleChoice
) => {
  const state = currentState
  if (setPriority.payload === 'all') {
    const result = objValsFalser(state)
    result.all = true
    return result
  } else {
    if (singleChoice) {
      if (
        setPriority.payload === 'priority' ||
        setPriority.payload === 'billable'
      ) {
        const result = objValsFalser(state)
        result[setPriority.payload] = !currentState[setPriority.payload]
        if (!Object.keys(state).filter(key => result[key]).length) {
          result.all = true
        }
        return result
      } else {
        const result = objFirstLevelValsFalser(state)
        if (Object.keys(result.tag).length) {
          result.tag[setPriority.payload] = !currentState.tag[
            setPriority.payload
          ]
        }
        if (!Object.keys(result.tag).filter(key => result.tag[key]).length) {
          result.all = true
        }
        return result
      }
    } else {
      if (
        setPriority.payload === 'priority' ||
        setPriority.payload === 'billable'
      ) {
        state[setPriority.payload] = !currentState[setPriority.payload]
      } else {
        if (Object.keys(state.tag).length) {
          state.tag[setPriority.payload] = !currentState.tag[
            setPriority.payload
          ]
        }
      }
      if (
        !Object.keys(state).filter(key => state[key]).length &&
        !Object.keys(state.tag).filter(key => state.tag[key]).length
      ) {
        state.all = true
      }
    }
  }
  return state
}

export const filterClear = currentState => {
  const state = currentState
  for (let property in state.currencyes) {
    state.currencyes[String(property)] = false
  }
  state.currencyes.all = true
  for (let property in state.priority) {
    if (String(property) === 'tag') {
      Object.keys(state.priority.tag).forEach(
        key => (state.priority.tag[key] = false)
      )
    } else {
      state.priority[String(property)] = false
    }
  }
  state.priority.all = true
  return state
}

export const formatMoney = money => {
  return String(Math.round(money)).replace(
    /^(\d+)(\d{3})(\d{3})(\d{3})$|^(\d+)(\d{3})(\d{3})$|^(\d+)(\d{3})$/,
    function(fullMatch, g1, g2, g3, g4, g5, g6, g7, g8, g9) {
      if (g1) {
        return g1 + ',' + g2 + ',' + g3 + ',' + g4
      } else if (g5) {
        return g5 + ',' + g6 + ',' + g7
      } else if (g8) {
        return g8 + ',' + g9
      } else {
        return fullMatch
      }
    }
  )
}

export const calcTotalTaskTimeInPeriod = (period, taskId) => {
  //return {hours: --,minutes: --}
  let totalTime = Number()
  period.map(week => {
    week.tasksInfo.map(task => {
      if (task.task.id === +taskId) {
        totalTime += +task.totalTime
      }
      return false
    })
    return false
  })
  return {
    hours: Math.round(totalTime / 3600),
    minutes: Math.round((totalTime % 3600) / 60)
  }
}

export const taskTotalTaskPaymentFromWeek = (
  period,
  taskId,
  taskCurrencyId,
  currencyes
) => {
  let totalPayment = Number()
  period.map(week => {
    week.tasksInfo.map(task => {
      if (task.task.id === +taskId) {
        totalPayment += Math.round(+task.totalPayment)
      }
      return false
    })
    return false
  })
  const symbol = currencyes.filter(currency => +currency.id === +taskCurrencyId)
  return {
    money: totalPayment,
    symbol: symbol.length ? symbol[0].symbol : ''
  }
}

export const toSeconds = time => {
  return +time.hours * 3600 + time.minutes * 60
}

export const getCurrencyInfoFromId = currencyId => {
  const state = store.getState()
  const currencies = state.app.database.currencies
  return currencies.filter(currency => +currency.id === +currencyId)[0]
}

export const convertSecondsToTimeHHMM = seconds => {
  let minutesSum = null
  let hours = null
  let minutes = null
  if (+seconds) {
    minutesSum = Math.round(seconds / 60)
    hours = Math.floor(minutesSum / 60)
    minutes = hours ? minutesSum - hours * 60 : minutesSum
    return [hours, minutes]
  } else {
    return ['--', '--']
  }
}

export const convertPayment = payment => {
  if (+payment) {
    return formatMoney(Math.round(payment))
  } else {
    return payment
  }
}

export const convertPaymentFromObject = paymentObj => {
  paymentObj = paymentObj.map(item => {
    return { ...item, value: convertPayment(item.value) }
  })
  return paymentObj
}

export const convertDaysReportTimeSecondsToHoursMinutes = database => {
  if (database.data === '') {
    throw Error(NO_DATA_EXCEPTION)
  }
  const { avgPayment, avgTime, totalPayment, totalTime, weeks } = database.data
  database.data.avgPayment = convertPayment(avgPayment)
  database.data.avgTime = convertSecondsToTimeHHMM(avgTime)
  database.data.totalPayment = convertPaymentFromObject(totalPayment)
  database.data.totalTime = convertSecondsToTimeHHMM(totalTime)
  weeks.map((week, indexWeek) => {
    database.data.weeks[indexWeek].totalTime = convertSecondsToTimeHHMM(
      week.totalTime
    )
    database.data.weeks[
      indexWeek
    ].totalPaymentByCurrencies = convertPaymentFromObject(
      week.totalPaymentByCurrencies
    )

    week.days.map((day, indexDay) => {
      database.data.weeks[indexWeek].days[
        indexDay
      ].totalTime = convertSecondsToTimeHHMM(day.totalTime)
      database.data.weeks[indexWeek].days[
        indexDay
      ].totalPaymentByCurrencies = convertPaymentFromObject(
        day.totalPaymentByCurrencies
      )
      day.tasksInfo.map((task, indexTask) => {
        database.data.weeks[indexWeek].days[indexDay].tasksInfo[
          indexTask
        ].totalTime = convertSecondsToTimeHHMM(task.totalTime)
        database.data.weeks[indexWeek].days[indexDay].tasksInfo[
          indexTask
        ].totalPaymentByCurrencies = convertPaymentFromObject(
          task.totalPaymentByCurrencies
        )
        return false
      })
      return false
    })
    return false
  })
  return database
}

export const convertWeeksReportTimeSecondsToHoursMinutes = database => {
  const { avgPayment, avgTime, totalTime, tasksInfoAvg, weeks } = database.data
  database.data.totalTime = convertSecondsToTimeHHMM(totalTime)
  database.data.avgPayment = convertPaymentFromObject(avgPayment)
  database.data.avgTime = convertSecondsToTimeHHMM(avgTime)
  tasksInfoAvg.map((task, index) => {
    database.data.tasksInfoAvg[index].totalTime = convertSecondsToTimeHHMM(
      task.totalTime
    )
    database.data.tasksInfoAvg[
      index
    ].totalPaymentByCurrencies = convertPaymentFromObject(
      task.totalPaymentByCurrencies
    )
    database.data.tasksInfoAvg[index].totalPayment = convertPayment(
      task.totalPayment
    )
    return false
  })
  weeks.map((week, indexWeek) => {
    database.data.weeks[indexWeek].totalTime = convertSecondsToTimeHHMM(
      week.totalTime
    )
    database.data.weeks[
      indexWeek
    ].totalPaymentByCurrencies = convertPaymentFromObject(
      week.totalPaymentByCurrencies
    )
    week.tasksInfo.map((task, index) => {
      database.data.weeks[indexWeek].tasksInfo[
        index
      ].totalTime = convertSecondsToTimeHHMM(task.totalTime)
      database.data.weeks[indexWeek].tasksInfo[
        index
      ].totalPaymentByCurrencies = convertPaymentFromObject(
        task.totalPaymentByCurrencies
      )
      database.data.weeks[indexWeek].tasksInfo[
        index
      ].totalPayment = convertPayment(task.totalPayment)
      return false
    })
    return false
  })
  return database
}

const REDIRECT_ERR_PAGE_HTTP_CODES = {
  403: {
    defaultMessage: 'Forbidden'
  },
  404: {
    defaultMessage: 'Not found'
  }
}

/**
 * Обработка исключений в сагах.
 *
 * Проверяет, является ли исключение поражденным http запросом и есть ли общий HTTP код ошибки,
 * (такой как 403 Forbidden), и если есть, обрабатывает такие ошибки (в общем случае делает редирект на страницу с ошибкой)
 *
 * Так же, дополнительно передает указанный action в store (в случае если передали)
 */
export function* handleSagaError(error, action) {
  let status = error.status
  if (status && REDIRECT_ERR_PAGE_HTTP_CODES[status]) {
    let redirectConfig = REDIRECT_ERR_PAGE_HTTP_CODES[status]

    if (status === 403) {
      yield localStorage.removeItem('token')
      yield Api.removeToken()
    }


    let message =
      (error.data && error.data.message) || redirectConfig.defaultMessage
    yield put(push(`/error?error=${status} ${message}`))
  }
  if (action) {
    yield put(action)
  }
}
