import React from "react";
import Button from '../../../../UI/Button'
import TextField from "@material-ui/core/TextField";

import Preloader from '../../../../UI/Preloader'


export const Form = (props) => {
  const {
    values: { email },
    errors,
    touched,
    handleChange,
    isValid,
    setFieldTouched,
    handleSubmit,
    loading,
    errorsApi
  } = props;
  const change = (name, e) => {
    if (name === 'email') {
      e.target.value = e.target.value.replace(/ |[А-Яа-я]/g,"")
    }
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };
  return (
   <form 
    onSubmit={handleSubmit}
    >
     <TextField
       id="email"
       name="email"
       label="Your email"
       style={{marginBottom:'44px'}}
       className="auth_input"
       helperText={touched.email ? errors.email : ""}
       error={touched.email && Boolean(errors.email)}
       value={email}
       onChange={change.bind(null, "email")}
     />
     {errorsApi && <p className = "auth_errors">{errorsApi}</p>}
     <Button
       className="button"
       type="submit"
       color="primary"
       style={{minWidth:'300px', marginBottom:'24px'}} 
       disabled={!isValid || loading}
     >
      {loading ? <Preloader mini/>:'Request reset link'}
     </Button>
   </form>
 );
};

