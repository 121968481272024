export const SET_LOADING = 'SET_LOADING'
export const SET_ERROR = 'SET_ERROR'

export const SET_TASKS_LOADING = 'SET_TASKS_LOADING'
export const SET_TASKS_LOADING_STOP = 'SET_TASKS_LOADING_STOP'

export const SET_SIDEBAR_LOADING = 'SET_SIDEBAR_LOADING'

export const SET_EDIT_TASK_PRIORITY_LOADING = 'SET_EDIT_TASK_PRIORITY_LOADING'
export const SET_EDIT_TASK_NAME_LOADING = 'SET_EDIT_TASK_NAME_LOADING'
export const SET_EDIT_TASK_ICON_LOADING = 'SET_EDIT_TASK_ICON_LOADING'
export const SET_EDIT_TASK_COLOR_LOADING = 'SET_EDIT_TASK_COLOR_LOADING'
export const SET_EDIT_TASK_CURRENCY_LOADING = 'SET_EDIT_TASK_CURRENCY_LOADING'
export const SET_EDIT_TASK_HOURLY_RATE_LOADING =
  'SET_EDIT_TASK_HOURLY_RATE_LOADING'
export const SET_EDIT_TASK_POMODORO_TIME_LOADING =
  'SET_EDIT_TASK_POMODORO_TIME_LOADING'
export const SET_EDIT_TASK_TAG_LOADING = 'SET_EDIT_TASK_TAG_LOADING'

export const LOGOUT = 'LOGOUT'
export const SAVE_TOKEN = 'SAVE_TOKEN'

export const DELETE_ACCOUNT = 'DELETE_ACCOUNT'
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS'
export const DELETE_ACCOUNT_FAILURE = 'DELETE_ACCOUNT_FAILURE'

export const AUTO_SIGN_IN_REQUEST = 'AUTO_SIGN_IN_REQUEST'
export const AUTO_SIGN_IN_FAILURE = 'AUTO_SIGN_IN_FAILURE'
export const AUTO_SIGN_IN_SUCCESS = 'AUTO_SIGN_IN_SUCCESS'

export const SET_CURRENT_USER = 'SET_CURRENT_USER'

export const SET_ROLE = 'SET_ROLE'

export const SET_DATE_RANGE = 'SET_DATE_RANGE'
export const RESET_DATE_RANGE = 'RESET_DATE_RANGE'

export const SELECT_DAYS_TO_VIEW = 'SELECT_DAYS_TO_VIEW'

export const SWITCH_TIME_MONEY_MODE = 'SWITCH_TIME_MONEY_MODE'

export const TOGGLE_TIME_OR_REPORT_MODE = 'TOGGLE_TIME_OR_REPORT_MODE'

export const GET_REPORT_TASKS_INFO_REQUEST = 'GET_REPORT_TASKS_INFO_REQUEST'
export const GET_REPORT_TASKS_INFO = 'GET_REPORT_TASKS_INFO'
export const GET_REPORT_TASKS_INFO_FAILURE = 'GET_REPORT_TASKS_INFO_FAILURE'
export const GET_REPORT_TASKS_INFO_SUCCESS = 'GET_REPORT_TASKS_INFO_SUCCESS'
export const SET_REPORT_TASKS_EMPTY = 'SET_REPORT_TASKS_EMPTY'

export const GET_REPORT_WEEKLY_FILTER = 'GET_REPORT_WEEKLY_FILTER'
export const GET_REPORT_WEEKLY_FILTER_FAILURE =
  'GET_REPORT_WEEKLY_FILTER_FAILURE'
export const GET_REPORT_WEEKLY_FILTER_SUCCESS =
  'GET_REPORT_WEEKLY_FILTER_SUCCESS'

export const GET_REPORT_TASKS_SETTINGS = 'GET_REPORT_TASKS_SETTINGS'
export const GET_REPORT_TASKS_SETTINGS_FAILURE =
  'GET_REPORT_TASKS_SETTINGS_FAILURE'
export const GET_REPORT_TASKS_SETTINGS_SUCCESS =
  'GET_REPORT_TASKS_SETTINGS_SUCCESS'

export const GET_SETTINGS_TASK_DAILY = 'GET_SETTINGS_TASK_DAILY'

export const GET_REPORT_WEEKLY = 'GET_REPORT_WEEKLY'

export const SELECT_TASK = 'SELECT_TASK'

export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST'
export const SIGN_UP = 'SIGN_UP'
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS'
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE'

export const SELECT_DATE_START = 'SELECT_DATE_START'
export const SELECT_DATE_END = 'SELECT_DATE_END'

export const SELECT_DATE_END_SETTINGS = 'SELECT_DATE_END_SETTINGS'

export const GET_CURRENCYES_REQUEST = 'GET_CURRENCYES_REQUEST'
export const GET_CURRENCYES = 'GET_CURRENCYES'
export const GET_CURRENCYES_SUCCESS = 'GET_CURRENCYES_SUCCESS'
export const GET_CURRENCYES_FAILURE = 'GET_CURRENCYES_FAILURE'

export const SET_CURRENCYES = 'SET_CURRENCYES'

export const GET_TASK_BY_ID = 'GET_TASK_BY_ID'
export const GET_TASK_BY_ID_FAILURE = 'GET_TASK_BY_ID_FAILURE'
export const GET_TASK_BY_ID_SUCCESS = 'GET_TASK_BY_ID_SUCCESS'

export const GET_ALL_TASKS = 'GET_ALL_TASKS'
export const GET_ALL_TASKS_SUCCESS = 'GET_ALL_TASKS_SUCCESS'
export const GET_ALL_TASKS_FAILURE = 'GET_ALL_TASKS_FAILURE'

export const CALC_TASK_AVG_VALUES_BY_WEEKS = 'CALC_TASK_AVG_VALUES_BY_WEEKS'

export const SET_TASK_SETTINGS_VIEW_MODE_WEEK =
  'SET_TASK_SETTINGS_VIEW_MODE_WEEK'
export const SET_TASK_SETTINGS_VIEW_MODE_DAY = 'SET_TASK_SETTINGS_VIEW_MODE_DAY'
