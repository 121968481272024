import * as types from '../types'

export const signUpRequest = () => ({
  type: types.SIGN_UP_REQUEST
})

export const signUpSuccess = payload => ({
  type: types.SIGN_UP_SUCCESS,
  payload
})

export const signUpFailure = payload => ({
  type: types.SIGN_UP_FAILURE,
  payload
})

export const signUpFetch = user => ({
  type: types.SIGN_UP_FETCH,
  payload: user
})

export const getLinksRequest = () => ({
  type: types.GET_LINKS_REQUEST
})
export const getLinksSuccess = payload => ({
  type: types.GET_LINKS_SUCCESS,
  payload
})
export const getLinksError = error => ({
  type: types.GET_LINKS_ERROR,
  error
})

export const resendEmail = email => ({
  type: types.RESEND_EMAIL,
  email
})

export const resendEmailRequest = () => ({
  type: types.RESEND_EMAIL_REQUEST
})

export const resendEmailSuccess = () => ({
  type: types.RESEND_EMAIL_SUCCESS
})
export const resendEmailError = error => ({
  type: types.RESEND_EMAIL_ERROR,
  error
})
