import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import * as appActions from './actions'
import * as signInActions from '../components/auth/SignIn/actions'
import PrivateRoute from '../components/PrivateRoute'
import Preloader from '../components/UI/Preloader'
import SignIn from '../components/auth/SignIn'
import SignUp from '../components/auth/SignUp'
import SignUpConfirmEmail from '../components/auth/SignUpConfirmEmail'
import PasswordReset from '../components/auth/PasswordReset'
import ChangePassword from '../components/auth/PaswordChange'
import CompleteUserInfo from '../components/auth/CompleteUserInfo'
import ErrorPage from '../components/ErrorPage'
import Update from '../components/Update'

import TimeSheet from '../components/TimeSheet'
import Report from '../components/Report'
import Integrations from '../components/Integrations'
import TaskSettings from '../components/TaskSettings'

import './App.scss'

class App extends Component {
  static propTypes = {
    tryAutoSignIn: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
  }

  componentDidMount() {
    const { tryAutoSignIn, initAppleAuth } = this.props
    tryAutoSignIn()
    initAppleAuth()
  }

  render() {
    const { loading, role } = this.props
    let content = (
      <div
        style={{
          background: '#506073',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh'
        }}>
        <Preloader />
      </div>
    )

    if (!loading) {
      if (role === 'admin') {
        content = (
          <Switch>
            <PrivateRoute path="/" exact role={role} />
            <PrivateRoute path="/update" exact role={role} component={Update} />
            <Route path="/error" component={ErrorPage} />
            <Route path="/auth" component={SignIn} />
            <Redirect to="/" />
          </Switch>
        )
      } else {
        content = (
          <Switch>
            <PrivateRoute path="/" exact />
            <PrivateRoute path="/timesheet" exact component={TimeSheet} />
            <PrivateRoute path="/report" exact component={Report} />
            <PrivateRoute path="/integrations" exact component={Integrations} />
            <PrivateRoute
              path="/tasksettings/:taskId"
              component={TaskSettings}
            />
            <Route path="/complete-info" component={CompleteUserInfo} />
            <Route
              path="/confirm-email/success"
              component={SignUpConfirmEmail}
            />
            <Route
              path="/confirm-email/failure"
              component={SignUpConfirmEmail}
            />
            <Route path="/error" component={ErrorPage} />
            <Route path="/auth" component={SignIn} />
            <Route path="/register" component={SignUp} />
            <Route path="/register" component={SignUp} />
            <Route path="/confirm-email" component={SignUpConfirmEmail} />
            <Route path="/forgot-password" component={PasswordReset} />
            <Route path="/change-password" component={ChangePassword} />
            <Redirect to="/" />
          </Switch>
        )
      }
    }
    return content
  }
}

const mapStateToProps = state => ({
  loading: state.app.loading,
  role: state.signIn.role
})

const mapDispatchToProps = dispatch => ({
  tryAutoSignIn: () => dispatch(appActions.autoSignIn()),
  initAppleAuth: () => dispatch(signInActions.initAppleAuth())
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
)
