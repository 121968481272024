import React from 'react'
import { connect } from 'react-redux'

import Preloader from '../../../../../UI/Preloader'
import ButtonEdit from '../../../../../UI/ButtonEdit'
import TaskStatusCurrent from '../../../../../UI/TaskStatusCurrent'
import * as actions from '../../actions'
import * as appActions from '../../../../../../App/actions'

class EditStatus extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      priority: null,
      billable: null,
      pomodoro: null,
      isSubmitting: false,
      readonly: true,
    }
  }

  componentDidMount = () => {
    this.setState((state) => {
      return {
        ...state,
        priority: this.props.highPriority,
        billable: this.props.billable,
        pomodoro: this.props.pomodoro,
      }
    })
  }

  handleClick = () => {
    this.setState((state) => {
      return {
        priority: this.props.highPriority,
        billable: this.props.billable,
        pomodoro: this.props.pomodoro,
        readonly: false,
      }
    })
  }
  handleSubmit = () => {
    const {
      editTaskPriority,
      editTaskBillable,
      editTaskPomodoroStatus,
      getSettingsTaskDaily,
      getReportTasksSettings,
      mode,
      highPriority,
      billable,
      pomodoro,
    } = this.props
    const getDataAction =
      mode === 'week' ? getReportTasksSettings : getSettingsTaskDaily
    const currentPriority = this.state.priority
    const currentBillable = this.state.billable
    const currentPomodoroStatus = this.state.pomodoro
    this.setState((state) => {
      return { readonly: true }
    })
    if (highPriority !== currentPriority) {
      editTaskPriority(currentPriority)
    }
    if (pomodoro !== currentPomodoroStatus) {
      editTaskPomodoroStatus(currentPomodoroStatus)
    }
    if (billable !== currentBillable) {
      editTaskBillable(currentBillable)
      getDataAction()
    }
  }

  handleChange = (param, value) => {
    this.setState(() => {
      return { [param]: value }
    })
  }

  render() {
    const { readonly, billable, priority, pomodoro } = this.state
    const statusesList = Object.keys({ billable, priority, pomodoro }).map(
      (statusItem) => {
        return (
          <TaskStatusCurrent
            key={statusItem}
            isBillable={this.state[statusItem]}
            onClick={this.handleChange}
            isEditable={!readonly}
          >
            {`${statusItem.charAt(0).toUpperCase() + statusItem.slice(1)}`}
          </TaskStatusCurrent>
        )
      }
    )

    const { loading } = this.props
    return (
      <div
        className='task_settings_item'
        style={{ userSelect: 'none', padding: '15px 16px', height: 'initial' }}
      >
        <div
          className='task_settings_item_left'
          style={{ display: 'flex', flexFlow: 'row wrap' }}
        >
          {statusesList}
        </div>
        {!loading && (
          <div className='task_settings_item_right'>
            {this.state.readonly ? (
              <ButtonEdit onClick={this.handleClick} />
            ) : (
              <ButtonEdit
                active
                onClick={this.handleSubmit}
                disabled={this.state.isSubmitting}
              />
            )}
          </div>
        )}
        {loading && (
          <div className='task_settings_item_right'>
            <Preloader mini />
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  billable: state.app.appState.selectedTaskToEditObj.task.billable,
  highPriority: state.app.appState.selectedTaskToEditObj.task.highPriority,
  pomodoro: state.app.appState.selectedTaskToEditObj.task.pomodoro,
  loading: state.app.appState.editPriorityLoading,
  mode: state.app.appState.taskSettingsViewMode,
})

const mapDispatchToProps = (dispatch) => ({
  editTaskPriority: (data) => dispatch(actions.editTaskPriority(data)),
  editTaskBillable: (data) => dispatch(actions.editTaskBillable(data)),
  editTaskPomodoroStatus: (data) =>
    dispatch(actions.editTaskPomodoroStatus(data)),
  getSettingsTaskDaily: () => dispatch(appActions.getSettingsTaskDaily()),
  getReportTasksSettings: () => dispatch(appActions.getReportTasksSettings()),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditStatus)
