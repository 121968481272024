import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import * as actions from './actions'

import './scss/styles.scss' // main style file
import './scss/theme/default.scss' // theme css file
import { DateRange } from './components/react-date-range'

import Datepicker from '../UI/Datepicker'
import CurrenciesControls from './components/CurrenciesControls'
import PriorityControls from './components/PriorityControls'
import Popover from '../UI/Popover'

class FiltersPanel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      key: 'selection',
      focusedRange: [0, 0],
      open: false,
      dateStartView: moment()
        .subtract(1, 'months')
        .startOf('month')._d,
      dateStartView1: new moment().startOf('month')._d,
      pickerFirstMaxdate: moment()
        .subtract(1, 'months')
        .endOf('month')._d,
      pickerSecondMindate: moment().startOf('month')._d
    }
  }
  handleSelect = dates => {
    const { selectStartDate, selectEndDate } = this.props
    if (dates.selection) {
      this.setState({
        key: 'selected',
        key1: 'selected',
        focusedRange: [0, 1]
      })
      selectStartDate([
        moment(dates.selection.startDate).format('YYYY-MM-DD'),
        moment(dates.selection.endDate).format('YYYY-MM-DD')
      ])
    } else {
      this.setState({
        key: 'selection',
        key1: 'selection',
        focusedRange: [0, 0]
      })
      selectEndDate([
        moment(dates.selected.startDate).format('YYYY-MM-DD'),
        moment(dates.selected.endDate).format('YYYY-MM-DD')
      ])
      setTimeout(() => {
        this.setIsOpen(false)
      }, 700)
    }
  }

  onChangeCurrentMonthPickerFirst = selectedMonth => {
    this.setState({
      pickerSecondMindate: moment(selectedMonth)
        .add(1, 'month')
        .startOf('month')._d
    })
  }
  onChangeCurrentMonthPickerSecond = selectedMonth => {
    this.setState({
      pickerFirstMaxdate: moment(selectedMonth)
        .subtract(1, 'month')
        .endOf('month')._d
    })
  }

  pickRange = range => {
    const { selectEndDate } = this.props
    switch (range) {
      case 'today':
        selectEndDate([
          moment().format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ])
        break
      case 'yesterday':
        selectEndDate([
          moment()
            .subtract(1, 'day')
            .format('YYYY-MM-DD'),
          moment()
            .subtract(1, 'day')
            .format('YYYY-MM-DD')
        ])
        break
      case 'week':
        selectEndDate([
          moment()
            .startOf('week')
            .format('YYYY-MM-DD'),
          moment()
            .endOf('week')
            .format('YYYY-MM-DD')
        ])
        break
      case 'prevWeek':
        selectEndDate([
          moment()
            .subtract(1, 'week')
            .startOf('week')
            .format('YYYY-MM-DD'),
          moment()
            .subtract(1, 'week')
            .endOf('week')
            .format('YYYY-MM-DD')
        ])
        break
      case 'month':
        selectEndDate([
          moment()
            .startOf('month')
            .format('YYYY-MM-DD'),
          moment()
            .endOf('month')
            .format('YYYY-MM-DD')
        ])
        break
      case 'prevMonth':
        selectEndDate([
          moment()
            .subtract(1, 'month')
            .startOf('month')
            .format('YYYY-MM-DD'),
          moment()
            .subtract(1, 'month')
            .endOf('month')
            .format('YYYY-MM-DD')
        ])
        break
      case 'year':
        selectEndDate([
          moment()
            .startOf('year')
            .format('YYYY-MM-DD'),
          moment()
            .endOf('year')
            .format('YYYY-MM-DD')
        ])
        break
      case 'prevYear':
        selectEndDate([
          moment()
            .subtract(1, 'year')
            .startOf('year')
            .format('YYYY-MM-DD'),
          moment()
            .subtract(1, 'year')
            .endOf('year')
            .format('YYYY-MM-DD')
        ])
        break
      case 'entireTime':
        selectEndDate([null, null])
        break

      default:
        break
    }
    setTimeout(() => {
      this.setIsOpen(false)
    }, 700)
  }

  setIsOpen = isOpen => {
    this.setState({
      open: isOpen
    })
  }

  handleFilterCurrencyes = event => {
    this.props.setFilterCurrencyes(event, this.props.appState)
  }

  handleFilterPriority = event => {
    this.props.setFilterPriority(event, this.props.appState)
  }

  componentDidMount() {
    this.setState({
      loading: true
    })
  }

  handleChangeTag = tagName => {
    const { setFilterTag, setFilterTagRequest } = this.props
    setFilterTag(tagName)
    setFilterTagRequest()
  }

  render() {
    const { filters } = this.props
    const currencyes = this.props.currencyes
    const selectedDateRange = this.props.selectedDateRange
    const selectionRange = {
      startDate: moment(selectedDateRange[0], 'YYYY-MM-DD'),
      endDate: moment(selectedDateRange[1], 'YYYY-MM-DD'),
      key: this.state.key
    }
    const { downloadXLSX, downloadCSV } = this.props

    const { dateStartView, dateStartView1 } = this.state

    const { pickerFirstMaxdate, pickerSecondMindate } = this.state
    return (
      <div className="tools_panel_placeholder">
        <div className="left">
          <Datepicker
            dateStart={selectionRange.startDate}
            loading={selectionRange.startDate._i}
            dateEnd={selectionRange.endDate}
            open={this.state.open}
            theme={'main_datepicker'}
            arrow={true}
            onRequestClose={() => {
              this.setIsOpen(false)
            }}
            onOpen={() => {
              this.setIsOpen(!this.state.open)
            }}
            html={
              <div
                style={{
                  display: 'flex',
                  padding: '30px 15px',
                  flexWrap: 'wrap'
                }}>
                <DateRange
                  months={1}
                  direction="horizontal"
                  ranges={[selectionRange]}
                  onChange={this.handleSelect}
                  shownDate={dateStartView}
                  date={new Date()}
                  maxDate={pickerFirstMaxdate}
                  showMonthArrow={true}
                  showDateDisplay={false}
                  showMonthAndYearPickers={false}
                  showMonthName={false}
                  focusedRange={this.state.focusedRange}
                  onShownDateChange={this.onChangeCurrentMonthPickerFirst}
                />
                <DateRange
                  months={1}
                  direction="horizontal"
                  ranges={[selectionRange]}
                  onChange={this.handleSelect}
                  shownDate={dateStartView1}
                  date={new Date()}
                  maxDate={new Date()}
                  minDate={pickerSecondMindate}
                  showMonthArrow={true}
                  showDateDisplay={false}
                  showMonthAndYearPickers={false}
                  focusedRange={this.state.focusedRange}
                  onShownDateChange={this.onChangeCurrentMonthPickerSecond}
                />
                <div className="datepicker_footer">
                  <div className="datepicker_footer_entire_label">
                    <p
                      onClick={() => {
                        this.pickRange('entireTime')
                      }}
                      className="entire_label">
                      <span className="entire_label link">Entire time</span>
                    </p>
                  </div>
                  <div className="datepicker_footer_pickers">
                    <ul>
                      <li>
                        <span
                          className="link"
                          onClick={() => {
                            this.pickRange('today')
                          }}>
                          Today
                        </span>
                      </li>
                      <li>
                        <span
                          className="link"
                          onClick={() => {
                            this.pickRange('yesterday')
                          }}>
                          Yesterday
                        </span>
                      </li>
                      <li>
                        <span
                          className="link"
                          onClick={() => {
                            this.pickRange('week')
                          }}>
                          This week
                        </span>
                      </li>
                      <li>
                        <span
                          className="link"
                          onClick={() => {
                            this.pickRange('prevWeek')
                          }}>
                          Last week
                        </span>
                      </li>
                      <li>
                        <span
                          className="link"
                          onClick={() => {
                            this.pickRange('month')
                          }}>
                          This month
                        </span>
                      </li>
                      <li>
                        <span
                          className="link"
                          onClick={() => {
                            this.pickRange('prevMonth')
                          }}>
                          Last month
                        </span>
                      </li>
                      <li>
                        <span
                          className="link"
                          onClick={() => {
                            this.pickRange('year')
                          }}>
                          This year
                        </span>
                      </li>
                      <li>
                        <span
                          className="link"
                          onClick={() => {
                            this.pickRange('prevYear')
                          }}>
                          Last year
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            }
          />

          <PriorityControls
            filters={filters.priority}
            style={{ marginRight: '11px' }}
            onClick={this.handleFilterPriority}
            onTagSelect={tagName => this.handleChangeTag(tagName)}
          />
          {filters.priority.billable && (
            <CurrenciesControls
              filters={filters.currencyes}
              onClick={this.handleFilterCurrencyes}
              currencyes={currencyes}
            />
          )}
        </div>
        <div className="right">
          <span
            className="link"
            style={{ marginRight: '17px' }}
            onClick={this.props.setFilterClear}>
            Clear filters
          </span>
          <Popover downloadXLSX={downloadXLSX} downloadCSV={downloadCSV}>
            Export
          </Popover>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    filters: state.filtersPanel.filters,
    selectedDateRange: state.filtersPanel.selectedDateRange,
    currencyes: state.app.database.currencies
  }
}
const mapDispatchToProps = dispatch => ({
  setFilterCurrencyes: (data, state) =>
    dispatch(actions.setFilterCurrencyes(data, state)),
  setFilterPriority: (data, state) =>
    dispatch(actions.setFilterPriority(data, state)),
  setFilterTag: tagName => dispatch(actions.setFilterTag(tagName)),
  setFilterTagRequest: () => dispatch(actions.setFilterTagRequest()),
  setFilterClear: () => dispatch(actions.setFilterClear()),
  selectStartDate: dates => dispatch(actions.selectStartDate(dates)),
  selectEndDate: dates => dispatch(actions.selectEndDate(dates)),
  downloadXLSX: () => dispatch(actions.downloadXLSX()),
  downloadCSV: () => dispatch(actions.downloadCSV())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FiltersPanel)
