import React from 'react'
import { connect } from 'react-redux'
import { replace } from 'connected-react-router'

import Logo from '../../../assets/logo.svg'
import CompleteUserInformationForm from './components/CompleteUserInfoForm'

class CompleteUserInfo extends React.Component {
  componentDidMount() {
    const { appleError, replace } = this.props
    if (!appleError || !appleError.identityToken) {
      console.log(appleError)
      replace('/')
    }
  }

  render() {
    const { appleError } = this.props
    return (
      <main className="layout">
        <div className="auth">
          <div className="auth_placeholder">
            <img src={Logo} alt="logo" className="logo auth_logo" />
            <p className="auth_intro">Complete user information</p>
            <CompleteUserInformationForm userInfo={appleError} />
          </div>
        </div>
      </main>
    )
  }
}

const mapStateToProps = state => ({
  appleError: state.signIn.appleError
})

const mapDispatchToProps = {
  replace
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompleteUserInfo)
