import React from 'react'

import PriorityIcon from '../../Svg/PriorityIcon'
import BillableIcon from '../../Svg/BillableIcon'
import PomodoroIcon from '../../Svg/PomodoroIcon'

import classNames from 'classnames/bind'

const classes = {
  0: 'dummy',
  1: 'singleEl',
  2: 'twoEl',
  3: 'threeEl',
}

const TaskStatusCurrent = ({ priority, billable, pomodoro, children }) => {
  const classAppendix =
    classes[[priority, billable, pomodoro].filter((prop) => prop).length]
  return (
    <span className={classNames('priority_status', `${classAppendix}`)}>
      {priority && <PriorityIcon />}
      {billable && <BillableIcon />}
      {pomodoro && <PomodoroIcon />}
      {children}
    </span>
  )
}

export default TaskStatusCurrent
