import * as types from '../types'
import { SET_ROLE } from '../../../../App/types'

const initialState = {
  error: null,
  loading: false,
  success: false,
  googleLoading: null,
  googleError: null,
  appleError: null,
  role: ''
}

const isLoading = state => ({
  ...state,
  loading: true
})

const isFailed = (state, action) => ({
  ...state,
  loading: false,
  error: action.payload
})

const isSucceed = state => ({
  ...state,
  loading: false,
  success: true,
  error: false
})

const sendGoogleTokenId = state => ({
  ...state,
  googleLoading: true
})
const sendGoogleTokenIdSuccess = state => ({
  ...state,
  googleLoading: false
})
const sendGoogleTokenIdFailure = (state, action) => ({
  ...state,
  googleLoading: false,
  googleError: action.payload
})

const sendAppleCredentialsSuccess = state => ({ ...state, appleError: null })
const sendAppleCredentialsFail = (state, action) => ({
  ...state,
  appleError: action.payload
})

const SignInReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SIGN_IN_REQUEST:
      return isLoading(state)

    case types.SIGN_IN_FAILURE:
      return isFailed(state, action)

    case types.SIGN_IN_SUCCESS:
      return isSucceed(state)

    case types.SEND_GOOGLE_TOKEN_ID:
      return sendGoogleTokenId(state)
    case types.SEND_GOOGLE_TOKEN_ID_SUCCESS:
      return sendGoogleTokenIdSuccess(state)
    case types.SEND_GOOGLE_TOKEN_ID_FAILURE:
      return sendGoogleTokenIdFailure(state, action)

    case types.SEND_APPLE_CREDENTIALS_SUCCESS:
      return sendAppleCredentialsSuccess(state)
    case types.SEND_APPLE_CREDENTIALS_ERROR:
      return sendAppleCredentialsFail(state, action)
    case SET_ROLE:
      return { ...state, role: action.payload }

    default:
      return state
  }
}

export default SignInReducer
