import React from 'react'
import preloaderIcon from '../../../assets/preloader.svg'


const Preloader = mini => {
    return <img 
        className='preloader'
        width = {mini.mini ? '14px' : '47px'}
        height = {mini.mini ? '14px' : '47px'}
        src={preloaderIcon} 
        alt="loading"/>
}


export default Preloader