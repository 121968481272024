import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import EmailForm from './components'

import Logo from '../../../assets/logo.svg'

import * as actions from './actions'


class PasswordReset extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLinkSubmitted: false
    }
  }

  handleClick = (e) => {
    this.setState({
      isLinkSubmitted: true
    })
    this.props.passwordReset(e.target.value)
  }

  render() {
    const { resetSuccess } = this.props
    const message = resetSuccess ? 
      'Сheck your email to continue the password reset process' : 
      'Enter your email address below, and we will send you a password reset link'
    return (
      <main className="layout">
        <div className="auth">
          <div className="auth_placeholder">
            <img src={Logo} alt="logo" className="logo auth_logo"/>
            <p className="auth_intro" style={{marginBottom: '26px'}}>Password reset</p>
            <p style={{marginBottom: '43px'}}>{message}</p>
            {!resetSuccess && <EmailForm />}
            <Link to="/auth">
                Back to Log In
            </Link>
            <p style={{marginTop: 'auto'}}>
              Don’t have an account?&nbsp;
              <Link to="/register">
                Sign up
              </Link>
            </p>
          </div>
        </div>
      </main>
    )
  }
}

const mapStateToProps = state => ({
  resetSuccess: state.passwordReset.success
})
const mapDispatchToProps = dispatch => ({
  passwordReset: email => dispatch(actions.passwordReset(email))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordReset)


