import React from 'react'

import PriorityIcon from '../../Svg/PriorityIcon'
import BillableIcon from '../../Svg/BillableIcon'
import PomodoroIcon from '../../Svg/PomodoroIcon'
import CloseXIcon from '../../Svg/CloseX'
import Button from '../Button'

import classNames from 'classnames/bind'

const icons = {
  priority: <PriorityIcon />,
  billable: <BillableIcon />,
  pomodoro: <PomodoroIcon />,
}

const TaskStatusCurrent = ({
  isPriority,
  isBillable,
  isPomodoro,
  isEditable,
  children,
  onClick,
  ...props
}) => {
  const propname = String(children).toLowerCase()
  if (!isPriority && !isBillable && !isPomodoro && !isEditable) {
    return <></>
  }
  return isEditable ? (
    <span
      className={classNames(
        `priority_status ${
          isPriority || isBillable || isPomodoro
            ? 'priority_status_current'
            : 'priority_status_add'
        }`
      )}
      {...props}
    >
      {icons[propname]}
      {children}
      {isPriority || isBillable || isPomodoro ? (
        <CloseXIcon onClick={() => onClick(propname, false)} />
      ) : (
        <Button onClick={() => onClick(propname, true)}>+</Button>
      )}
    </span>
  ) : (
    <span
      className={classNames(`priority_status priority_status_current`)}
      {...props}
    >
      {icons[propname]}
      {children}
    </span>
  )
}

export default TaskStatusCurrent
