import * as types from '../types'
import * as appTypes from '../../../App/types'
import moment from 'moment'
import { priorityFilterToggler, filterToggler, filterClear } from '../../../helpers'

const initialState = {
  loading: null,
  error: null,
  tasksDisplayMode: 'time',
  selectedDateRange: [
    new moment().startOf('month').format('YYYY-MM-DD'),
    new moment().endOf('month')._d > new moment()._d
      ? new moment().format('YYYY-MM-DD')
      : new moment().endOf('month').format('YYYY-MM-DD'),
  ],
  filters: {
    priority: {
      all: true,
      priority: false,
      billable: false,
      pomodoro: false,
      tag: [],
    },
    currencyes: {
      all: true,
    },
    tasksIds: [],
  },
}

const resetDateRange = (state, action) => {
  return ({
    ...state,
    selectedDateRange: [
      new moment().startOf("month").format("YYYY-MM-DD"),
      new moment().endOf("month")._d > new moment()._d
        ? new moment().format("YYYY-MM-DD")
        : new moment().endOf("month").format("YYYY-MM-DD"),
    ],
  })
}

const setDateStart = (state, action) => {
  return {
    ...state,
    selectedDateRange: action.dates,
  }
}

const setDateEnd = (state, action) => {
  return {
    ...state,
    selectedDateRange: action.dates,
  }
}

const setDateRange = (state, action) => {
  return {
    ...state,
    selectedDateRange: action.dates,
  }
}

const setFilterCurrencyes = (state, action) => {
  return {
    ...state,
    filters: {
      ...state.filters,
      currencyes: filterToggler(state.filters.currencyes, action.payload, true),
    },
  }
}

const setFilterPriority = (state, action) => {
  const mode = action.payload !== 'billable' ? 'time' : 'money'
  return {
    ...state,
    tasksDisplayMode: mode,
    filters: {
      ...state.filters,
      priority: priorityFilterToggler(state.filters.priority, action.payload, true),
    },
  }
}

const setFilterCear = (state) => {
  return {
    ...state,
    tasksDisplayMode: 'time',
    filters: filterClear(state.filters),
  }
}

const setListOfCurrencyes = (state, action) => {
  let newFilter = { all: true }
  action.currencyes.data.map((val) => {
    if (state.filters.currencyes[val.key]) {
      newFilter.all = false
    }
    newFilter[val.key] = state.filters.currencyes[val.key] || false
    return false
  })
  return {
    ...state,
    filters: {
      ...state.filters,
      currencyes: newFilter,
    },
  }
}

const switchTimeMoneyMode = (state) => {

  return ({
    ...state,
    tasksDisplayMode: state.tasksDisplayMode === 'time' ? 'money' : 'time',
    filters: {
      ...state.filters,
      priority: {
        all: false,
        priority: false,
        billable: true,
        pomodoro: false,
        tag: [],
      }
    }
  })
}

const initTasksFilter = (state, action) => {
  const tasksIds = action.data.tasksInfoAvg
    ? action.data.tasksInfoAvg.map((task) => task.task.id)
    : []
  return {
    ...state,
    tasksIds: tasksIds,
  }
}

const switchSettingsTimeMoneyMode = (state) => ({
  ...state,
  tasksDisplayMode: state.tasksDisplayMode === 'time' ? 'money' : 'time',
})

const getReportTasksSuccess = (state, action) => {
  return {
    ...state,
    selectedDateRange: action.dateRange,
  }
}

const getReportTasksSettingsSuccess = (state, action) => {
  return {
    ...state,
    selectedDateRange: action.dateRange,
  }
}

const extractTagsFromTasksToFilters = (state, action) => {
  const tagsObject = {...state.filters.priority.tag}
  const newTagsObj = {}
  const sortedTagsArray = action.data.map(task => task.tag).filter(tag => !!tag).sort()
  sortedTagsArray.forEach(tag => {
    if (typeof tagsObject[tag] !== undefined) {
      newTagsObj[tag] = tagsObject[tag]
    } else {
      newTagsObj[tag] = false
    }
  })
  return {
    ...state,
    filters: {
      ...state.filters,
      priority: {
        ...state.filters.priority,
        tag: {...newTagsObj}
      }
    }
  }
}

const setFilterTag = (state, action) => {
  const totalSelected = Object.keys(state.filters.priority.tag).filter(key => state.filters.priority.tag[key]).length
  let nextStateIsSelected = false
  if (totalSelected === 0) {
    nextStateIsSelected = false
  } else if (totalSelected === 1) {
    nextStateIsSelected = state.filters.priority.tag[action.payload]
  } else {
    nextStateIsSelected = false
  }
  const allFlagState = nextStateIsSelected && !state.filters.priority.priority && !state.filters.priority.billable

  return {
    ...state,
    filters: {
      ...state.filters,
      priority: {
        ...state.filters.priority,
        all: allFlagState,
        priority: false,
        billable: false,
        tag: {
          ...state.filters.priority.tag,
          [action.payload]: !state.filters.priority.tag[action.payload]
        }
      }
    }
  }
}

const FiltersPanelReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SELECT_DATE_START:
      return setDateStart(state, action)

    case types.SELECT_DATE_END:
      return setDateEnd(state, action)

    case types.SELECT_DATE_END_SETTINGS:
      return setDateEnd(state, action)

    case types.SET_DATE_RANGE:
      return setDateRange(state, action)

    case types.SET_FILTER_CURRENCYES:
      return setFilterCurrencyes(state, action)

    case types.SET_FILTER_PRIORITY:
      return setFilterPriority(state, action)

    case types.SET_FILTERS_CLEAR:
      return setFilterCear(state)

    case types.SET_LIST_OF_CURRENCYES:
      return setListOfCurrencyes(state, action)

    case types.SWITCH_TIME_MONEY_MODE:
      return switchTimeMoneyMode(state)

    case types.SWITCH_SETTINGS_TIME_MONEY_MODE:
      return switchSettingsTimeMoneyMode(state)

    case appTypes.GET_REPORT_TASKS_INFO:
      return initTasksFilter(state, action)

    case appTypes.GET_REPORT_TASKS_INFO_SUCCESS:
      return getReportTasksSuccess(state, action)

    case appTypes.GET_REPORT_TASKS_SETTINGS_SUCCESS:
      return getReportTasksSettingsSuccess(state, action)

    case appTypes.RESET_DATE_RANGE:
      return resetDateRange(state)

    case appTypes.GET_ALL_TASKS_SUCCESS:
      return extractTagsFromTasksToFilters(state, action)

    case types.SET_FILTER_TAG:
      return setFilterTag(state, action)

    default:
      return state
  }
}

export default FiltersPanelReducer
