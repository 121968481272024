import React, { Component } from 'react'
import { connect } from 'react-redux'

import TimeMoneyControls from '../../../UI/TimeMoneyControls'

import * as filtersActions from '../../../FiltersPanel/actions'


class TimeMoneyToggler extends Component {


  render() {
    const { mode } = this.props
    return (
      <TimeMoneyControls
        mode={mode}
        onClick={this.props.switchSettingsTimeMoneyMode}
      />
    )
  }
}

const mapStateToProps = state => ({
  mode: state.filtersPanel.tasksDisplayMode
})

const mapDispatchToProps = dispatch => ({
  switchSettingsTimeMoneyMode: () => dispatch(filtersActions.switchSettingsTimeMoneyMode())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimeMoneyToggler)
