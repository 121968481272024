import axios from 'axios'
import * as config from '../../config'
import { stringifyQuery } from '../../helpers'

class Api {
  token = false

  getToken = () => {
    const token = localStorage.getItem('token')
    if (token && token !== 'undefined') {
      this.token = token
      return token
    }
  }

  removeToken = () => {
    this.token = false
  }

  getHeaders = (extendHeaders = {}) => {
    const headers = {
      Accept: 'application/json'
    }

    Object.keys(extendHeaders).forEach(key => {
      headers[key] = extendHeaders[key]
    })

    this.getToken()

    if (this.token) {
      headers.Authorization = 'Bearer ' + this.token
    }
    return headers
  }

  get = (endpoint, query = {}, body = false) =>
    new Promise(async (resolve, reject) => {
      try {
        const queryString = stringifyQuery(query)
        const response = await axios.get(
          `${config.DATA_API_URL}${endpoint}${queryString}`,
          {
            headers: this.getHeaders(),
            data: {
              body
            }
          }
        )
        const { data, headers } = response
        resolve({ headers, data })
      } catch (error) {
        reject(error.response)
      }
    })

  post = (endpoint = '', query = {}, body) =>
    new Promise(async (resolve, reject) => {
      try {
        const queryString = encodeURI(stringifyQuery(query))
        const response = await axios.post(
          `${config.DATA_API_URL}${endpoint}${queryString}`,
          body,
          {
            headers: this.getHeaders()
          }
        )
        const { headers, data } = response
        resolve({ data: data, headers })
      } catch (error) {
        reject(error.response)
      }
    })

  put = (endpoint = '/', body = '', query) =>
    new Promise(async (resolve, reject) => {
      try {
        const queryString = query ? stringifyQuery(query) : ''
        const response = await axios.put(
          `${config.API_URL}${endpoint}${queryString}`,
          body,
          {
            headers: this.getHeaders()
          }
        )
        const { headers, data } = response
        resolve({ data: data.data, headers })
      } catch (error) {
        reject(error.response)
      }
    })
}

export default new Api()
