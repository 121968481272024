export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST'
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE'
export const SIGN_IN_FETCH = 'SIGN_IN_FETCH'
export const SAVE_TOKEN = 'SAVE_TOKEN'

export const SEND_GOOGLE_TOKEN_ID = 'SEND_GOOGLE_TOKEN_ID'
export const SEND_GOOGLE_TOKEN_ID_SUCCESS = 'SEND_GOOGLE_TOKEN_ID_SUCCESS'
export const SEND_GOOGLE_TOKEN_ID_FAILURE = 'SEND_GOOGLE_TOKEN_ID_FAILURE'

export const GET_LINKS = 'GET_LINKS'
export const GET_LINKS_REQUEST = 'GET_LINKS_REQUEST'
export const GET_LINKS_SUCCESS = 'GET_LINKS_SUCCESS'
export const GET_LINKS_ERROR = 'GET_LINKS_ERROR'

export const SEND_FACEBOOK_TOKEN = 'SEND_FACEBOOK_TOKEN'
export const SEND_FACEBOOK_TOKEN_SUCCESS = 'SEND_FACEBOOK_TOKEN_SUCCESS'
export const SEND_FACEBOOK_TOKEN_FAILURE = 'SEND_FACEBOOK_TOKEN_FAILURE'

export const INIT_APPLE_AUTH = 'INIT_APPLE_AUTH'
export const SEND_APPLE_CREDENTIALS = 'SEND_APPLE_CREDENTIALS'
export const SEND_APPLE_CREDENTIALS_SUCCESS = 'SEND_APPLE_CREDENTIALS_SUCCESS'
export const SEND_APPLE_CREDENTIALS_ERROR = 'SEND_APPLE_CREDENTIALS_ERROR'
