import * as types from '../types'

const initialState = {
  error: null,
  loading: false,
  success: false,
  email: null,
  isEmailSubmitted: false
}

const isLoading = state => ({
  ...state,
  loading: true
})

const isFailed = (state, action) => {
  let error = null
  if (typeof action.payload.data.errors === 'object') {
    error = action.payload.data.errors[0].defaultMessage
  } else {
    error = action.payload.data.error
  }
  return ({
  ...state,
  loading: false,
  error: error
})}

const isSucceed = (state, action) => ({
  ...state,
  loading: false,
  success: true,
  error: false,
  email: action.payload
})

const resendEmailRequest = (state) => ({
  ...state,
  loading: true
})

const resendEmailSuccess = (state) => ({
  ...state,
  loading: false,
  isEmailSubmitted: true
})

const SignUpReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SIGN_UP_REQUEST:
      return isLoading(state)

    case types.SIGN_UP_FAILURE:
      return isFailed(state, action)

    case types.SIGN_UP_SUCCESS:
      return isSucceed(state, action)

    case types.RESEND_EMAIL_SUCCESS:
      return resendEmailSuccess(state)

    case types.RESEND_EMAIL_REQUEST:
        return resendEmailRequest(state)

    default:
      return state
  }
}

export default SignUpReducer
