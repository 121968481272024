export const EDIT_TASK_TIME = 'EDIT_TASK_TIME'
export const EDIT_TASK_TIME_REQUEST = 'EDIT_TASK_TIME_REQUEST'
export const EDIT_TASK_TIME_ERROR = 'EDIT_TASK_TIME_ERROR'
export const EDIT_TASK_TIME_SUCCESS = 'EDIT_TASK_TIME_SUCCESS'

export const EDIT_TASK_PRIORITY = 'EDIT_TASK_PRIORITY'
export const EDIT_TASK_PRIORITY_REQUEST = 'EDIT_TASK_PRIORITY_REQUEST'
export const EDIT_TASK_PRIORITY_ERROR = 'EDIT_TASK_PRIORITY_ERROR'
export const EDIT_TASK_PRIORITY_SUCCESS = 'EDIT_TASK_PRIORITY_SUCCESS'

export const EDIT_TASK_BILLABLE = 'EDIT_TASK_BILLABLE'
export const EDIT_TASK_BILLABLE_ERROR = 'EDIT_TASK_BILLABLE_ERROR'
export const EDIT_TASK_BILLABLE_SUCCESS = 'EDIT_TASK_BILLABLE_SUCCESS'

export const EDIT_TASK_NAME = 'EDIT_TASK_NAME'
export const EDIT_TASK_NAME_REQUEST = 'EDIT_TASK_NAME_REQUEST'
export const EDIT_TASK_NAME_ERROR = 'EDIT_TASK_NAME_ERROR'
export const EDIT_TASK_NAME_SUCCESS = 'EDIT_TASK_NAME_SUCCESS'

export const EDIT_TASK_ICON = 'EDIT_TASK_ICON'
export const EDIT_TASK_ICON_REQUEST = 'EDIT_TASK_ICON_REQUEST'
export const EDIT_TASK_ICON_ERROR = 'EDIT_TASK_ICON_ERROR'
export const EDIT_TASK_ICON_SUCCESS = 'EDIT_TASK_ICON_SUCCESS'

export const EDIT_TASK_COLOR = 'EDIT_TASK_COLOR'
export const EDIT_TASK_COLOR_REQUEST = 'EDIT_TASK_COLOR_REQUEST'
export const EDIT_TASK_COLOR_ERROR = 'EDIT_TASK_COLOR_ERROR'
export const EDIT_TASK_COLOR_SUCCESS = 'EDIT_TASK_COLOR_SUCCESS'

export const EDIT_TASK_CURRENCY = 'EDIT_TASK_CURRENCY'
export const EDIT_TASK_CURRENCY_REQUEST = 'EDIT_TASK_CURRENCY_REQUEST'
export const EDIT_TASK_CURRENCY_ERROR = 'EDIT_TASK_CURRENCY_ERROR'
export const EDIT_TASK_CURRENCY_SUCCESS = 'EDIT_TASK_CURRENCY_SUCCESS'

export const EDIT_TASK_HOURLY_RATE = 'EDIT_TASK_HOURLY_RATE'
export const EDIT_TASK_HOURLY_RATE_REQUEST = 'EDIT_TASK_HOURLY_RATE_REQUEST'
export const EDIT_TASK_HOURLY_RATE_ERROR = 'EDIT_TASK_HOURLY_RATE_ERROR'
export const EDIT_TASK_HOURLY_RATE_SUCCESS = 'EDIT_TASK_HOURLY_RATE_SUCCESS'

export const EDIT_TASK_POMODORO_STATUS_REQUEST =
  'EDIT_TASK_POMODORO_STATUS_REQUEST'
export const EDIT_TASK_POMODORO_STATUS_ERROR = 'EDIT_TASK_POMODORO_STATUS_ERROR'
export const EDIT_TASK_POMODORO_STATUS_SUCCESS =
  'EDIT_TASK_POMODORO_STATUS_SUCCESS'

export const EDIT_TASK_POMODORO_TIME_REQUEST = 'EDIT_TASK_POMODORO_TIME_REQUEST'
export const EDIT_TASK_POMODORO_TIME_ERROR = 'EDIT_TASK_POMODORO_TIME_ERROR'
export const EDIT_TASK_POMODORO_TIME_SUCCESS = 'EDIT_TASK_POMODORO_TIME_SUCCESS'

export const EDIT_TASK_TAG_REQUEST = 'EDIT_TASK_TEG_REQUEST'
export const EDIT_TASK_TAG_ERROR = 'EDIT_TASK_TEG_ERROR'
export const EDIT_TASK_TAG_SUCCESS = 'EDIT_TASK_TEG_SUCCESS'
