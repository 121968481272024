import React, { Component } from "react"
import { connect } from "react-redux"
import { Formik } from "formik"
import { Form } from "./PasswordForm"
import * as Yup from "yup"

import * as actions from "../actions"

const validationSchema = Yup.object({
  password: Yup.string()
    .min(8, "Password must contain at least 8 characters")
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      "Use large and small characters, as well as numbers and special characters like ? ! @ $ %"
    )
    .max(32, "Password is too long")
    .required("Password is required"),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Password confirmation is required"),
})

class PasswordForm extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const values = { password: "", passwordConfirm: "" }
    const { passwordChange, loading, errorsApi } = this.props
    const emailFormLocation = window.location.search
    const email = emailFormLocation.slice(
      emailFormLocation.search("email=") + 6,
      emailFormLocation.search("&key")
    )
    const key = emailFormLocation.slice(emailFormLocation.search("key=") + 4)
    return (
      <React.Fragment>
        <div
          style={{
            maxWidth: "300px",
          }}
        >
          <Formik
            render={(props) => (
              <Form {...props} loading={loading} errorsApi={errorsApi} />
            )}
            initialValues={values}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                passwordChange({
                  email: email,
                  password: values.password,
                  key: key,
                })
                setSubmitting(false)
              }, 400)
            }}
          />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  loading: state.passwordChange.loading,
  errorsApi: state.passwordChange.error,
})

const mapDispatchToProps = (dispatch) => ({
  passwordChange: (data) => dispatch(actions.passwordChange(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PasswordForm)
