import React from 'react'
import { connect } from 'react-redux'
import {Tooltip} from 'react-tippy'
import { Scrollbars } from 'react-custom-scrollbars'

import TaskDetails from '../TaskDetails'
import tasksIcon from '../../../../assets/tasks_list.svg'
import classNames from 'classnames/bind'

import * as appActions from '../../../../App/actions'

class TasksTotalDisplay extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tasksFilter: {}
    }
  }

  componentDidMount = () => {
    this.setState({
      tasksFilter: this.props.tasksFilter
    })
  }

  secondsToHours = value => {
    return <p className='time_total_display_text'>
        {Math.floor(value/3600)}<span>h </span>
        {Math.floor(String(value % 3600).padStart(2,'0')/60)}<span>m</span>
    </p>
  }

  handleClick = (e) => {
    const { getReportWeeklyFilter } = this.props
    let newState = this.state.tasksFilter
    newState[e] = !newState[e]
    this.setState({
      tasksFilter: newState
    })
    const selectedTasks = Object.keys(newState).filter(key => newState[key])
    if (selectedTasks.length) {
      getReportWeeklyFilter(selectedTasks)
    } else {
      getReportWeeklyFilter(['none']) // TF-165 не отображать задачи если не выбрано ни одной задачи
    }
    
  }

  handleVisibleAll = () => {
    const { getReportWeeklyFilter } = this.props
    let newState = this.state.tasksFilter
    newState = this.props.tasksFilter//this.props.allTasks.map(task => task.id)
    this.setState({
      tasksFilter: newState
    })
    const selectedTasks = Object.keys(newState).filter(key => newState[key])
    getReportWeeklyFilter(Object.values(selectedTasks))
  }

  render () {
    const { allTasks } = this.props
    const tasksText = allTasks ? <p className='time_total_display_text'>{allTasks.length}<span>&nbsp;Tasks</span></p> : ''
    const content = <div 
      className={classNames('time_total_display')}
      style={{
        cursor: 'pointer'
        }}>
        <img src={tasksIcon} alt="hours" style={{width:'20px', height:'20px',marginRight: '6px'}}/>
        {tasksText}
      </div>   
    const tasks = allTasks && allTasks.length > 8 ? <Scrollbars 
      renderTrackVertical={props => <div 
        {...props} 
        style={{
            ...props.style, 
            backgroundColor: 'rgba(255,255,255,0.1)', 
            width:'3px',
            right: '4px',
            height: '100%',
            borderRadius: '2px'
        }}/>}
      renderThumbVertical={props => <div {...props} style={{...props.style, backgroundColor: 'rgba(255,255,255,0.2)', borderRadius: '2px'}}/>}
      style={{height: '462px', width: 'calc(100% + 8px)'}}>
      <div style={{width: 'calc(100% - 8px)'}}>
        {allTasks && allTasks.map(task => 
          <TaskDetails 
            key={task.id}
            taskObj = {task}
            onClick={this.handleClick.bind(this, task.id)}
            active = {this.state.tasksFilter[task.id]}
          />
        )}
      </div>
    </Scrollbars> : allTasks && allTasks.map(task => 
          <TaskDetails 
            key={task.id}
            taskObj = {task}
            onClick={this.handleClick.bind(this, task.id)}
            active = {this.state.tasksFilter[task.id]}
          />)
    return (
      <Tooltip
        position="bottom"
        theme='tasks-filter'
        animation={'shift'}
        arrow={true}
        html={<div className='tasks_filter_list_all' style={{width: '300px', paddingBottom: '35px'}}>
          {tasks}
          <div className='tasks_filter_list'>
            <span className='link' onClick={this.handleVisibleAll}>
              Visible all
            </span>
          </div>
        </div>}
        interactive={true}
        trigger={'click'}
      >
        {content}
      </Tooltip>
    )
  }
}

const mapStateToProps = (state) => ({
  tasksFilter: state.app.appState.tasksFilter,
  tasksInfoAvg: state.app.database.tasksInfoAvg,
  allTasks: state.app.database.allTasks
})

const mapDispatchToProps = dispatch => ({
  getReportWeeklyFilter: ids => dispatch(appActions.getReportWeeklyFilter(ids))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(TasksTotalDisplay)
