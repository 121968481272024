import React from 'react'
import TextField from '@material-ui/core/TextField'

import Button from '../../../../UI/Button'
import Preloader from '../../../../UI/Preloader'

const fieldNameToLabel = {
  email: 'Your email',
  lastName: 'Your last name',
  firstName: 'Your first name'
}

const Form = props => {
  const {
    values,
    errors,
    touched,
    handleChange,
    isValid,
    setFieldTouched,
    handleSubmit,
    loading,
    errorsApi
  } = props
  const change = (name, e) => {
    if (name === 'email') {
      e.target.value = e.target.value.replace(/ |[А-Яа-я]/g, '')
    }
    e.persist()
    handleChange(e)
    setFieldTouched(name, true, false)
  }

  const renderFields = () =>
    Object.keys(values).map(key => (
      <TextField
        key={key}
        id={key}
        name={key}
        label={fieldNameToLabel[key]}
        style={{ marginBottom: '14px' }}
        className="auth_input"
        helperText={touched.userName ? errors.userName : ''}
        error={touched.userName && Boolean(errors.userName)}
        value={values[key]}
        onChange={change.bind(null, key)}
      />
    ))

  return (
    <form onSubmit={handleSubmit}>
      {renderFields()}
      {errorsApi && (
        <p className="auth_errors">
          {`${errorsApi.data.error}. ${errorsApi.data.message}`}
        </p>
      )}
      <Button
        className="button"
        type="submit"
        color="primary"
        style={{ minWidth: '300px', marginTop: '36px' }}
        disabled={!isValid || loading}>
        {loading ? <Preloader mini /> : 'Continue'}
      </Button>
    </form>
  )
}

export default Form
