/* eslint-disable  default-case */
import { takeEvery, put, all, call, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import JWT from 'jsonwebtoken'

import * as types from '../types'
import * as actions from '../actions'
import * as appActions from '../../../../App/actions'
import Api from '../../../../services/Api'
import { API_ENDPOINTS } from '../../../../constants/index'

function* signInAsync(action) {
  try {
    yield put(actions.signInRequest())
    let error = {}
    const response = yield call(
      Api.post,
      API_ENDPOINTS.authSignIn,
      '',
      action.payload
    )
    const { headers, data } = response

    if (data.msg === 'Please confirm your email address') {
      error = {
        data: {
          message: 'Please confirm your email address'
        }
      }
      throw error
    } else {
      yield put(appActions.setCurrentUser(data.user))
      yield put(actions.saveToken(headers.token))
      localStorage.setItem('token', headers.token)

      let tokenDecoded,
        isAdmin = false
      if (headers.token) {
        tokenDecoded = JWT.decode(headers.token)
        isAdmin = tokenDecoded.roles && tokenDecoded.roles.includes('ADMIN')
      }

      yield put(appActions.setRole(isAdmin ? 'admin' : 'user'))

      const location = yield select(state => state.router.location)

      if (isAdmin) {
        yield put(push('/update'))
      } else {
        if (location.from) {
          yield put(push(location.from.pathname))
        } else {
          yield put(push('/'))
        }
      }

      yield put(actions.signInSuccess())
    }
  } catch (e) {
    yield put(actions.signInFailure(e))
  }
}

function* getLinksAsync() {
  try {
    yield put(actions.getLinksRequest())
    const response = yield call(Api.get, API_ENDPOINTS.oauth)
    const { data } = response
    yield put(actions.getLinksSuccess(data))
  } catch (error) {
    yield put(actions.getLinksError(error))
  }
}

function* sendFacebookTokenIdAsync({ token }) {
  try {
    const response = yield call(
      Api.post,
      API_ENDPOINTS.authGoogleOrFacebook,
      '',
      {
        token
      }
    )
    const { headers, data } = response
    yield put(appActions.setCurrentUser(data.user))
    yield put(appActions.saveToken(headers.token))
    localStorage.setItem('token', headers.token)
    yield put(push('/'))
    yield put(actions.sendFacebookTokenIdSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(actions.sendFacebookTokenIdFailure(error))
  }
}

function* sendGoogleTokenIdAsync({ token }) {
  try {
    const response = yield call(
      Api.post,
      API_ENDPOINTS.authGoogleOrFacebook,
      '',
      {
        token
      }
    )
    const { headers, data } = response
    yield put(appActions.setCurrentUser(data.user))
    yield put(appActions.saveToken(headers.token))
    localStorage.setItem('token', headers.token)
    yield put(push('/'))
    yield put(actions.sendGoogleTokenIdSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(actions.sendGoogleTokenIdFailure(error))
  }
}

function* initAppleAuthAsync() {
  try {
    yield call(window.AppleID.auth.init, {
      clientId: 'io.timeflip.timeflipwebapp',
      redirectURI: `${process.env.REACT_APP_APP_URL}auth`,
      scope: 'name email',
      state: 'signIn',
      usePopup: true
    })
  } catch (error) {
    console.log(error)
    yield put(actions.sendAppleTokenIdFailure(error))
  }
}

function* sendAppleTokenIdAsync() {
  try {
    const appleResponse = yield call(window.AppleID.auth.signIn)
    const {
      authorization: { id_token },
      user: appleUser
    } = appleResponse

    const email = appleUser ? appleUser.email : null
    const user = appleUser ? appleUser.user : null

    const response = yield call(Api.post, API_ENDPOINTS.appleAuth, '', {
      identityToken: id_token,
      user: user,
      email: email
    })
    const { headers, data } = response

    yield put(appActions.setCurrentUser(data.user))
    yield put(appActions.saveToken(headers.token))
    localStorage.setItem('token', headers.token)
    yield put(push('/'))
    yield put(actions.sendAppleTokenIdSuccess(response))
  } catch (error) {
    yield put(actions.sendAppleTokenIdFailure(error))
    if (error.identityToken) yield put(push('/complete-info'))
  }
}

export default function* signInSaga() {
  yield all([
    takeEvery(types.GET_LINKS, getLinksAsync),
    takeEvery(types.SIGN_IN_FETCH, signInAsync),
    takeEvery(types.SEND_GOOGLE_TOKEN_ID, sendGoogleTokenIdAsync),
    takeEvery(types.SEND_FACEBOOK_TOKEN, sendFacebookTokenIdAsync),
    takeEvery(types.INIT_APPLE_AUTH, initAppleAuthAsync),
    takeEvery(types.SEND_APPLE_CREDENTIALS, sendAppleTokenIdAsync)
  ])
}
