import * as types from "../types"

const initialState = {
  error: null,
  loading: true,
  currentVersion: "",
  updateUploaded: false,
}

const Update = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.GET_CURRENT_VERSION_SUCCESS:
      return {
        ...state,
        loading: false,
        currentVersion: action.payload,
      }

    case types.GET_CURRENT_VERSION_FAILURE:
      return {
        ...state,
        loading: false,
        currentVersion: "-",
      }

    case types.UPLOAD_FILE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case types.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        updateUploaded: true,
      }

    case types.UPLOAD_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        updateUploaded: false,
      }

    default:
      return state
  }
}

export default Update
