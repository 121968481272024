import React from 'react'

import {ICONS, EXCLUDE_KEYS} from '../../../../../../../../constants'

class IconPicker extends React.Component {

    handleOnClick = () => {

    }

    render () {
        const { onClick } = this.props
        return (
            <ul className="iconpicker">
                {Object.keys(ICONS).map((key, val) => 
                    {
                        return EXCLUDE_KEYS.indexOf(key) === -1 ? <li 
                            key={key}
                            onClick={onClick.bind(this,key)}
                        >
                            {ICONS[key]}
                        </li> 
                        : 
                        ""
                    }
                )}
            </ul>
        )
    }
}

export default IconPicker