import { all, put, select, call, takeEvery } from 'redux-saga/effects'
import { API_URL } from '../../../config'

import * as appActions from '../../../App/actions'
import Api from '../../../services/Api'
import { API_ENDPOINTS } from '../../../constants/index'

import * as types from '../types'
import { handleSagaError } from '../../../helpers'

function* downloadXLSXAsync(action) {
  const { pathname } = yield select(state => state.router.location)
  const { filters } = yield select(state => state.filtersPanel)
  const { selectedDateRange } = yield select(state => state.app.appState)
  const { currencies, tasksInfoAvg } = yield select(state => state.app.database)

  const { query, body } = getRequestParams({ filters, selectedDateRange, currencies, tasksInfoAvg, pathname })
  try {
    const response = yield call(Api.post, API_ENDPOINTS.exportXlsx, query, body)
    let ifrm = document.createElement('iframe')
    ifrm.setAttribute('src', API_URL + 'files/file?name=' + response.data)
    ifrm.style.width = '0px'
    ifrm.style.height = '0px'
    ifrm.style.display = 'none'
    document.body.appendChild(ifrm)
  } catch (error) {
    console.log('download error', error)
    yield handleSagaError(error)
  }
}

function* downloadCSVAsync(action) {
  const { pathname } = yield select(state => state.router.location)
  const { filters } = yield select(state => state.filtersPanel)
  const { selectedDateRange } = yield select(state => state.app.appState)
  const { currencies, tasksInfoAvg } = yield select(state => state.app.database)

  const { query, body } = getRequestParams({ filters, selectedDateRange, currencies, tasksInfoAvg, pathname })
  try {
    const response = yield call(Api.post, API_ENDPOINTS.exportCsv, query, body)
    let ifrm = document.createElement('iframe')
    ifrm.setAttribute('src', API_URL + 'files/file?name=' + response.data)
    ifrm.style.width = '0px'
    ifrm.style.height = '0px'
    ifrm.style.display = 'none'
    document.body.appendChild(ifrm)
  } catch (error) {
    console.log('download error', error)
    yield handleSagaError(error)
  }
}

function getRequestParams({ filters, selectedDateRange, currencies, tasksInfoAvg, pathname }) {
    // currecies
    let selectedCurrencyId
    currencies.map(currency => {
      if (filters.currencyes[currency.key]) {
        selectedCurrencyId = currency.id
      }
      return false
    })
    // body
    let tasksIds = tasksInfoAvg.map(row => row.task.id)
    if (pathname === "/report") {
      if (tasksIds.length === 0) {
        tasksIds.push(0)
      }
    } else if (pathname === "/timesheet") {
      tasksIds = []
    }

    const body = {
      taskIds: [...tasksIds],
      tags:
        typeof filters.priority.tag === undefined
          ? []
          : [...Object.keys(filters.priority.tag).filter(value => filters.priority.tag[value])]
    }
    
    // query
    let isHighPriority = ''
    let isBillable = ''
    let currencyId = ''

    if (!filters.priority.all) {
      if (filters.priority.priority) {
        isHighPriority = true
      }
      if (filters.priority.billable) {
        isBillable = true
        currencyId = !filters.currencyes.all ? selectedCurrencyId : ''
      }
    }

    const query = {
      beginDateStr: selectedDateRange[0],
      endDateStr: selectedDateRange[1],
      isHighPriority,
      isBillable,
      currencyId,
    }

    return { query, body }
}

function* setFilterPriorityAsync() {
  yield put(appActions.getReportTasksRequest())
}
function* setFilterCurrencyAsync() {
  yield put(appActions.getReportTasksRequest())
}
function* setFilterClearAsync() {
  yield put(appActions.getReportTasksRequest())
}

function* setFilterTagRequest() {
  yield put(appActions.getReportTasksRequest())
}

export default function* toolsPanelSaga() {
  yield all([
    takeEvery(types.DOWNLOAD_XLSX, downloadXLSXAsync),
    takeEvery(types.DOWNLOAD_CSV, downloadCSVAsync),
    takeEvery(types.SET_FILTER_PRIORITY, setFilterPriorityAsync),
    takeEvery(types.SET_FILTER_CURRENCYES, setFilterCurrencyAsync),
    takeEvery(types.SET_FILTER_TAG_REQUEST, setFilterTagRequest),
    takeEvery(types.SET_FILTERS_CLEAR, setFilterClearAsync)
  ])
}
