import React from 'react'
import PropTypes from 'prop-types'

import TaskStatus from '../TaskStatus'
import { ICONS } from '../../../constants'

import classNames from 'classnames/bind'

class TaskDetails extends React.Component {
  render() {
    const {
      onClick,
      taskId,
      taskObj,
      billable,
      priority,
      taskIcon,
      taskTime,
      taskTitle,
      taskColor,
      mode,
      currencyes,
      style
    } = this.props
    const { pomodoro } = taskObj.task
    const totalMoney =
      taskObj && taskObj.totalPaymentByCurrencies.length
        ? taskObj.totalPaymentByCurrencies[0].value
        : 0
    let symbol = []
    if (currencyes.length && taskObj.task.currencyId) {
      symbol = currencyes.filter(currency => {
        return currency.id === taskObj.task.currencyId
      })[0].symbol
    }
    let taskRelativeValue
    if (mode === 'time') {
      taskRelativeValue = taskObj.totalTimeRatio
    } else {
      taskRelativeValue = taskObj.totalPaymentRatio
    }
    return (
      <div
        style={style}
        className={classNames('task_details', { taskTime: 'dummy' })}
        onClick={onClick.bind(this, { taskId: taskId, taskObj: taskObj })}>
        <div className="task_details_placeholder">
          <div className="task_details_left">
            {ICONS[taskIcon]}
            <div className="task_details_left_text_container">
              <p className="task_details_title">{taskTitle}</p>
              {!!taskObj.task.tag && <span>#{taskObj.task.tag}</span>}
            </div>
          </div>
          <div className="task_deatils_right">
            <TaskStatus
              billable={billable}
              priority={priority}
              pomodoro={pomodoro}
            />
            {mode === 'time' ? (
              <p>
                {taskTime[0]}
                <span className="muted">h&nbsp;</span>
                {taskTime[1]}
                <span className="muted">m</span>
              </p>
            ) : (
              <p>
                <span className="muted">{symbol}</span>
                {totalMoney}
              </p>
            )}
          </div>
        </div>
        <div
          className="task_deatails_statusbar"
          style={{
            width: taskRelativeValue * 100 + '%',
            background: taskColor
          }}
        />
      </div>
    )
  }
}

TaskDetails.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func
}

TaskDetails.defaultProps = {
  onClick: () => {}
}

export default TaskDetails
