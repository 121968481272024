import React from 'react'
import { connect } from 'react-redux'
import { Tooltip } from 'react-tippy'

import IconPicker from './components/IconPicker'
import ButtonEdit from '../../../../../UI/ButtonEdit'
import Preloader from '../../../../../UI/Preloader'
import * as actions from '../../actions'
import { ICONS } from '../../../../../../constants'


class EditTaskIcon extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            readonly: null,
            active: null,
            tooltipIsOpen: null,
            taskIcon: null
        }
    }

    componentDidMount = () => {
        this.setState(state => {
            return {
                readonly: true,
                active: false,
                tooltipIsOpen: false,
                taskIcon: this.props.taskIcon || ''
            }
        })
    }

    handleClick = () => {
        this.setState(state => {
            return {
                ...state,
                active: true,
                tooltipIsOpen: true
            }
        })
    }
    handleOutsideClick = () => {
        this.setState(state => {
            return {
                ...state,
                active: false,
                tooltipIsOpen: false
            }
        })
    }

    handleSubmit = () => {
        const icon = this.state.taskIcon
        if (this.props.taskIcon !== icon) {
            if (icon === 'NONE') {
                this.props.editTaskIcon("null")
                this.setState(state => {
                    return {
                        ...state,
                        taskIcon: ""
                    }
                })
            } else {
                this.props.editTaskIcon(icon)
            }
        }
        this.setState(state => {
            return {readonly: true}
        })
    }

    handlePickIcon = (key) => {
        this.setState(state => {
            return {
                ...state,
                active: false,
                tooltipIsOpen: false,
                readonly: false,
                taskIcon: key
            }
        })
    }

    render () {
        const { loading } = this.props
        const { taskIcon } = this.state
        const currentIcon = ICONS[taskIcon] || ICONS.NONE
        return (
            <div className="task_settings_item">
                <div className="task_settings_item_left">
                    <p className="task_settings_item_left_label">
                        Icon
                    </p>
                </div>
                <div className="task_settings_item_right">
                    <div style={{
                        marginRight: '9px',
                        display: 'flex',
                        alignItems: 'center',
                        maxHeight: 40,
                        maxWidth: 40,
                        objectFit: 'contain'
                    }}>
                        {currentIcon}
                    </div>
                    <div className='task_settings_item_form'>
                        <Tooltip
                            trigger='click'
                            position="left"
                            html={<IconPicker onClick={this.handlePickIcon}/>}
                            arrow={true}
                            interactive={true}
                            onRequestClose={this.handleOutsideClick}
                            hideOnClick = {true}
                            open = {this.state.tooltipIsOpen}
                        >
                            <div>
                                {loading && <Preloader mini />}
                                {!loading && (
                                    this.state.readonly ? 
                                    <ButtonEdit classActive={this.state.active} onClick={this.handleClick} /> : 
                                    <ButtonEdit active onClick={this.handleSubmit} disabled={this.state.isSubmitting}/>
                                )}
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    taskIcon: state.app.appState.selectedTaskToEditObj.task.icon,
    loading: state.app.appState.editIconLoading
})

const mapDispatchToProps = dispatch => ({
    editTaskIcon: newIcon => dispatch(actions.editTaskIcon(newIcon))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditTaskIcon)


