import React from 'react'
import { connect } from 'react-redux'
import { Tooltip } from 'react-tippy'

import CurrencyPicker from './components/CurrencyPicker'
import ButtonEdit from '../../../../../UI/ButtonEdit'
import Preloader from '../../../../../UI/Preloader'
import * as actions from '../../actions'
import * as appActions from '../../../../../../App/actions'


class EditTaskCurrency extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            readonly: null,
            active: null,
            tooltipIsOpen: null,
            currency: {}
        }
    }

    componentDidMount = () => {
        this.setState(state => {
            return {
                readonly: true,
                active: false,
                tooltipIsOpen: false,
                currency: this.props.currencyes.find(currency => {
                    return currency.id === this.props.currencyId
                }) || ''
            }
        })
    }

    handleClick = () => {
        this.setState(state => {
            return {
                ...state,
                active: true,
                tooltipIsOpen: true
            }
        })
    }
    handleOutsideClick = () => {
        this.setState(state => {
            return {
                ...state,
                active: false,
                tooltipIsOpen: false
            }
        })
    }

    handleSubmit = () => {
        const { editTaskCurrency, getReportTasksSettings, getSettingsTaskDaily, mode } = this.props
        const cuerrentCurrencyId = this.state.currency.id
        const prevCurrencyId = this.props.currencyId
        const getDataAction = mode === 'week' ? getReportTasksSettings : getSettingsTaskDaily
        if (prevCurrencyId !== cuerrentCurrencyId) {
            editTaskCurrency(cuerrentCurrencyId)
            getDataAction()
        }
        this.setState( {
            readonly: true
        })
    }

    handleSelectCurrency = (id) => {
        this.setState(state => {
            return {
                ...state,
                readonly: false,
                active: false,
                tooltipIsOpen: false,
                currency: this.props.currencyes.find(currency => {
                    return currency.id === id
                })
            }
        })
    }

    render () {
        const { currencyes, loading } = this.props
        const currentCurrency = this.state.currency
        return (
            <div className="task_settings_item">
                <div className="task_settings_item_left">
                    <p className="task_settings_item_left_label">
                        Currency
                    </p>
                </div>
                <div className="task_settings_item_right">
                    <div style={{
                        marginRight: '9px'
                    }}>
                        <div className="edit_current_currency">
                            <span className="symbol">{currentCurrency.symbol}&nbsp;</span>
                            <span className="name">{currentCurrency.key}</span>
                        </div>
                    </div>
                    <div className='task_settings_item_form'>
                        <Tooltip
                            trigger='click'
                            position="left"
                            html={<CurrencyPicker 
                                onClick={this.handleSelectCurrency}
                                currencyes={currencyes}
                                />}
                            theme="currencyes"
                            arrow={true}
                            interactive={true}
                            onRequestClose={this.handleOutsideClick}
                            hideOnClick = {true}
                            open = {this.state.tooltipIsOpen}
                        >
                            <div>
                                {loading && <Preloader mini />}
                                {!loading && (this.state.readonly ? 
                                    <ButtonEdit classActive={this.state.active} onClick={this.handleClick} /> : 
                                    <ButtonEdit active onClick={this.handleSubmit} disabled={this.state.isSubmitting}/>)
                                }
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    currencyId: state.app.appState.selectedTaskToEditObj.task.currencyId,
    currencyes: state.app.database.currencyes,
    loading: state.app.appState.editCurrencyLoading,
    mode: state.app.appState.taskSettingsViewMode
})

const mapDispatchToProps = dispatch => ({
    editTaskCurrency: newCurrencyId => dispatch(actions.editTaskCurrency(newCurrencyId)),
    getSettingsTaskDaily: () => dispatch(appActions.getSettingsTaskDaily()),
    getReportTasksSettings: () => dispatch(appActions.getReportTasksSettings())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditTaskCurrency)


