import React from 'react'
import PropTypes from 'prop-types'
import Truncate from 'react-truncate'

import CloseX from '../../Svg/CloseX'

import classNames from 'classnames/bind'


const Label = ({ children, onClick, ...props }) => (
  <span
    {...props}
    className={classNames('label', {  })}
    onClick={onClick}>
      <Truncate
        lines={1}
        width={120}
      >
        {children}
      </Truncate>
    <CloseX style={{marginLeft: '10px'}} />
  </span>
)

Label.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func
}

Label.defaultProps = { 
  onClick: () => {}
}

export default Label