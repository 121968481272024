import * as types from '../types'

export const signInRequest = () => ({
  type: types.SIGN_IN_REQUEST
})

export const signInSuccess = payload => ({
  type: types.SIGN_IN_SUCCESS,
  payload
})

export const signInFailure = payload => ({
  type: types.SIGN_IN_FAILURE,
  payload
})

export const signInFetch = user => ({
  type: types.SIGN_IN_FETCH,
  payload: user
})

export const saveToken = token => ({
  type: types.SAVE_TOKEN,
  payload: token
})

export const sendGoogleTokenId = token => ({
  type: types.SEND_GOOGLE_TOKEN_ID,
  token: token
})

export const initAppleAuth = () => ({
  type: types.INIT_APPLE_AUTH
})
export const sendAppleTokenId = () => ({
  type: types.SEND_APPLE_CREDENTIALS
})
export const sendAppleTokenIdSuccess = payload => ({
  type: types.SEND_APPLE_CREDENTIALS_SUCCESS,
  payload
})
export const sendAppleTokenIdFailure = payload => ({
  type: types.SEND_APPLE_CREDENTIALS_ERROR,
  payload
})

export const sendGoogleTokenIdSuccess = payload => ({
  type: types.SEND_GOOGLE_TOKEN_ID_SUCCESS,
  payload
})
export const sendGoogleTokenIdFailure = payload => ({
  type: types.SEND_GOOGLE_TOKEN_ID_FAILURE,
  payload
})

export const sendFacebookTokenId = token => ({
  type: types.SEND_FACEBOOK_TOKEN,
  token
})
export const sendFacebookTokenIdSuccess = payload => ({
  type: types.SEND_FACEBOOK_TOKEN_SUCCESS,
  payload
})
export const sendFacebookTokenIdFailure = payload => ({
  type: types.SEND_FACEBOOK_TOKEN_FAILURE,
  payload
})

export const getLinksRequest = () => ({
  type: types.GET_LINKS_REQUEST
})
export const getLinksSuccess = payload => ({
  type: types.GET_LINKS_SUCCESS,
  payload
})
export const getLinksError = error => ({
  type: types.GET_LINKS_ERROR,
  error
})
