export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST'
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS'
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE'
export const SIGN_UP_FETCH = 'SIGN_UP_FETCH'
export const SAVE_TOKEN = 'SAVE_TOKEN'

export const GET_LINKS = 'GET_LINKS'
export const GET_LINKS_REQUEST = 'GET_LINKS_REQUEST'
export const GET_LINKS_SUCCESS = 'GET_LINKS_SUCCESS'
export const GET_LINKS_ERROR = 'GET_LINKS_ERROR'

export const RESEND_EMAIL = 'RESEND_EMAIL'
export const RESEND_EMAIL_REQUEST = 'RESEND_EMAIL_REQUEST'
export const RESEND_EMAIL_ERROR = 'RESEND_EMAIL_ERROR'
export const RESEND_EMAIL_SUCCESS = 'RESEND_EMAIL_SUCCESS'