import React from 'react'
import { connect } from 'react-redux'

import Layout from '../Layout'
import googleIcon from '../../assets/ic_Google_Calendar.svg'
import appleIcon from '../../assets/ic_Apple_Calendar.svg'
import outlookIcon from '../../assets/ic_outlook-calendar.svg'
import checkIcon from '../../assets/check_icon2.svg'
import classNames from 'classnames/bind'

class Integrations extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			isCoping: false,
		}
	}

	actionCopyLink = () => {
		const url = this.props.userInfo.ics
		if (typeof url !== "undefined") {
			navigator.clipboard.writeText(url)
			.then(() => {
				console.log("success coping link to clipboard!")
				this.setState({
					isCoping: true,
				})
			})
			.catch(err => {
				console.log('error coping link to clipboard', err)
				this.setState({
					isCoping: false,
				})
			})
		} else {
			console.error('link is undefined')
		}
	}

	render() {
		const { isCoping } = this.state
		let buttonMess
		if (isCoping) {
			buttonMess = <span><img className="integrations-checkIcon" src={checkIcon} alt="" /><span>Copied</span></span>
		} else {
			buttonMess = <span>Copy link</span>
		}
		const content = (
			<React.Fragment>
				<div className="main_workspace">
        			<div className="main_workspace_header">
						<h1>Integrations</h1>
					</div>
					<div className="integrations">
						<h2 className="integrations-title">Calendars</h2>
						<p className="integrations-text">Subscribe your calendar app to your TimeFlip</p>
						<button onClick={this.actionCopyLink} className={classNames('integrations-copyLinkBut', { '-active' : isCoping })}>
							{buttonMess}	
						</button>
						<p className="integrations-text">Learn how to sync your calendar</p>
						<div className="integrations-linksBox">
							<a href="https://support.google.com/calendar/answer/37100?co=GENIE.Platform%3DDesktop&hl=en#zippy=%2Cadd-using-a-link"
							 rel="noopener noreferrer" target="_blank" className="integrations-link">
								<img title="Google calendar" src={googleIcon} alt="Google calendar"/>
							</a>
							<a href="https://support.apple.com/guide/calendar/subscribe-to-calendars-icl1022/mac"
							 rel="noopener noreferrer" target="_blank" className="integrations-link">
								<img title="Apple calendar" src={appleIcon} alt="Apple calendar"/>
							</a>
							<a href="https://support.microsoft.com/en-us/office/import-or-subscribe-to-a-calendar-in-outlook-on-the-web-503ffaf6-7b86-44fe-8dd6-8099d95f38df" 
								rel="noopener noreferrer" target="_blank" className="integrations-link">
								<img title="Outlook calendar" src={outlookIcon} alt="Outlook calendar"/>
							</a>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
		return <Layout content={content} />
	}
}

const mapStateToProps = state => {
	return {
		userInfo: state.app.user,
		loading: state.app.appState.loading,
	}
}

export default connect(
	mapStateToProps,
)(Integrations)
