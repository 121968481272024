/* eslint-disable  default-case */
import { takeEvery, all, put, call } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import * as types from '../types'
import * as actions from '../actions'
import * as completeUserActions from '../../CompleteUserInfo/actions'
import Api from '../../../../services/Api'
import { API_ENDPOINTS } from '../../../../constants/index'

function* signUpAsync(action) {
  try {
    yield put(actions.signUpRequest())
    yield call(Api.post, API_ENDPOINTS.authSignUp, '', action.payload)
    yield put(actions.signUpSuccess(action.payload.email))
    yield put(push('/confirm-email'))
  } catch (e) {
    yield put(actions.signUpFailure(e))
  }
}

function* signUpSuccessWorker() {
  try {
    yield put(completeUserActions.getUserInfoCompletedStatus())
  } catch (error) {
    console.log(error)
  }
}

function* getLinksAsync() {
  try {
    yield put(actions.getLinksRequest())
    const response = yield call(Api.get, API_ENDPOINTS.oauth)
    const { data } = response
    yield put(actions.getLinksSuccess(data))
  } catch (error) {
    yield put(actions.getLinksError(error))
  }
}

function* resendEmailAsync(action) {
  try {
    yield put(actions.resendEmailRequest())
    yield call(Api.post, API_ENDPOINTS.authEmailSendVerification, {
      email: action.email
    })
    yield put(actions.resendEmailSuccess())
  } catch (error) {
    yield put(actions.resendEmailError(error))
  }
}

export default function* signUpSaga() {
  yield all([
    takeEvery(types.SIGN_UP_FETCH, signUpAsync),
    takeEvery(types.SIGN_UP_SUCCESS, signUpSuccessWorker),
    takeEvery(types.GET_LINKS, getLinksAsync),
    takeEvery(types.RESEND_EMAIL, resendEmailAsync)
  ])
}
