import { takeEvery, put, call, all } from 'redux-saga/effects'

import * as types from '../types'
import * as actions from '../actions'
import Api from '../../../../services/Api'
import { API_ENDPOINTS } from '../../../../constants/index'

function* passwordChangeAsync(data) {
  try {
    yield call(Api.post, API_ENDPOINTS.passwordReset, '', data.data)
    yield put(actions.passwordChangeSuccess())
  } catch (error) {
    yield put(actions.passwordChangeFailure(error))
  }
}

export default function* passwordChangeSaga() {
  yield all([takeEvery(types.PASSWORD_CHANGE, passwordChangeAsync)])
}
