import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Scrollbars } from 'react-custom-scrollbars'

import DayModule from '../../../UI/DayModule'
import WeekModule from '../../../UI/WeekModule'
import NoData from '../../../NoData'

import * as appActions from '../../../../App/actions'
import * as filterActions from '../../../FiltersPanel/actions'

class DaysView extends React.Component {
  handleOnClickDay = date => {
    return this.props.selectDaysToView(date)
  }
  isActiveDay = data => {
    return data === this.props.pickedDay
  }

  onWeekClickHandler = day => {
    const { getReportTasksRequest, setDateRange } = this.props
    const startDate = moment()
      .year(day.year)
      .week(day.onum)
      .day(0)
      .format('YYYY-MM-DD')
    const endDate = moment()
      .year(day.year)
      .week(day.onum)
      .day(6)
      .format('YYYY-MM-DD')
    setDateRange([startDate, endDate])
    getReportTasksRequest()
  }

  render() {
    const {
      weeks,
      mode,
      taskSettingsView,
      timeSheetView,
      reportView,
      currencyes,
      selectedCurrency
    } = this.props
    const { totalTime, totalPayment } = weeks
    const weeksClone = JSON.parse(JSON.stringify(weeks))
    weeksClone.weeks.map((week, index) => {
      weeksClone.weeks[index].days.unshift(week)
      return null
    })
    const days = []
    weeksClone.weeks.map(week => {
      week.days.map(day => {
        days.push(day)
        return null
      })
      return null
    })
    const scrollbarsStyles =
      days.length > 48
        ? {
            renderTrackVertical: props => (
              <div
                {...props}
                style={{
                  ...props.style,
                  backgroundColor: 'rgba(255,255,255,0.1)',
                  right: '2px',
                  height: '100%',
                  borderRadius: '3px'
                }}
              />
            ),
            renderThumbVertical: props => (
              <div
                {...props}
                style={{
                  ...props.style,
                  backgroundColor: 'rgba(255,255,255,0.2)',
                  borderRadius: '3px'
                }}
              />
            )
          }
        : {}
    return !totalTime && !totalPayment.length ? (
      <NoData />
    ) : (
      <div className="days_view_placeholder_col">
        <div className="days_view_header">
          <div className="days_view_weeks_placeholder">
            <span className="days_view_weeks_label">Week #</span>
          </div>
          <div className="days_view_days_placeholder">
            <ul className="days_view_days_labels">
              <li className="days_view_day">S</li>
              <li className="days_view_day">M</li>
              <li className="days_view_day">T</li>
              <li className="days_view_day">W</li>
              <li className="days_view_day">T</li>
              <li className="days_view_day">F</li>
              <li className="days_view_day">S</li>
            </ul>
          </div>
        </div>
        <Scrollbars
          style={{ height: '540px', width: 'calc(100% + 10px)' }}
          {...scrollbarsStyles}>
          <div
            className="days_view_days_rows_daily"
            style={{ width: 'calc(100% - 10px)' }}>
            {days.map((day, index) => {
              if (+index % 8 === 0) {
                return (
                  <WeekModule
                    key={index}
                    date={day.onum}
                    time={true}
                    money={false}
                    value={day.totalTime}
                    taskSettingsView={taskSettingsView}
                    week={day}
                    mode={mode}
                    currencyes={currencyes}
                    selectedcurrency={selectedCurrency}
                    onClick={this.onWeekClickHandler.bind(this, day)}
                  />
                )
              } else {
                return (
                  <DayModule
                    key={index}
                    date={day.dateStr}
                    dateObj={day}
                    onClick={this.handleOnClickDay}
                    active={this.isActiveDay(day.dateStr)}
                    disabled={!day.inPeriod}
                    mode={mode}
                    timeSheetView={timeSheetView}
                    reportView={reportView}
                    value={day.totalTime}
                    currencyes={currencyes}
                    selectedcurrency={selectedCurrency}
                  />
                )
              }
            })}
          </div>
        </Scrollbars>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  weeks: state.app.database,
  mode: state.filtersPanel.tasksDisplayMode,
  timeSheetView: state.app.appState.timeSheetView,
  reportView: state.app.appState.reportView,
  beginDateStr: state.app.database.beginDateStr,
  endDateStr: state.app.database.endDateStr,
  pickedDay: state.app.appState.pickedDay,
  taskSettingsView: state.app.appState.taskSettingsView,
  currencyes: state.app.database.currencies,
  selectedCurrency: state.filtersPanel.filters.currencyes
})

const mapDispatchToProps = dispatch => ({
  selectDaysToView: data => dispatch(appActions.selectDaysToView(data)),
  getReportTasksRequest: () => dispatch(appActions.getReportTasksRequest()),
  setDateRange: dateRange => dispatch(filterActions.setDateRange(dateRange))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DaysView)
