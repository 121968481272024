import React from 'react'
import { connect } from 'react-redux'
import { Tooltip } from 'react-tippy'

import ColorPicker from './components/ColorPicker'
import ButtonEdit from '../../../../../UI/ButtonEdit'
import Preloader from '../../../../../UI/Preloader'
import * as actions from '../../actions'
import { ICONS } from '../../../../../../constants';



class EditTaskColor extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            readonly: null,
            active: null,
            tooltipIsOpen: null,
            color: null
        }
    }

    componentDidMount = () => {
        this.setState(state => {
            return {
                readonly: true,
                active: false,
                tooltipIsOpen: false,
                color: this.props.taskColor || ''
            }
        })
    }

    handleClick = () => {
        this.setState(state => {
            return {
                ...state,
                active: true,
                tooltipIsOpen: true
            }
        })
    }
    handleOutsideClick = () => {
        this.setState(state => {
            return {
                ...state,
                active: false,
                tooltipIsOpen: false
            }
        })
    }

    handleSubmit = () => {
        if (this.state.color !== this.props.taskColor) {
            this.props.editTaskColor(this.state.color)
        }
        this.setState(state => {
            return {readonly: true}
        })
    }

    handlePickIcon = (color) => {
        this.setState(state => {
            return {
                ...state,
                active: false,
                tooltipIsOpen: false,
                readonly: false,
                color: color,
            }
        })
    }

    render () {
        const { loading } = this.props
        const taskColor = this.state.color
        return (
            <div className="task_settings_item">
                <div className="task_settings_item_left">
                    <p className="task_settings_item_left_label">
                        Color
                    </p>
                </div>
                <div className="task_settings_item_right">
                    <div style={{
                        marginRight: '9px'
                    }}>
                        {taskColor === "" ? 
                        <div 
                            className="edit_current_color"
                            style={{backgroundColor:"transparent"}}
                        >
                            { ICONS.NONE }
                        </div>
                        : 
                        <div 
                            className="edit_current_color"
                            style={{backgroundColor:taskColor}}
                        >

                        </div>
                    }
                    </div>
                    <div className='task_settings_item_form'>
                        <Tooltip
                            trigger='click'
                            position="left"
                            html={<ColorPicker onClick={this.handlePickIcon} taskCurrentColor = {taskColor}/>}
                            arrow={true}
                            interactive={true}
                            onRequestClose={this.handleOutsideClick}
                            hideOnClick = {true}
                            open = {this.state.tooltipIsOpen}
                        >
                            <div>
                                {loading && <Preloader mini />}
                                {!loading && (
                                    this.state.readonly ? 
                                    <ButtonEdit classActive={this.state.active} onClick={this.handleClick} /> : 
                                    <ButtonEdit active onClick={this.handleSubmit} disabled={this.state.isSubmitting}/>
                                )}
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    taskColor: state.app.appState.selectedTaskToEditObj.task.color,
    loading: state.app.appState.editColorLoading
})

const mapDispatchToProps = dispatch => ({
    editTaskColor: newColor => dispatch(actions.editTaskColor(newColor))
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditTaskColor)


