import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik';

import * as actions from '../../actions'
import { toSeconds } from '../../../../../../helpers'
import Preloader from '../../../../../UI/Preloader'
import DisplayDate from '../../../../../UI/Datepicker/components/DisplayDate'
import ButtonEdit from '../../../../../UI/ButtonEdit'

import './style.scss'


class EditTotalTime extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            readonly: true
        }
    }

    handleClick = () => {
        this.setState(state => {
            return {readonly: false}
        })
    }

    render () {
        const { 
            selectedDateRange,  
            loading, 
            editTaskTime,
            totalTimeFromState } = this.props

		const selectionRange = {
			startDate: moment(selectedDateRange[0],'YYYY-MM-DD'),
			endDate: moment(selectedDateRange[1],'YYYY-MM-DD')
        }
        const totalTimeFromStateDaily = totalTimeFromState ? (totalTimeFromState[0]!=='--' ? totalTimeFromState : [0,0]) : [0,0]
        return (
            <div className="task_settings_item">
                <div className="task_settings_item_left">
                    <DisplayDate 
                        dateStart={selectionRange.startDate}
                        dateEnd={selectionRange.endDate}
                        removeIcon={true}
                    />
                </div>
                <div className="task_settings_item_right">
                    {loading && <Preloader mini />}
                    {!loading && <Formik
                        initialValues={{hours: totalTimeFromStateDaily[0], minutes: totalTimeFromStateDaily[1]}}
                        onSubmit={(values, { setSubmitting }) => {
                            this.setState(state => {
                                return {readonly: true}
                            })
                            // if ((+totalTimeFromStateDaily[0] !== +values.hours)||(+totalTimeFromStateDaily[1] !== +values.minutes)) {
                                setTimeout(() => {
                                }, 400)
                                editTaskTime(toSeconds(values))
                            // } 
                            setSubmitting(false)
                        }}
                        >
                        {({ isSubmitting, values, setFieldValue }) => (
                            <Form className='task_settings_item_form'>
                                <div className="task_settings_item_form_hours">
                                    <Field 
                                        type="text" 
                                        name="hours" 
                                        value={values.hours} 
                                        onChange={(e) => {
                                            setFieldValue('hours',e.target.value.replace(/[^0-9]+/,''))
                                        }}
                                        readOnly = {this.state.readonly} 
                                        />
                                    h
                                </div>
                                <div className="task_settings_item_form_minutes">
                                    <Field 
                                        type="text" 
                                        maxLength="2" 
                                        name="minutes" 
                                        value={values.minutes} 
                                        onChange={(e) => {
                                            setFieldValue('minutes',e.target.value.replace(/[^0-9]+/,''))
                                        }}
                                        readOnly = {this.state.readonly} 
                                        />
                                    m
                                </div>
                                {this.state.readonly ? <ButtonEdit onClick={this.handleClick} /> : <ButtonEdit active type="submit" disabled={isSubmitting}/>}
                            </Form>
                        )}
                    </Formik>}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    selectedDateRange: state.filtersPanel.selectedDateRange,
    taskId: state.app.appState.selectedTaskToEdit,
    weeks: state.app.database.weeks,
    loading: state.app.appState.taskLoading,
    totalTimeFromState: state.app.database.totalTime
})

const mapDispatchToProps = dispatch => ({
    editTaskTime: newTime => dispatch(actions.editTaskTime(newTime))
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditTotalTime)


