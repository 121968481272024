import * as types from '../types'

export const editTaskTime = (payload) => ({
  type: types.EDIT_TASK_TIME,
  payload,
})

export const editTaskTimeSuccess = () => ({
  type: types.EDIT_TASK_TIME_SUCCESS,
})

export const editTaskTimeError = (error) => ({
  type: types.EDIT_TASK_TIME_ERROR,
  error,
})

export const editTaskPriority = (payload) => ({
  type: types.EDIT_TASK_PRIORITY,
  payload,
})
export const editTaskPriorityError = (error) => ({
  type: types.EDIT_TASK_PRIORITY_ERROR,
  error,
})

export const editTaskPrioritySuccess = () => ({
  type: types.EDIT_TASK_PRIORITY_SUCCESS,
})

export const editTaskBillable = (payload) => ({
  type: types.EDIT_TASK_BILLABLE,
  payload,
})

export const editTaskBillableError = (payload) => ({
  type: types.EDIT_TASK_BILLABLE_ERROR,
  payload,
})

export const editTaskBillableSuccess = () => ({
  type: types.EDIT_TASK_BILLABLE_SUCCESS,
})

export const editTaskName = (payload) => ({
  type: types.EDIT_TASK_NAME,
  payload,
})
export const editTaskNameError = (error) => ({
  type: types.EDIT_TASK_NAME_ERROR,
  error,
})

export const editTaskNameSuccess = () => ({
  type: types.EDIT_TASK_NAME_SUCCESS,
})

export const editTaskIcon = (payload) => ({
  type: types.EDIT_TASK_ICON,
  payload,
})
export const editTaskIconSuccess = () => ({
  type: types.EDIT_TASK_ICON_SUCCESS,
})
export const editTaskIconError = (error) => ({
  type: types.EDIT_TASK_ICON_ERROR,
  error,
})

export const editTaskColor = (payload) => ({
  type: types.EDIT_TASK_COLOR,
  payload,
})
export const editTaskColorSuccess = () => ({
  type: types.EDIT_TASK_COLOR_SUCCESS,
})
export const editTaskColorError = (error) => ({
  type: types.EDIT_TASK_COLOR_ERROR,
  error,
})

export const editTaskCurrency = (payload) => ({
  type: types.EDIT_TASK_CURRENCY,
  payload,
})
export const editTaskCurrencySuccess = () => ({
  type: types.EDIT_TASK_CURRENCY_SUCCESS,
})
export const editTaskCurrencyError = (error) => ({
  type: types.EDIT_TASK_CURRENCY_ERROR,
  error,
})

export const editTaskHourlyRate = (payload) => ({
  type: types.EDIT_TASK_HOURLY_RATE,
  payload,
})
export const editTaskHourlyRateSuccess = () => ({
  type: types.EDIT_TASK_HOURLY_RATE_SUCCESS,
})
export const editTaskHourlyRateError = (error) => ({
  type: types.EDIT_TASK_HOURLY_RATE_ERROR,
  error,
})

export const editTaskPomodoroStatus = (payload) => ({
  type: types.EDIT_TASK_POMODORO_STATUS_REQUEST,
  payload,
})
export const editTaskPomodoroStatusSuccess = () => ({
  type: types.EDIT_TASK_POMODORO_STATUS_SUCCESS,
})
export const editTaskPomodoroStatusError = (error) => ({
  type: types.EDIT_TASK_POMODORO_STATUS_ERROR,
  error,
})

export const editTaskPomodoroTime = (payload) => ({
  type: types.EDIT_TASK_POMODORO_TIME_REQUEST,
  payload,
})
export const editTaskPomodoroTimeSuccess = () => ({
  type: types.EDIT_TASK_POMODORO_TIME_SUCCESS,
})
export const editTaskPomodoroTimeError = (error) => ({
  type: types.EDIT_TASK_POMODORO_TIME_ERROR,
  error,
})

export const editTaskTag = (payload) => ({
  type: types.EDIT_TASK_TAG_REQUEST,
  payload,
})
export const editTaskTagSuccess = () => ({
  type: types.EDIT_TASK_TAG_SUCCESS,
})
export const editTaskTagError = (error) => ({
  type: types.EDIT_TASK_TAG_ERROR,
  error,
})
