import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import Logo from '../../../assets/logo.svg'
import mailIcon from '../../../assets/mail_icon.svg'
import Preloader from '../../UI/Preloader'

import * as signUpActions from '../SignUp/actions'


class SignUpConfirmEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFromEmailLink: null,
      seconds: 10
    }
  }

  componentDidMount() {
    const appStep = window.location.pathname.replace(/\/confirm-email\//,'')
    if ((appStep === 'success')||(appStep === 'failure')) {
      this.setState({
        isFromEmailLink: true
      })
      setTimeout(()=>{
        this.props.history.push('/')
      },10000)
      this.interval = setInterval(() => this.tick(), 1000);
    } else if (!this.props.email) {
      this.props.history.push('/')
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval)
  }
  tick() {
    this.setState(state => ({
      seconds: state.seconds - 1
    }));
  }
  onClickHandler = () => {
    const { resendEmail, email } = this.props
    resendEmail(email)
  }

  onClickHandlerSignIn = () => {
    this.props.history.push('/')
  }

  render () {
    const { loading, isEmailSubmitted } = this.props
    const { textMessageTop, textMessageBottom } = !isEmailSubmitted ? 
      {
        textMessageTop : "The email is confirmed, now you can log in with your username and password",
        textMessageBottom: <span>you will be automatically redirected in {this.state.seconds} seconds.<br/>or click on the link below</span>
      } :
      {
        textMessageTop : "Thank you for signing up. Please confirm your email address",
        textMessageBottom: "Confirmation link sent to your mailbox"
      }
    return (
      <main className="layout">
        <div className="auth">
          <div className="auth_placeholder">
            <img src={Logo} alt="logo" className="logo auth_logo"/>
            <p className="auth_intro" style={{color:'#FFFFFF',marginBottom:'26px'}}>{textMessageTop}</p>
            <p style={{marginBottom: '26px', textAlign: 'center'}}>{textMessageBottom}</p>
            {!isEmailSubmitted && <span onClick={this.onClickHandlerSignIn} className='link'>Sign in</span>}
            {isEmailSubmitted && <img src={mailIcon} alt="mail-icon" style={{marginBottom: '24px'}}/>}
            {isEmailSubmitted && 
              (!loading ? 
                <span onClick={this.onClickHandler} className='link'>Resend email</span>
                :
                <Preloader />
              )
            }

          </div>
        </div>
      </main>
    )
  }
}

SignUpConfirmEmail.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node])
}

const mapStateToProps = state => ({
  email: state.signUp.email,
  loading: state.signUp.loading,
  isEmailSubmitted: state.signUp.email
})
const mapDispatchToProps = dispatch => ({
  resendEmail: email => dispatch(signUpActions.resendEmail(email))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUpConfirmEmail)