import * as types from '../types'

export const selectStartDate = dates => ({
  type: types.SELECT_DATE_START,
  dates
})

export const selectEndDate = dates => ({
  type: types.SELECT_DATE_END,
  dates
})

export const selectEndDateSettings = dates => ({
  type: types.SELECT_DATE_END_SETTINGS,
  dates
})

export const setDateRange = dates => ({
  type: types.SET_DATE_RANGE,
  dates
})

export const setFilterCurrencyes = (currency, state) => ({
  type: types.SET_FILTER_CURRENCYES,
  payload: {
    payload: currency,
    state: state
  }
})

export const setFilterPriority = (priority, state) => ({
  type: types.SET_FILTER_PRIORITY,
  payload: {
    payload: priority,
    state: state
  }
})

export const setFilterClear = () => ({
  type: types.SET_FILTERS_CLEAR
})

export const setListOfCurrencyes = currencyes => ({
  type: types.SET_LIST_OF_CURRENCYES,
  currencyes
})

export const switchTimeMoneyMode = () => ({
  type: types.SWITCH_TIME_MONEY_MODE
})

export const switchSettingsTimeMoneyMode = () => ({
  type: types.SWITCH_SETTINGS_TIME_MONEY_MODE
})

export const downloadXLSX = () => ({
  type: types.DOWNLOAD_XLSX
})
export const downloadCSV = () => ({
  type: types.DOWNLOAD_CSV
})

export const setFilterTask = taskId => ({
  type: types.SET_FILTER_TASK
})

export const setFilterTag = tagTitle => ({
  type: types.SET_FILTER_TAG,
  payload: tagTitle
})

export const setFilterTagRequest = () => ({
  type: types.SET_FILTER_TAG_REQUEST
})

export const setFilterTagSuccess = () => ({
  type: types.SET_FILTER_TAG_SUCCESS
})

export const setFilterTagError = () => ({
  type: types.SET_FILTER_TAG_ERROR
})
