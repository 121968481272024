import React from "react";
import Button from '../../../../UI/Button'

import PasswordInput from '../../../../UI/PasswordInput'
import Preloader from '../../../../UI/Preloader'


export const Form = (props) => {
  const {
    values: { password, passwordConfirm },
    errors,
    touched,
    handleChange,
    isValid,
    setFieldTouched,
    handleSubmit,
    loading,
    errorsApi
  } = props;
  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };
  return (
   <form 
    onSubmit={handleSubmit}
    >
     <PasswordInput 
      touched = {touched.password}
      errors = {errors.password}
      value = {password}
      inputName = "password"
      label = "New password"
      change = {change}
      style={{marginBottom:'14px'}}
     />
     <PasswordInput 
      touched = {touched.passwordConfirm}
      errors = {errors.passwordConfirm}
      value = {passwordConfirm}
      inputName = "passwordConfirm"
      label = "Confirm password"
      change = {change}
      style={{marginBottom:'44px'}}
     />
     {errorsApi && <p className = "auth_errors">{errorsApi}</p>}
     <Button
       key='passwordChange'
       className="button"
       type="submit"
       color="primary"
       style={{minWidth:'300px', marginBottom:'24px'}} 
       disabled={!isValid || loading}
     >
      {loading ? <Preloader mini/>:'Change Password'}
     </Button>
   </form>
 );
};

