import React from 'react'
import moment from 'moment'
import CalendarIcon from '../../../../Svg/CalendarIcon'

const DisplayDate = ({dateStart, dateEnd, removeIcon, onClick}) => {
    const displayDateStart = moment(dateStart,'X')
    const displayDateEnd = moment(dateEnd,'X')
    if (displayDateStart.format('YYYY') === displayDateEnd.format('YYYY')){
      if (displayDateStart.format('MMM') === displayDateEnd.format('MMM')) {
        if (displayDateStart.format('D') === displayDateEnd.format('D')) {
          return (
            <div className='datepicker_toggler' onClick={onClick}>
              <span>{displayDateStart.format('D')}&nbsp;</span>
              <span className='text_mutted'>{displayDateStart.format('MMM')}, {displayDateStart.format('YYYY')}</span>
              {removeIcon ? '':<CalendarIcon />}
            </div>)
          
        } else {
          return (
            <div className='datepicker_toggler' onClick={onClick}>
              <span>{displayDateStart.format('D')}-{displayDateEnd.format('D')}&nbsp;</span>
              <span className="text_mutted">{displayDateStart.format('MMM')}, {displayDateStart.format('YYYY')}</span>
              {removeIcon ? '': <CalendarIcon />}
            </div>)
        }
      } else {
        return (
          <div className='datepicker_toggler' onClick={onClick}>
            <span>{displayDateStart.format('D')}&nbsp;</span>
            <span className="text_mutted">{displayDateStart.format('MMM')}-</span>
            <span>{displayDateEnd.format('D')}&nbsp;</span>
            <span className="text_mutted">{displayDateEnd.format('MMM')}, {displayDateStart.format('YYYY')}</span>
            {removeIcon ? '': <CalendarIcon />}
          </div>)
        
      }
    } else {
      return (
        <div className='datepicker_toggler' onClick={onClick}>
          <span>{displayDateStart.format('D')}&nbsp;</span>
          <span className="text_mutted">{displayDateStart.format('MMM')}, {displayDateStart.format('YYYY')}-</span>
          <span>{displayDateEnd.format('D')}&nbsp;</span>
          <span className="text_mutted">{displayDateEnd.format('MMM')}, {displayDateEnd.format('YYYY')}</span>
          {removeIcon ? '': <CalendarIcon />}
        </div>)
    }
  }


export default DisplayDate