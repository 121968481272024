
import React from 'react'
import PropTypes from 'prop-types'
import {Tooltip} from 'react-tippy'

import Button from '../Button'
import ArrowSelect from '../../Svg/ArrowSelect'


class Popover extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
  }

  handleOpen = () => {
    this.setState({
      isOpen: true
    })
  }
  handleClose = () => {
    this.setState({
      isOpen: false
    })
  }

  render() {
    const { children, downloadXLSX, downloadCSV } = this.props
    return (
      <Tooltip
      position="bottom"
      trigger="click"
      animation="perspective"
      open={this.state.isOpen}
      onRequestClose={() => {this.handleClose()}}
      html={
        <div className='flex flex-v'>
          <Button 
            style={{width:'124px', height:'32px', fontSize: '14px',marginBottom:"9px"}}
            onClick={() => {
              downloadXLSX()
              this.handleClose()
            }}
            ><span>Download as&nbsp;</span>XLSX</Button>
          <Button 
            style={{width:'124px', height:'32px', fontSize: '14px'}}
            onClick={() => {
              downloadCSV()
              this.handleClose()
            }}
            ><span>Download as&nbsp;</span>CSV</Button>
        </div>
      }
      interactive
      arrow={true}
    >
      <Button  
        style={{width:'124px', height:'32px', fontSize: '14px'}}
        className='button icon-select'
        onClick={this.handleOpen}
        borderless
      >
        {children}<ArrowSelect style={{marginLeft: "5px"}}/>
      </Button>
    </Tooltip>
    )
  }
}

Popover.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func
}

Popover.defaultProps = { 
  type: 'button',
  onClick: () => {}
}

export default Popover