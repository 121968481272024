import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Scrollbars } from 'react-custom-scrollbars'

import WeekModule from '../WeekModule'
import DaysModule from '../DaysModule'
import Preloader from '../../../UI/Preloader'
import NoData from '../../../NoData'

import classNames from 'classnames/bind'

import * as appActions from '../../../../App/actions'
import * as filterActions from '../../../FiltersPanel/actions'


class ReportWeeksView extends React.Component {

    isActiveDay = (data) => {
        return data === this.props.pickedDay
    }

    componentDidMount = () => {
        //this.props.setTaskSettingsViewModeWeek()
    }
    
    componentWillUnmount = () => {
        //this.props.setTaskSettingsViewModeWeek()
    }

    filterTask = tasks => {
        return tasks.filter(task => +task.task.id === +this.props.taskId)
    }
    onWeekClickHandler = (weekNum) => {
        const { setTaskSettingsViewModeDay, getSettingsTaskDaily, setDateRange, taskId, selectedDateRange } = this.props

        const startDate = moment().day(0).week(weekNum)
        let startDateStr = startDate.format('YYYY-MM-DD')
        if (startDate.isBefore(selectedDateRange[0])) {
            startDateStr = selectedDateRange[0]
        }
        if (startDate.isAfter(selectedDateRange[1])) {
            startDateStr = selectedDateRange[1]
        }

        const endDate = moment().day(6).week(weekNum)
        let endDateStr = endDate.format('YYYY-MM-DD')
        if (endDate.isAfter(selectedDateRange[1])) {
            endDateStr = selectedDateRange[1]
        }

        setDateRange([startDateStr, endDateStr])

        setTaskSettingsViewModeDay()
        getSettingsTaskDaily(taskId)
    }

    onDayClickHandler = (day) => {
        const { setTaskSettingsViewModeDay, getSettingsTaskDaily, setDateRange, taskId } = this.props
        setDateRange([day.dateStr,day.dateStr])
        setTaskSettingsViewModeDay()
        getSettingsTaskDaily(taskId)
    }
    
    render () {
        const { 
            weeks, 
            taskSettingsView, 
            taskId, 
            taskObj,
            loading,
            mode,
            viewModeWeekOrDay,
            currencyes,
            tasksInfoAvg,
            selectedCurrency,
            selectedDateRange } = this.props
            const reportView = window.location.pathname === '/report'
            const scrollbarsStyles = {
                renderTrackVertical:props => <div 
                    {...props} 
                    style={{
                        ...props.style, 
                        backgroundColor: 'rgba(255,255,255,0.1)', 
                        right: '2px',
                        height: '100%',
                        borderRadius: '3px'
                    }}/>,
                renderThumbVertical:props => <div {...props} style={{...props.style, backgroundColor: 'rgba(255,255,255,0.2)', borderRadius: '3px'}}/>
                }
            if (typeof weeks[0] === "undefined") {
              weeks[0] = {
                days: []
              }
            }
            let scrollbarsStylesResult
            let daysLength = weeks[0].days.length
            if (viewModeWeekOrDay === 'week' && weeks.length > 6) {
                scrollbarsStylesResult = scrollbarsStyles
            } else if (viewModeWeekOrDay !== 'week' && weeks[0].days.length > 6) {
                daysLength = weeks[0].days.filter(day => day.inPeriod).length
                scrollbarsStylesResult = scrollbarsStyles
            }
            let content = viewModeWeekOrDay === 'week' ? weeks.map((week,index) => {
                const start = moment().day(0).week(week.onum);
                const end = moment().day(6).week(week.onum);
                if (start.isBefore(selectedDateRange[1]) && end.isAfter(selectedDateRange[0])) {
                    return (
                        <WeekModule 
                            key={index}
                            date={week.onum}
                            disabled={!week.tasksInfo.length}
                            time={true}
                            money={false}
                            report={reportView}
                            taskSettingsView = {taskSettingsView}
                            taskId={taskId}
                            taskObj = {taskObj}
                            week={week}
                            mode={mode}
                            currencyes={currencyes}
                            selectedcurrency={selectedCurrency}
                            width = {mode === 'time'? week.totalTimeRatio : (week.totalPaymentByCurrenciesRatio ? (week.totalPaymentByCurrenciesRatio.length ? week.totalPaymentByCurrenciesRatio[0].value : 0 ) : 0)}
                            onClick={this.onWeekClickHandler.bind(this, week.onum)}
                        />
                    );
                }
                return null;
            }) : weeks[0].days.map(day => day && day.inPeriod &&
            <DaysModule 
                key={day.dateStr}
                date={day.dateStr}
                disabled={!day.tasksInfo.length}
                taskObj = {taskObj}
                day={day}
                mode={mode}
                currencyes={currencyes}
                selectedcurrency={selectedCurrency}
                width = {mode === 'time'? day.totalTimeRatio : day.totalPaymentByCurrenciesRatio[0].value}
                onClick={daysLength > 1 ? this.onDayClickHandler.bind(this, day) : null}
            />
            )
        return (
            !tasksInfoAvg.length ? <NoData />: <div className='days_view_placeholder'>
            {loading && <Preloader />}
            {!loading && <div className={classNames('days_view_weeks_placeholder report')}>
                <Scrollbars 
                style={{height: '540px', width: 'calc(100% + 10px)'}}
                {...scrollbarsStylesResult}
                >
                    <div className='days_view_weeks_column' style={{width: 'calc(100% - 10px)'}}>
                        {content}
                    </div>
                </Scrollbars>
            </div>}
        </div>
        
        )
    }
}
const mapStateToProps = state => ({
    loading: state.app.appState.loading,
    weeks: state.app.database.weeks,
    taskSettingsView: state.app.appState.taskSettingsView,
    taskId: state.app.appState.selectedTaskToEdit,
    taskObj: state.app.appState.selectedTaskToEditObj,
    tasksInfoAvg: state.app.database.tasksInfoAvg,
    mode: state.filtersPanel.tasksDisplayMode,
    viewModeWeekOrDay: state.app.appState.taskSettingsViewMode,
    currencyes: state.app.database.currencies,
    selectedCurrency: state.filtersPanel.filters.currencyes,
    taskSettingsWeeksBarWidthsMoney: state.app.database.taskSettingsWeeksBarWidthsMoney,
    taskSettingsWeeksBarWidthsTime: state.app.database.taskSettingsWeeksBarWidthsTime,
    selectedDateRange: state.filtersPanel.selectedDateRange
  })

const mapDispatchToProps = dispatch => ({
    setTaskSettingsViewModeWeek: () => dispatch(appActions.setTaskSettingsViewModeWeek()),
    setTaskSettingsViewModeDay: () => dispatch(appActions.setTaskSettingsViewModeDay()),
    getSettingsTaskDaily: id => dispatch(appActions.getSettingsTaskDaily(id)),
    setDateRange: dateRange => dispatch(filterActions.setDateRange(dateRange)),
    switchTimeMoneyMode: () => dispatch(filterActions.switchTimeMoneyMode())
})

export default connect(
        mapStateToProps,
        mapDispatchToProps
    )(ReportWeeksView)
