import React from 'react'
import PropTypes from 'prop-types'

import EditIcon from '../../Svg/EditIcon'
import CheckIcon from '../../Svg/CheckIcon'
import classNames from 'classnames/bind'


const ButtonEdit = ({ active, classActive, prev, next, onClick, type, ...props }) => {
  let content
  if (active) {
    content = <button
      type={type}
      {...props}
      className={classNames('button_edit', { active })}
      onClick={onClick}>
      <CheckIcon />
    </button>
  } else {
    content = <div
      type={type}
      {...props}
      className={classNames('button_edit', { active }, { 'active': classActive })}
      onClick={onClick}>
      <EditIcon />
    </div>
  }
  return content
}

ButtonEdit.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool
}

ButtonEdit.defaultProps = { 
  type: 'button',
  onClick: () => {}
}

export default ButtonEdit