import * as types from '../types'


const initialState = {
  loading: null,
  error: null,
  appState:{
    selectedDateRange: [],
  },
}

const setDateStart = (state, action) => {
  return ({
    ...state,
    appState:
      {
        ...state.appState,
        selectedDateRange: action.dates
      }
  })
}

const setDateEnd = (state, action) => {
    return ({
      ...state,
      appState:
        {
          ...state.appState,
          selectedDateRange: action.dates
        }
    })
  }

const MainWorkspace = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SELECT_DATE_START:
      return setDateStart(state, action)

    case types.SELECT_DATE_END:
      return setDateEnd(state, action)

    default:
      return state
  }
}


export default MainWorkspace
