import React from 'react'

import { TASK_COLORS, ICONS } from '../../../../../../../../constants'

class ColorPicker extends React.Component {

    handleOnClick = () => {

    }

    render () {
        const { onClick, taskCurrentColor } = this.props
        return (
            <ul className="colorpicker">
                {TASK_COLORS.map(color => 
                    <li 
                        key={color}
                        onClick={onClick.bind(this,color)}
                        className = {taskCurrentColor === color ? 'active' : ''}
                        style={{backgroundColor: color}}
                    >
                    </li>
                )}
                <li 
                    key="removeColor"
                    onClick={onClick.bind(this,"")}
                    className = "remove_color"
                >
                    {ICONS.NONE}
                </li>
            </ul>
        )
    }
}

export default ColorPicker