import React, { Component } from "react";
import { connect } from 'react-redux'
import { Formik } from "formik";
import { Form } from './SignInForm';
import * as Yup from "yup"

import * as actions from '../actions'


const validationSchema = Yup.object({
  email: Yup.string("Enter your email")
  .email("Enter a valid email")
  .required("Email is required"),
  password: Yup.string("")
  .min(8, "Password must contain at least 8 characters")
  .required("Enter your password"),
})

class SignInForm extends Component {
 constructor(props) {
   super(props);
   this.state = {};
 }

 render() {
    const values = { email: "", password: "" }
    const { signIn, loading, errorsApi } = this.props
    return (
     <React.Fragment>
      <div
        style={{
          maxWidth: "300px"
        }}
      >
        <Formik
          render={props => <Form { ...props } loading={loading} errorsApi={errorsApi}/>}
          initialValues={values}
          validationSchema={validationSchema}      
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              signIn(values)
              setSubmitting(false)
            }, 400);
          }}
        />
      </div>
     </React.Fragment>
   );
 }
}


const mapStateToProps = state => ({
  loading: state.signIn.loading,
  errorsApi: state.signIn.error
})

const mapDispatchToProps = dispatch => ({
  signIn: values => dispatch(actions.signInFetch(values))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps)(SignInForm)