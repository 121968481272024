import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import * as Yup from 'yup'

import * as actions from '../../actions'
import Form from './Form'

const validationSchema = userInfo =>
  Yup.object({
    ...(userInfo && !userInfo.user
      ? {
          firstName: Yup.string('').required('First name is required'),
          lastName: Yup.string('').required('Last name is required')
        }
      : {}),
    ...(userInfo && !userInfo.email
      ? {
          email: Yup.string('Enter your email')
            .email('Enter a valid email')
            .required('Email is required')
        }
      : {})
  })

class SignUpForm extends Component {
  render() {
    const { completeUserInfo, loading, errorsApi, userInfo } = this.props

    const values = {}
    if (userInfo && !userInfo.email) {
      values.email = ''
    }
    if (userInfo && !userInfo.user) {
      values.firstName = ''
      values.lastName = ''
    }
    return (
      <React.Fragment>
        <div
          style={{
            maxWidth: '300px'
          }}>
          <Formik
            render={props => (
              <Form {...props} loading={loading} errorsApi={errorsApi} />
            )}
            initialValues={values}
            validationSchema={validationSchema(userInfo)}
            onSubmit={values => completeUserInfo(values)}
          />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.completeUserInfo.loading,
  errorsApi: state.completeUserInfo.error
})

const mapDispatchToProps = dispatch => ({
  completeUserInfo: values => dispatch(actions.completeUserInfoRequest(values))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUpForm)
