import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Tooltip } from 'react-tippy'

import classNames from 'classnames/bind'

import { getCurrencyInfoFromId } from '../../../../helpers'
import TaskDetails from '../../../UI/TaskDetails'

const DayModule = ({ 
  children, 
  date,
  disabled,
  day,
  mode,
  currencyes,
  onClick,
  taskOnClick,
  ...props
}) => {
  return (
  <div
    {...props}
    className={classNames('week_module report', {'task_settings': onClick !== null}, { 'dummy': day.totalTime[0] === '--' }, {disabled})}
    onClick={onClick}>
    <div className = 'week_module_date_placeholder'>
      <span className="week_module_date">{moment(date, 'YYYY-MM-DD').format('DD MMM')}</span>
      <div className="flex flex-v">
        {mode === 'time' && (day.totalTime[0] === '--' ? <span className="week_module_content_label">—</span> : 
          <div className='week_module_content'>
            <span>
              {day.totalTime[0]}<span className="week_module_content_label">h</span>
            </span>
            <span>
              {day.totalTime[1]}<span className="week_module_content_label">m</span>
            </span>
          </div>
        )}
      </div>
      
      {mode === 'money' && (
        day.totalPaymentByCurrencies.length ? 
        day.totalPaymentByCurrencies.map(currency => {
          return <div key={currency.currencyId} className='week_module_content'>
            { 
              <span>
                <span className="week_module_content_label">{getCurrencyInfoFromId(currency.currencyId).symbol}</span>
                <span>{currency.value}</span>
              </span>
            }
          </div>
          }) : <span className="week_module_content_label">—</span>
      )}
    </div>
    {day.tasksInfo.length ? (
      <div 
        className='week_module_data_placeholder'
        style={{flexBasis: 'calc((100% - 119px)*'+day.totalTimeRatio+')'}}
      >
        {day.tasksInfo.map((task,index) => { 
          const html = <TaskDetails 
            taskIcon = {task.task.icon}
            taskTime = {task.totalTime}
            taskTitle = {task.task.name}
            billable = {task.task.billable}
            priority = {task.task.highPriority}
            taskRelativeValue = {mode === 'time' ? task.totalTimeRatio:task.totalPaymentRatio}
            taskColor = {task.task.color}
            style = {{margin: '-8px'}}
            taskObj = {task}
            mode = {mode}
            currencyes = {currencyes}
          />
          return <Tooltip
                title="datepicker"
                position="top"
                html={html}
                arrow={true}
                followCursor={true}
                key={index}
                className='week_module_task_display_block'
                style={{
                  flexBasis:mode === 'time' ? 
                    task.totalTimeRatio*100+'%':
                    ((task.totalPaymentRatio*100<1 && task.totalPaymentRatio*100>0) ?
                      '1%':
                      task.totalPaymentRatio*100+'%'),
                  background: task.task.color
                  }}
              >
              <div
                style={{ height: '100%', cursor: 'pointer' }}
                onClick={taskOnClick.bind(this, { taskId: task.task.id, day: day})}
              >
              </div>
          </Tooltip>
          }
          ) 
        }
      </div>
    ) : (
      <div 
        className='week_module_data_placeholder'
        style={{flexBasis: 'calc((100% - 119px) * 1)'}}
      ></div>
    )}
  </div>
)
}

DayModule.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  date: PropTypes.string,
  onClick: PropTypes.func,
  taskOnClick: PropTypes.func
}

DayModule.defaultProps = { 
  onClick: () => {},
  taskOnClick: () => {}
}

export default DayModule
