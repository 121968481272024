import React from 'react'
import { connect } from 'react-redux'
import { Tooltip } from 'react-tippy'
import Preloader from '../Preloader'

import DisplayDate from './components/DisplayDate'

class Popover extends React.Component {
  componentDidMount() {}
  componentDidUpdate() {}

  render() {
    const { html, dateStart, dateEnd, loading, ...props } = this.props

    return (
      <Tooltip
        {...props}
        title="datepicker"
        position="bottom"
        trigger="click"
        animation="perspective"
        html={html}
        interactive
        arrow={true}
        className="datepicker_toggler_placeholder"
        style={{ marginRight: '10px' }}>
        {loading ? (
          <DisplayDate
            dateStart={dateStart}
            dateEnd={dateEnd}
            removeIcon={false}
            onClick={this.props.onOpen}
          />
        ) : (
          <Preloader mini />
        )}
      </Tooltip>
    )
  }
}
const mapStateToProps = state => ({})

const mapDispatchToProps = distpatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Popover)
