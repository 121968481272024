import { takeEvery, put, call, all } from 'redux-saga/effects'

import * as types from '../types'
import * as actions from '../actions'
import Api from '../../../../services/Api'
import { API_ENDPOINTS } from '../../../../constants/index'

function* passwordResetAsync(email) {
  try {
    const data = {
      email: email.email
    }
    yield call(Api.post, API_ENDPOINTS.passwordResetRequest, '', data)
    yield put(actions.passwordResetSuccess())
  } catch (error) {
    yield put(actions.passwordResetFailure(error))
  }
}

export default function* passwordResetSaga() {
  yield all([takeEvery(types.PASSWORD_RESET, passwordResetAsync)])
}
