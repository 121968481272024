import * as types from '../types'
import * as completeUserInformationTypes from '../../components/auth/CompleteUserInfo/types'
import moment from 'moment'

const initialState = {
  loading: true,
  token: null,
  error: null,
  user: null,
  appState: {
    error: null,
    loading: false,
    taskLoading: false,
    editPriorityLoading: false,
    editNameLoading: false,
    editIconLoading: false,
    editColorLoading: false,
    editCurrencyLoading: false,
    editHourlyRateLoading: false,
    editPomodoroTimeLoading: false,
    editTagLoading: false,
    selectedDateRange: [
      new moment().startOf('month').format('YYYY-MM-DD'),
      new moment().endOf('month')._d > new moment()._d
        ? new moment().format('YYYY-MM-DD')
        : new moment().endOf('month').format('YYYY-MM-DD')
    ],
    pickedDay: moment().format('YYYY-MM-DD'),
    pickedDayObj: {
      totalTime: null,
      dateStr: '2019-07-23',
      inPeriod: null,
      tasksInfo: [],
      totalPayment: null,
      totalPaymentRatio: null,
      totalTimeRatio: null
    },
    timeSheetView: true,
    reportView: false,

    taskSettingsView: false,
    taskSettingsViewMode: 'week', // week||day
    selectedTaskToEdit: '',
    selectedTaskToEditObj: {
      task: {
        billable: null,
        name: null,
        tag: null
      }
    }
  },
  database: {
    currencies: [],
    tasksInfoAvg: [],
    weeks: [],
    tasksFilter: [],
    totalTime: null,
    totalPayment: []
  }
}

const setLoading = state => ({
  ...state,
  loading: !state.loading,
  appState: {
    ...state.appState,
    loading: !state.appState.loading
  }
})

const setError = (state, action) => ({
  ...state,
  loading: false,
  error: action.payload
})

const setAppStateError = (state, action) => ({
  ...state,
  appState: {
    ...state.appState,
    loading: false,
    error: action.payload
  }
})

const setTasksLoading = state => {
  return {
    ...state,
    appState: {
      ...state.appState,
      loading: true,
      taskLoading: true
    }
  }
}

const setTasksLoadingStop = state => {
  return {
    ...state,
    appState: {
      ...state.appState,
      taskLoading: false,
      loading: false
    }
  }
}

const setDateRange = (state, action) => {
  return {
    ...state,
    appState: {
      ...state.appState,
      selectedDateRange: action.dates
    }
  }
}

const resetDateRange = (state, action) => {
  return {
    ...state,
    appState: {
      ...state.appState,
      selectedDateRange: [
        new moment().startOf('month').format('YYYY-MM-DD'),
        new moment().endOf('month')._d > new moment()._d
          ? new moment().format('YYYY-MM-DD')
          : new moment().endOf('month').format('YYYY-MM-DD')
      ],
      pickedDay: moment().format('YYYY-MM-DD')
    }
  }
}

const setCurrentUser = (state, action) => ({
  ...state,
  user: action.user
})

const selectDaysToView = (state, action) => {
  return {
    ...state,
    appState: {
      ...state.appState,
      pickedDay: action.payload.date,
      pickedDayObj: action.payload.dateObj
    }
  }
}

const toggleTimeOrReportMode = state => {
  return {
    ...state,
    appState: {
      ...state.appState,
      timeSheetView: !state.appState.timeSheetView,
      reportView: !state.appState.reportView,
      taskSettingsView: false
    }
  }
}

const selectTask = (state, action) => {
  return {
    ...state,
    appState: {
      ...state.appState,
      taskSettingsView: true,
      selectedTaskToEdit: action.payload.taskId,
      selectedTaskToEditObj: action.payload.taskObj
    }
  }
}

const setReportTasksLoading = state => {
  return {
    ...state,
    appState: {
      ...state.appState,
      loading: !state.appState.loading
    }
  }
}

const getReportTasks = (state, action) => {
  return {
    ...state,
    appState: {
      ...state.appState
    },
    database: {
      ...state.database,
      ...action.data
    }
  }
}

const getReportTasksSuccess = state => {
  const selectedDateRange = [
    state.database.beginDateStr,
    state.database.endDateStr
  ]
  return {
    ...state,
    appState: {
      ...state.appState,
      selectedDateRange: selectedDateRange,
      loading: false,
      taskLoading: false
    }
  }
}

const getReportTasksSettingsSuccess = state => {
  const selectedDateRange = [
    state.database.beginDateStr,
    state.database.endDateStr
  ]
  return {
    ...state,
    appState: {
      ...state.appState,
      selectedDateRange: selectedDateRange,
      loading: false,
      taskLoading: false
    }
  }
}

const logout = state => ({
  ...state,
  timeTrackInfo: null,
  token: null,
  user: null
})

const deleteAccountFailure = (state, action) => ({
  ...state,
  error: action.payload,
})

const setDateStart = (state, action) => {
  return {
    ...state,
    appState: {
      ...state.appState,
      selectedDateRange: action.dates
    }
  }
}

const setDateEnd = (state, action) => {
  return {
    ...state,
    appState: {
      ...state.appState
      //selectedDateRange: action.dates
    }
  }
}

const setCurrencyes = (state, action) => {
  return {
    ...state,
    database: {
      ...state.database,
      currencyes: action.currencyes.data
    }
  }
}

const getTaskByIdSuccess = (state, action) => {
  return {
    ...state,
    appState: {
      ...state.appState,
      editPriorityLoading: false,
      editNameLoading: false,
      editIconLoading: false,
      editColorLoading: false,
      editCurrencyLoading: false,
      editHourlyRateLoading: false,
      editPomodoroTimeLoading: false,
      editTagLoading: false,
      selectedTaskToEdit: action.task.data.id,
      selectedTaskToEditObj: {
        ...state.appState.selectedTaskToEditObj,
        task: action.task.data
      }
    }
  }
}

const getAllTasksSuccess = (state, action) => {
  const tasksFilter = action.data.reduce(
    (acc, cur) => ({ ...acc, [cur.id]: true }),
    {}
  )
  return {
    ...state,
    appState: {
      ...state.appState,
      tasksFilter: tasksFilter
    },
    database: {
      ...state.database,
      allTasks: action.data
    }
  }
}

const setEditTaskPriorityLoading = state => {
  return {
    ...state,
    appState: {
      ...state.appState,
      editPriorityLoading: true
    }
  }
}

const setEditTaskNameLoading = state => {
  return {
    ...state,
    appState: {
      ...state.appState,
      editNameLoading: true
    }
  }
}
const setEditTaskIconLoading = state => {
  return {
    ...state,
    appState: {
      ...state.appState,
      editIconLoading: true
    }
  }
}
const setEditTaskColorLoading = state => {
  return {
    ...state,
    appState: {
      ...state.appState,
      editColorLoading: true
    }
  }
}
const setEditTaskCurrencyLoading = state => {
  return {
    ...state,
    appState: {
      ...state.appState,
      loading: true,
      editCurrencyLoading: true
    }
  }
}
const setEditTaskHourlyRateLoading = state => {
  return {
    ...state,
    appState: {
      ...state.appState,
      editHourlyRateLoading: true
    }
  }
}

const setEditTaskPomodoroTimeLoading = state => {
  return {
    ...state,
    appState: {
      ...state.appState,
      editPomodoroTimeLoading: true
    }
  }
}

const setEditTaskTagLoading = state => {
  return {
    ...state,
    appState: {
      ...state.appState,
      editTagLoading: true
    }
  }
}

const setTaskSettingsViewModeWeek = state => {
  return {
    ...state,
    appState: {
      ...state.appState,
      taskSettingsViewMode: 'week'
    }
  }
}
const setTaskSettingsViewModeDay = state => {
  return {
    ...state,
    appState: {
      ...state.appState,
      taskSettingsViewMode: 'day'
    }
  }
}

const setSettingsAppLoading = state => {
  return {
    ...state,
    appState: {
      ...state.appState,
      loading: true
    }
  }
}

const SignInReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.AUTO_SIGN_IN_FAILURE:
      return setError(state, action)

    case types.AUTO_SIGN_IN_SUCCESS:
      return setLoading(state)

    case types.SET_TASKS_LOADING:
      return setTasksLoading(state)

    case types.SET_TASKS_LOADING_STOP:
      return setTasksLoadingStop(state)

    case types.SET_DATE_RANGE:
      return setDateRange(state, action)

    case types.SELECT_DAYS_TO_VIEW:
      return selectDaysToView(state, action)

    case types.TOGGLE_TIME_OR_REPORT_MODE:
      return toggleTimeOrReportMode(state)

    case types.GET_REPORT_TASKS_INFO_REQUEST:
      return setReportTasksLoading(state)

    case types.SET_REPORT_TASKS_EMPTY:
      return {
        ...state,
        database: {
          ...state.database,
          weeks: [],
          tasksInfoAvg: [],
          totalTime: null,
          totalPayment: []
        }
      }

    case types.GET_REPORT_TASKS_INFO:
      return getReportTasks(state, action)

    case types.GET_REPORT_TASKS_INFO_FAILURE:
      return setAppStateError(state, action)

    case types.GET_REPORT_TASKS_INFO_SUCCESS:
      return getReportTasksSuccess(state)

    case types.GET_REPORT_TASKS_SETTINGS_SUCCESS:
      return getReportTasksSettingsSuccess(state)

    case types.SELECT_TASK:
      return selectTask(state, action)

    case types.GET_TASK_BY_ID_SUCCESS:
      return getTaskByIdSuccess(state, action)

    case types.GET_ALL_TASKS_SUCCESS:
      return getAllTasksSuccess(state, action)

    case types.SELECT_DATE_START:
      return setDateStart(state, action)

    case types.SELECT_DATE_END:
      return setDateEnd(state, action)

    case types.SELECT_DATE_END_SETTINGS:
      return setSettingsAppLoading(state)

    case types.SET_SIDEBAR_LOADING:
      return setSettingsAppLoading(state)

    case types.SET_CURRENCYES:
      return setCurrencyes(state, action)

    case types.SET_LOADING:
      return setLoading(state, action)
    case types.SET_ERROR:
      return setError(state, action)

    case types.SET_EDIT_TASK_PRIORITY_LOADING:
      return setEditTaskPriorityLoading(state)

    case types.SET_EDIT_TASK_NAME_LOADING:
      return setEditTaskNameLoading(state)

    case types.SET_EDIT_TASK_ICON_LOADING:
      return setEditTaskIconLoading(state)

    case types.SET_EDIT_TASK_COLOR_LOADING:
      return setEditTaskColorLoading(state)

    case types.SET_EDIT_TASK_CURRENCY_LOADING:
      return setEditTaskCurrencyLoading(state)

    case types.SET_EDIT_TASK_HOURLY_RATE_LOADING:
      return setEditTaskHourlyRateLoading(state)

    case types.SET_EDIT_TASK_POMODORO_TIME_LOADING:
      return setEditTaskPomodoroTimeLoading(state)

    case types.SET_EDIT_TASK_TAG_LOADING:
      return setEditTaskTagLoading(state)

    case types.LOGOUT:
      return logout(state)

    case types.DELETE_ACCOUNT_FAILURE:
      return deleteAccountFailure(state, action)

    case types.SET_TASK_SETTINGS_VIEW_MODE_WEEK:
      return setTaskSettingsViewModeWeek(state)

    case types.SET_TASK_SETTINGS_VIEW_MODE_DAY:
      return setTaskSettingsViewModeDay(state)

    case types.RESET_DATE_RANGE:
      return resetDateRange(state)

    case types.SET_CURRENT_USER:
      return setCurrentUser(state, action)

    case completeUserInformationTypes.COMPLETE_USER_INFORMATION_SUCCESS:
      return setCurrentUser(state, action)

    default:
      return state
  }
}

export default SignInReducer
