export const SELECT_DATE_START = 'SELECT_DATE_START'
export const SELECT_DATE_END = 'SELECT_DATE_END'
export const SET_DATE_RANGE = 'SET_DATE_RANGE'

export const SELECT_DATE_END_SETTINGS = 'SELECT_DATE_END_SETTINGS'

export const SET_FILTER_CURRENCYES = 'SET_FILTER_CURRENCYES'

export const SET_FILTER_PRIORITY = 'SET_FILTER_PRIORITY'

export const SET_FILTERS_CLEAR = 'SET_FILTERS_CLEAR'

export const DOWNLOAD_XLSX = 'DOWNLOAD_XLSX'
export const DOWNLOAD_CSV = 'DOWNLOAD_CSV'

export const SET_LIST_OF_CURRENCYES = 'SET_LIST_OF_CURRENCYES'

export const SWITCH_TIME_MONEY_MODE = 'SWITCH_TIME_MONEY_MODE'

export const SWITCH_SETTINGS_TIME_MONEY_MODE = 'SWITCH_SETTINGS_TIME_MONEY_MODE'

export const SET_FILTER_TASK = 'SET_FILTER_TASK'

export const SET_FILTER_TAG = 'SET_FILTER_TAG'
export const SET_FILTER_TAG_REQUEST = 'SET_FILTER_TAG_REQUEST'
export const SET_FILTER_TAG_SUCCESS = 'SET_FILTER_TAG_SUCCESS'
export const SET_FILTER_TAG_ERROR = 'SET_FILTER_TAG_ERROR'
