import React from 'react'
import { connect } from 'react-redux'
import {Tooltip} from 'react-tippy'

import clockIcon from '../../../../assets/clock.svg'
import moneyIcon from '../../../../assets/money_icon.svg'
import { getCurrencyInfoFromId } from '../../../../helpers'
import classNames from 'classnames/bind'


class TimeTotalDisplay extends React.Component {


  render () {
    const { 
      totalTime,
      tasksDisplayMode,
      totalPayment } = this.props
    const totalTimeLoaded = totalTime ? totalTime : [0,0]
    let content
    if (tasksDisplayMode === 'time') {
      content = <div className={classNames('time_total_display')}>
        <img src={clockIcon} alt="hours" style={{width:'20px', height:'20px',marginRight: '6px'}}/>
        <p className='time_total_display_text'>
          {totalTimeLoaded[0]}<span>h </span>
          {totalTimeLoaded[1]}<span>m</span>
        </p>
      </div> 
    } else {
      content = <div className={classNames('time_total_display')}>
        <img src={moneyIcon} alt="hours" style={{width:'20px', height:'20px',marginRight: '6px'}}/>
        {totalPayment.length > 1 && 
          <Tooltip
            position="bottom"
            theme='total_money'
            animation={'shift'}
            arrow={true}
            html={
              totalPayment.length > 1 ? 
              totalPayment.map(currency => 
                  <p 
                    key={currency.currencyId} 
                    className="time_total_display_text">
                    <span>{getCurrencyInfoFromId(currency.currencyId).symbol}</span>{currency.value}</p>) :
                  false
            }
          >
          <p 
            className="time_total_display_text"
            style={{'userSelect':'none', cursor: 'pointer'}}
          >
            <span>{getCurrencyInfoFromId(totalPayment[0].currencyId).symbol}</span>
            {totalPayment[0].value}
          </p>
          </Tooltip>
        }
        {totalPayment.length === 1 &&           
          <p 
            className="time_total_display_text"
            style={{'userSelect':'none'}}
          >
            <span>{getCurrencyInfoFromId(totalPayment[0].currencyId).symbol}</span>
            {totalPayment[0].value}
          </p>
        }
      </div>
    }      
    return (
      content
    )
  }
}

const mapStateToProps = (state) => {

  return {
    tasksDisplayMode: state.filtersPanel.tasksDisplayMode,
    totalTime: state.app.database.totalTime,
    totalPayment: state.app.database.totalPayment,
    weeks: state.app.database.weeks,
    currencyes: state.app.database.currencies,
    selectedCurrency: state.filtersPanel.filters.currencyes
  }
}


export default connect(
    mapStateToProps
  )(TimeTotalDisplay)
