import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames/bind'

const Button = ({
  children,
  active,
  borderless,
  onClick,
  type,
  facebook,
  google,
  apple,
  ...props
}) => (
  <button
    type={type}
    {...props}
    className={classNames('button', {
      active,
      borderless,
      facebook,
      google,
      apple
    })}
    onClick={onClick}>
    {children}
  </button>
)

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool
}

Button.defaultProps = {
  type: 'button',
  onClick: () => {}
}

export default Button
