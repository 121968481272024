import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames/bind'


const WeekModule = ({ 
  children, 
  date, 
  time, 
  money, 
  onClick, 
  disabled, 
  report, 
  taskObj, 
  taskSettingsView, 
  taskId, 
  week,
  mode,
  currencyes,
  selectedcurrency,
  width,
  ...props }) => {

  let taskTotalPaymentFromWeek = []
  let symbol
  if (currencyes) {
    taskTotalPaymentFromWeek = week.totalPaymentByCurrencies.length ? week.totalPaymentByCurrencies[0].value : 0
    symbol = currencyes.filter(currency => currency.id === taskObj.task.currencyId)[0]
  }

  return (
  <div
    {...props}
    className={classNames('week_module report task_settings', { 'dummy': week.totalTime[0]==='--' }, {disabled})}
    onClick={onClick}>
    <div className = 'week_module_date_placeholder'>
      <span className="week_module_date">Week {date}</span>
      <div className="flex flex-v">
        {mode === 'time' && (week.totalTime[0] === '--' ? <span className="week_module_content_label">—</span> : 
        <div className='week_module_content'>
        <span>
          {week.totalTime[0]}<span className="week_module_content_label">h </span>
        </span>
        <span>
          {week.totalTime[1]}<span className="week_module_content_label">m</span>
        </span>
        </div>
        )}
      </div>
      
      {mode === 'money' && 
         <div className='week_module_content'>
          {taskTotalPaymentFromWeek === 0 ? 
            <span className="week_module_content_label">—</span>
          : 
            <span>
              <span className="week_module_content_label">{symbol.symbol}</span>
              <span>{taskTotalPaymentFromWeek}</span>
            </span>
          }

        </div>
        }
    </div>
    {
      taskObj &&
      <div 
        className='week_module_data_placeholder'
        style={{flexBasis: 'calc((100% - 119px)*1)'}}
      >
        <div
          className='week_module_task_display_block'
          style={{
            flexBasis: width*100+'%',
            background: taskObj.task.color
            }}
          >
        </div>
      </div>
    }
  </div>
)
}

WeekModule.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  date: PropTypes.number,
  time: PropTypes.bool,
  money: PropTypes.bool,
  content: PropTypes.array
}

WeekModule.defaultProps = { 
  onClick: () => {}
}

export default WeekModule