import { fork, all } from 'redux-saga/effects'

import appSaga from '../App/sagas'
import reportSaga from '../components/Report/sagas'
import signInSaga from '../components/auth/SignIn/sagas'
import signUpSaga from '../components/auth/SignUp/sagas'
import passwordResetSaga from '../components/auth/PasswordReset/sagas'
import passwordChangeSaga from '../components/auth/PaswordChange/sagas'
import completeUserInfoSaga from '../components/auth/CompleteUserInfo/sagas'
import editTaskSaga from '../components/TaskSettings/components/SettingsTaskPanel/sagas'
import filtersPanelSaga from '../components/FiltersPanel/sagas'
import uploadSaga from '../components/Update/sagas'

export default function*() {
  yield all([
    fork(appSaga),
    fork(reportSaga),
    fork(signInSaga),
    fork(signUpSaga),
    fork(passwordResetSaga),
    fork(passwordChangeSaga),
    fork(completeUserInfoSaga),
    fork(editTaskSaga),
    fork(filtersPanelSaga),
    fork(uploadSaga)
  ])
}
