import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import UpdateForm from "./components/UpdateForm"
import UpdateSuccess from "./components/UpdateSuccess"
import Preloader from "../UI/Preloader"
import Button from "../UI/Button"
import Logo from "../../assets/logo.svg"

import * as actions from "./actions"
import { logout } from "../../App/actions"

class Update extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modalIsOpen: false,
      loginGoogleLoading: null,
      loginFacebookLoading: null,
    }
  }
  componentDidMount = () => {
    const { getCurrentVersion } = this.props
    getCurrentVersion()
  }

  componentWillUnmount() {
    localStorage.clear()
  }

  onOkClickHandler = () => {
    const { history } = this.props
    localStorage.clear()
    history.go(history.location.pathname)
  }

  render() {
    const {
      loading,
      currentVersion,
      uploadFile,
      updateUploaded,
      logout,
    } = this.props

    return (
      <main className="layout">
        <div className="auth">
          <div className="auth_placeholder">
            <img
              src={Logo}
              alt="logo"
              className="logo auth_logo"
              style={{ marginBottom: "20px" }}
            />
            <p className="auth_intro" style={{ marginBottom: "80px" }}>
              OTA Update Panel
            </p>
            {(() => {
              if (loading) {
                return <Preloader />
              } else if (updateUploaded) {
                return <UpdateSuccess onOk={this.onOkClickHandler} />
              } else {
                return (
                  <>
                    <UpdateForm
                      currentVersion={currentVersion}
                      onSubmit={(data) => uploadFile(data)}
                    />
                    <p style={{ marginTop: "auto" }}>
                      <Button
                        className="button"
                        type="button"
                        color="primary"
                        style={{ minWidth: "300px", marginBottom: "24px" }}
                        onClick={logout}
                      >
                        Logout
                      </Button>
                    </p>
                  </>
                )
              }
            })()}
          </div>
        </div>
      </main>
    )
  }
}

Update.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
}

const mapStateToProps = (state) => ({
  loading: state.update.loading,
  currentVersion: state.update.currentVersion,
  updateUploaded: state.update.updateUploaded,
})

const mapDispatchToProps = (dispatch) => ({
  uploadFile: (data) => dispatch(actions.uploadFileRequest(data)),
  getCurrentVersion: () => dispatch(actions.getCurrentVersionRequest()),
  logout: () => dispatch(logout()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Update)
