import * as types from "../types"

export const getCurrentVersionRequest = () => ({
  type: types.GET_CURRENT_VERSION_REQUEST,
})

export const getCurrentVersionSuccess = (payload) => ({
  type: types.GET_CURRENT_VERSION_SUCCESS,
  payload,
})

export const getCurrentVersionFailure = (payload) => ({
  type: types.GET_CURRENT_VERSION_FAILURE,
  payload,
})

export const uploadFileRequest = (payload) => ({
  type: types.UPLOAD_FILE_REQUEST,
  payload,
})

export const uploadFileSuccess = (payload) => ({
  type: types.UPLOAD_FILE_SUCCESS,
  payload,
})

export const uploadFileFailure = (payload) => ({
  type: types.UPLOAD_FILE_FAILURE,
  payload,
})
