import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars'

import WeekModule from '../WeekModule'
import DayModule from '../DayModule'
import Preloader from '../../../UI/Preloader'
import NoData from '../../../NoData'

import classNames from 'classnames/bind'

import * as appActions from '../../../../App/actions'
import * as filterActions from '../../../FiltersPanel/actions'


class ReportWeeksView extends React.Component {

    isActiveDay = (data) => {
        return data === this.props.pickedDay
    }

    filterTask = tasks => {
        if (this.props.taskSettingsView) {
            return [tasks.find(task => {
                if (task.task.id === this.props.taskId) {
                    return task
                }
                return []
            })]
        } else {
            return tasks
        }
    }

    taskHandleClick = payload => {
        const {
            selectedDateRange,
            setDateRange,
            setTasksLoading,
            history,
            setTaskSettingsViewModeDay
        } = this.props

        const startDate = moment().day(0).week(payload.week.onum - 1)
        let startDateStr = startDate.format('YYYY-MM-DD')
        if (startDate.isBefore(selectedDateRange[0])) {
            startDateStr = selectedDateRange[0]
        }

        const endDate = moment().day(6).week(payload.week.onum - 1)
        let endDateStr = endDate.format('YYYY-MM-DD')
        if (endDate.isAfter(selectedDateRange[1])) {
            endDateStr = selectedDateRange[1]
        }

        setDateRange([startDateStr, endDateStr])

        setTasksLoading()
        setTaskSettingsViewModeDay()
        history.push("tasksettings/"+payload.taskId)
    }

    render () {
        const { 
            tasksInfoAvg,
            weeks, 
            taskId, 
            loading,
            mode,
            currencyes,
            selectedCurrency,
            selectedDateRange } = this.props
            const reportView = window.location.pathname === '/report'
            const scrollbarsStyles = weeks.length > 6 ? {
                renderTrackVertical:props => <div 
                    {...props} 
                    style={{
                        ...props.style, 
                        backgroundColor: 'rgba(255,255,255,0.1)', 
                        right: '2px',
                        height: '100%',
                        borderRadius: '3px'
                    }}/>,
                renderThumbVertical:props => <div {...props} style={{...props.style, backgroundColor: 'rgba(255,255,255,0.2)', borderRadius: '3px'}}/>
            } : {}
        return (
                !tasksInfoAvg.length ? <NoData /> :
                <div className='days_view_placeholder'>
                    {loading && <Preloader />}
                    {!loading && <div className={classNames('days_view_weeks_placeholder', { report: reportView })}>
                    <Scrollbars 
                        style={{height: '540px', width: 'calc(100% + 10px)'}}
                        {...scrollbarsStyles}
                        >
                        <div className='days_view_weeks_column'>
                            {weeks.length > 1 ? weeks.map((week) => {
                                const start = moment().day(0).week(week.onum);
                                const end = moment().day(6).week(week.onum);
                                if (start.isBefore(selectedDateRange[1]) && end.isAfter(selectedDateRange[0])) {
                                    return (
                                        <WeekModule 
                                            key={week.onum} 
                                            date={week.onum} 
                                            time={true} 
                                            money={false} 
                                            value={week.totalTime}
                                            ratio={mode === 'time' ? week.totalTimeRatio : 1}//week.totalPaymentRatio}
                                            taskId={taskId}
                                            tasksInfo = {week.tasksInfo}
                                            week={week}
                                            mode={mode}
                                            currencyes={currencyes}
                                            selectedcurrency={selectedCurrency}
                                            taskOnClick={this.taskHandleClick}
                                        />  
                                    );
                                }
                                return null;
                            }) : weeks[0].days.map((day) => day && day.inPeriod && (
                                <DayModule
                                    key={day.dateStr}
                                    date={day.dateStr}
                                    disabled={!day.tasksInfo.length}
                                    day={day}
                                    mode={mode}
                                    currencyes={currencyes}
                                    taskOnClick={this.taskHandleClick}
                                />
                            ))}
                        </div>
                    </Scrollbars>
                    </div>}
                </div>
        )
    }
}
const mapStateToProps = state => ({
    loading: state.app.appState.loading,
    weeks: state.app.database.weeks,
    taskSettingsView: state.app.appState.taskSettingsView,
    taskId: state.app.appState.selectedTaskToEdit,
    mode: state.filtersPanel.tasksDisplayMode,
    currencyes: state.app.database.currencies,
    selectedCurrency: state.filtersPanel.filters.currencyes,
    tasksInfoAvg: state.app.database.tasksInfoAvg,
    selectedDateRange: state.filtersPanel.selectedDateRange
  })

const mapDispatchToProps = dispatch => ({
    setTasksLoading: () => dispatch(appActions.setTasksLoading()),
    setDateRange: dateRange => dispatch(filterActions.setDateRange(dateRange)),
    setTaskSettingsViewModeDay: () => dispatch(appActions.setTaskSettingsViewModeDay())
})

export default withRouter(connect(
        mapStateToProps,
        mapDispatchToProps
    )(ReportWeeksView))
