import React from 'react'
import PropTypes from 'prop-types'

import ButtonAddTask from '../../UI/ButtonAddTask'

class TagCheckBox extends React.Component {
  render() {
    const {
      tagTitle,
      isChecked,
      onChange,
    } = this.props

    return (
      <div className="filter_tag_item_placeholder">
        <span>#{tagTitle}</span>
        <ButtonAddTask 
          style={{ transform: "scale(0.6) translateX(8px)" }}
          active={isChecked}
          onClick={() => onChange()}
        />
      </div>
    )
  }
}

TagCheckBox.propTypes = {
  tagTitle: PropTypes.string,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
}

TagCheckBox.defaultProps = {
  tagTitle: '',
  isChecked: false,
  onChange: () => {},
}

export default TagCheckBox
