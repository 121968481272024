import React from 'react'
import PropTypes from 'prop-types'

import Button from '../../../UI/Button'
import classNames from 'classnames/bind'


const CurrenciesControls = ({ onClick, filters, currencyes, disabled, ...props }) => (
  <div 
    className={classNames('controls controls_secondary',{})}
    >
    <Button
          {...props}
          key={'all'}
          className={classNames('button all')}
          active={filters.all}
          onClick={onClick.bind(this,'all')}>
          All
    </Button>  
    {currencyes.map(item => 
        <Button
          {...props}
          key={item.key}
          className={classNames('button', { item })}
          active={filters[item.key]}
          onClick={onClick.bind(this,item.key)}>
          {item.symbol}
        </Button>   
      )
    }
  </div>
)
 
CurrenciesControls.propTypes = {
  onClick: PropTypes.func
}

CurrenciesControls.defaultProps = { 
  onClick: () => {}
}

export default CurrenciesControls