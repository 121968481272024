import React from 'react'
import { connect } from 'react-redux'

import TasksTotalDisplay from './components/TasksTotalDisplay'
import FiltersPanel from '../FiltersPanel'
import TimeMoneyToggler from '../TimeMoneyToggler'
import ReportTasksPanel from './components/ReportTasksPanel'
import ReportWeeksView from './components/ReportWeeksView'
import Layout from '../Layout'
import Preloader from '../UI/Preloader'

import * as appActions from '../../App/actions'

class Report extends React.Component {
  componentDidMount = () => {
    this.props.getReportTasksRequest()
  }

  render() {
    const { loading } = this.props
    const content = (
      <React.Fragment>
        <div className="main_workspace">
          <div className="main_workspace_header">
            <h1>Report</h1>
            {loading && <Preloader mini />}
            {!loading && <TasksTotalDisplay />}
          </div>
          <FiltersPanel />
          {loading && <Preloader />}
          {!loading && <ReportWeeksView />}
        </div>
        <div className="main_controls">
          <TimeMoneyToggler />
          {loading && <Preloader mini />}
          {!loading && <ReportTasksPanel />}
        </div>
      </React.Fragment>
    )
    return <Layout content={content} />
  }
}

const mapStateToProps = state => ({
  loading: state.app.appState.loading
})

const mapDispatchToProps = dispatch => ({
  getReportTasksRequest: () => dispatch(appActions.getReportTasksRequest())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Report)
