import React from 'react'
import { connect } from 'react-redux'
import Button from '../../UI/Button'
import SignUpForm from './components'
import { GoogleLogin } from 'react-google-login'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import Preloader from '../../UI/Preloader'
import Logo from '../../../assets/logo.svg'
import { GOOGLE_CLIENT_ID, FACEBOOK_APP_ID } from '../../../constants'

import * as actions from '../SignIn/actions'

class SignUp extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modalIsOpen: false,
      loginGoogleLoading: null,
      loginFacebookLoading: null,
      loginAppleLoading: null
    }
  }

  responseFacebook = response => {
    const { sendFacebookTokenId } = this.props
    sendFacebookTokenId(response.accessToken)
  }

  responseGoogleSuccess = response => {
    const { sendGoogleTokenId } = this.props
    sendGoogleTokenId(response.tokenId)
  }
  responseGoogleFailure = response => {}

  onAppleSignIn = async () => {
    const { sendAppleTokenId } = this.props
    sendAppleTokenId()
  }

  render() {
    const {
      loginGoogleLoading,
      loginFacebookLoading,
      loginAppleLoading
    } = this.state
    return (
      <main className="layout">
        <div className="auth">
          <div className="auth_placeholder">
            <img src={Logo} alt="logo" className="logo auth_logo" />
            <p className="auth_intro">Sign up</p>
            <div className="auth_social_buttons flex">
              <FacebookLogin
                appId={FACEBOOK_APP_ID}
                fields="name,email"
                cssClass="facebookasd"
                textButton=""
                redirectUri={process.env.REACT_APP_APP_URL + 'auth'}
                buttonStyle={{ all: 'unset' }}
                callback={this.responseFacebook}
                render={renderProps => (
                  <Button
                    onClick={renderProps.onClick}
                    facebook
                    style={{ marginRight: '37px' }}>
                    Sign In with Facebook
                    {loginFacebookLoading && (
                      <Preloader
                        mini
                        style={{ position: 'absolute', right: '20px' }}
                      />
                    )}
                  </Button>
                )}
              />
              <GoogleLogin
                clientId={GOOGLE_CLIENT_ID}
                buttonText="Login"
                onSuccess={this.responseGoogleSuccess}
                onFailure={this.responseGoogleFailure}
                cookiePolicy={'single_host_origin'}
                render={renderProps => (
                  <Button
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    style={{ marginRight: '37px' }}
                    google>
                    Sign In with Google
                    {loginGoogleLoading && (
                      <Preloader
                        mini
                        style={{ position: 'absolute', right: '20px' }}
                      />
                    )}
                  </Button>
                )}
              />
              <Button onClick={this.onAppleSignIn} apple>
                Sign In with Apple
                {loginAppleLoading && (
                  <Preloader
                    mini
                    style={{ position: 'absolute', right: '20px' }}
                  />
                )}
              </Button>
            </div>
            <span className="or">or</span>
            <SignUpForm />
          </div>
        </div>
      </main>
    )
  }
}

const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
  sendGoogleTokenId: token => dispatch(actions.sendGoogleTokenId(token)),
  sendFacebookTokenId: token => dispatch(actions.sendFacebookTokenId(token)),
  sendAppleTokenId: () => dispatch(actions.sendAppleTokenId())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUp)
