import React, { useState } from "react"

const FileInput = ({ value, fileName, onChange }) => {
  const [labelClassName, setLabelClassName] = useState("")

  const onClick = () => {
    if (String(labelClassName).search("MuiInputLabel-shrink") < 0) {
      setLabelClassName(`${labelClassName} MuiInputLabel-shrink`)
    }
  }

  const onChangeHandle = (e) => {
    e.persist()
    onChange(e)
    if (fileName) {
      setLabelClassName(`${labelClassName} MuiFormLabel-filled`)
    } else {
      setLabelClassName(labelClassName.replace("MuiFormLabel-filled", ""))
    }
  }

  return (
    <div
      className="MuiFormControl-root MuiTextField-root file_input"
      style={{ marginBottom: "14px" }}
      onClick={onClick}
    >
      <div
        className={`${labelClassName} MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated`}
        data-shrink="true"
      >
        File
      </div>
      <div
        className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl"
        style={{ marginTop: "16px" }}
      >
        <input
          aria-invalid="false"
          className="MuiInputBase-input MuiInput-input"
          id="file"
          name="file"
          type="file"
          style={{ display: "none" }}
          onChange={(e) => onChangeHandle(e)}
          value={value}
        />
        <label
          className="MuiInputBase-input MuiInput-input file_input_customized"
          data-shrink="true"
          htmlFor="file"
        >
          <span className="file_name">{fileName}</span>
          <span className="file_choose">choose</span>
        </label>
      </div>
    </div>
  )
}

export default FileInput
