import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({ location, path, role, ...rest }) => {
  const getToken = () => localStorage.getItem('token')
  const token = getToken()
  let content

  if (token !== null && token !== 'undefined' && token) {
    if (role === 'admin') {
      if (path !== '/update') {
        content = (
          <Redirect
            to={{
              pathname: '/update',
              from: location
            }}
          />
        )
      } else {
        content = <Route {...rest} />
      }
    } else {
      if (path === '/') {
        content = (
          <Redirect
            to={{
              pathname: '/timesheet',
              from: location
            }}
          />
        )
      } else {
        content = <Route {...rest} />
      }
    }
  } else {
    content = (
      <Route
        render={() => (
          <Redirect
            to={{
              pathname: '/auth',
              from: location
            }}
          />
        )}
      />
    )
  }
  return content
}

export default PrivateRoute
