import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import AppReducer from '../App/reducers'
import ReportReducer from '../components/Report/reducers'
import SignInReducer from '../components/auth/SignIn/reducers'
import SignUpReducer from '../components/auth/SignUp/reducers'
import PasswordReset from '../components/auth/PasswordReset/reducers'
import PasswordChangeReducer from '../components/auth/PaswordChange/reducers'
import CompleteUserInfoReducer from '../components/auth/CompleteUserInfo/reducers'
import FiltersPanelReducer from '../components/FiltersPanel/reducers'
import TaskSettingsReducer from '../components/TaskSettings/reducers'
import Update from '../components/Update/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    app: AppReducer,
    report: ReportReducer,
    signIn: SignInReducer,
    signUp: SignUpReducer,
    passwordReset: PasswordReset,
    passwordChange: PasswordChangeReducer,
    completeUserInfo: CompleteUserInfoReducer,
    filtersPanel: FiltersPanelReducer,
    taskSettings: TaskSettingsReducer,
    update: Update
  })
