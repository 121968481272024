import React from 'react'

import Logo from '../../assets/logo.svg'


class ErrorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null
    }
  }

  componentDidMount() {
    const message = decodeURI(window.location.search.replace(/\?error=/,''))
    this.setState({
      errorMessage: message
    })
  }

  onClickHandlerSignIn = () => {
    this.props.history.push('/')
  }

  render () {
    return (
      <main className="layout">
        <div className="auth">
          <div className="auth_placeholder">
            <img src={Logo} alt="logo" className="logo auth_logo"/>
            <p className="auth_intro" style={{color:'#FFFFFF',marginBottom:'26px'}}>{this.state.errorMessage}</p>
            <span onClick={this.onClickHandlerSignIn} className='link'>Sign in</span>
          </div>
        </div>
      </main>
    )
  }
}


export default ErrorPage