/* eslint-disable  default-case */
import { takeEvery, put, all, call } from 'redux-saga/effects'
import * as types from '../types'
import * as actions from '../actions'
import { base64Converter } from '../../../helpers'

import Api from '../../../services/Api'
import { API_ENDPOINTS } from '../../../constants/index'

function* getCurrentVersion() {
  try {
    const response = yield call(Api.get, API_ENDPOINTS.firmwareGetVersion)
    const { data } = response
    if (data) {
      yield put(actions.getCurrentVersionSuccess(data.version || '-'))
    } else {
      yield put(actions.getCurrentVersionFailure())
    }
  } catch (error) {
    yield put(actions.getCurrentVersionFailure(error))
  }
}
function* uploadFile(action) {
  try {
    const { file, version } = action.payload

    let base64file = yield call(base64Converter, file)

    const preparedBody = {
      body: base64file.replace(/data:application\/(.+);base64,/, ''),
      fileName: file.name,
      published: true,
      type: 'FIRMWARE',
      version: version
    }

    const response = yield call(
      Api.post,
      API_ENDPOINTS.firmwareUpload,
      '',
      preparedBody
    )

    if (response) {
      yield put(actions.uploadFileSuccess())
    } else {
      yield put(actions.uploadFileFailure())
    }
  } catch (error) {
    yield put(actions.uploadFileFailure(error))
  }
}

export default function* uploadSaga() {
  yield all([
    takeEvery(types.GET_CURRENT_VERSION_REQUEST, getCurrentVersion),
    takeEvery(types.UPLOAD_FILE_REQUEST, uploadFile)
  ])
}
