import React from 'react'
import moment from 'moment'

import classNames from 'classnames/bind'

import { taskTotalTaskPaymentFromWeek } from '../../../../helpers'

const DaysModule = ({ 
  children, 
  date, 
  onClick, 
  disabled, 
  taskObj, 
  day,
  mode,
  currencyes,
  selectedcurrency,
  width,
  ...props }) => {
  let taskTotalPaymentFromWeek = []
  if (currencyes) {
    taskTotalPaymentFromWeek = taskTotalTaskPaymentFromWeek([day], taskObj.task.id, taskObj.task.currencyId, currencyes)
  }

  return (
  <div
    {...props}
    className={classNames('week_module report', {'task_settings': onClick !== null}, { 'dummy': day.totalTime[0] === '--' }, {disabled})}
    onClick={onClick}>
    <div className = 'week_module_date_placeholder'>
      <span className="week_module_date">{moment(date, 'YYYY-MM-DD').format('DD MMM')}</span>
      <div className="flex flex-v">
        {mode === 'time' && (day.totalTime[0] === '--' ? <span className="week_module_content_label">—</span> : 
        <div className='week_module_content'>
        <span>
          {day.totalTime[0]}<span className="week_module_content_label">h </span>
        </span>
        <span>
          {day.totalTime[1]}<span className="week_module_content_label">m</span>
        </span>
        </div>
        )}
      </div>
      
      {mode === 'money' && 
         <div className='week_module_content'>
          {day.totalPaymentByCurrencies.length ? 
            <span>
            <span className="week_module_content_label">{taskTotalPaymentFromWeek.symbol}</span>
            <span>{day.totalPaymentByCurrencies[0].value}</span>
          </span>
          : 
            <span className="week_module_content_label">—</span>
          }

        </div>
        }
    </div>
    {
      taskObj &&
      <div 
        className='week_module_data_placeholder'
        style={{flexBasis: 'calc((100% - 119px)*1)'}}
      >
        <div
          className='week_module_task_display_block'
          style={{
            flexBasis: width*100+'%',
            background: taskObj.task.color
            }}
          >
        </div>
      </div>
    }
  </div>
)
}


DaysModule.defaultProps = { 
  onClick: () => {}
}

export default DaysModule
