import React from 'react'

const CalendarIcon = ({active, ...props}) => (
    <svg {...props} className={'calendar_icon '+(active?'active':'')} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className='background' fillRule="evenodd" clipRule="evenodd" d="M0.491699 4.91748H15.5082V14.5629C15.5082 15.4328 14.8274 16.1515 13.9574 16.1515H2.04253C1.21038 16.1515 0.491699 15.4328 0.491699 14.5629V4.91748Z" fill="white" fillOpacity="0.188235" stroke="white" strokeOpacity="0.188235" strokeWidth="0.567376" strokeMiterlimit="22.9256"/>
        <path className='stroke' d="M4.84161 0.869976H9.83452C10.0993 0.869976 10.3262 1.09693 10.3262 1.39953C10.3262 1.6643 10.0993 1.89125 9.83452 1.89125H4.84161V2.68558C4.84161 2.95035 4.61466 3.1773 4.34988 3.1773C4.08511 3.1773 3.85816 2.95035 3.85816 2.68558V0.491726C3.85816 0.22695 4.08511 0 4.34988 0C4.61466 0 4.84161 0.22695 4.84161 0.491726V0.869976ZM12.1418 2.68558C12.1418 2.95035 11.9527 3.1773 11.6501 3.1773C11.3853 3.1773 11.1584 2.95035 11.1584 2.68558V0.491726C11.1584 0.22695 11.3853 0 11.6501 0C11.9527 0 12.1418 0.22695 12.1418 0.491726V0.869976H13.8818C14.487 0.869976 15.0165 1.13475 15.3948 1.513C15.773 1.89125 16 2.4208 16 2.98818V14.487C16 15.0922 15.773 15.6217 15.3948 16C15.0165 16.3782 14.487 16.6052 13.8818 16.6052H2.1182C1.55083 16.6052 1.02128 16.3782 0.605201 16C0.22695 15.6217 0 15.0922 0 14.487V2.98818C0 2.4208 0.22695 1.89125 0.605201 1.513C1.02128 1.13475 1.55083 0.869976 2.1182 0.869976H2.49645C2.79905 0.869976 3.026 1.09693 3.026 1.39953C3.026 1.6643 2.79905 1.89125 2.49645 1.89125H2.1182C1.8156 1.89125 1.55083 2.00473 1.32388 2.23168C1.13475 2.4208 0.983451 2.68558 0.983451 2.98818V4.42553H15.0165V2.98818C15.0165 2.68558 14.8652 2.4208 14.6761 2.23168C14.487 2.00473 14.1844 1.89125 13.8818 1.89125H12.1418V2.68558ZM7.98109 15.6217H13.8818C14.1844 15.6217 14.487 15.4704 14.6761 15.2813C14.8652 15.0922 15.0165 14.7896 15.0165 14.487V5.44681H0.983451V14.487C0.983451 14.7896 1.13475 15.0922 1.32388 15.2813C1.55083 15.4704 1.8156 15.6217 2.1182 15.6217H7.98109Z" fill="#E2E5E8"/>
        <path className='stroke path1' d="M6.35456 12.1421C6.54369 12.3312 6.54369 12.6338 6.35456 12.823C6.16544 13.0121 5.86284 13.0121 5.63589 12.823L3.47986 10.5913L5.63589 8.35962C5.86284 8.17049 6.16544 8.17049 6.35456 8.35962C6.54369 8.54874 6.54369 8.88917 6.35456 9.07829L4.87939 10.5913L6.35456 12.1421Z" fill="white"/>
        <path className='stroke path2' d="M9.83447 9.07829C9.64535 8.88917 9.64535 8.54874 9.83447 8.35962C10.0236 8.17049 10.364 8.17049 10.5531 8.35962L12.7092 10.5913L10.5531 12.823C10.364 13.0121 10.0236 13.0121 9.83447 12.823C9.64535 12.6338 9.64535 12.3312 9.83447 12.1421L11.3096 10.5913L9.83447 9.07829Z" fill="white"/>
    </svg>
)

export default CalendarIcon