import React, { Component } from "react";
import { connect } from 'react-redux'
import { Formik } from "formik";
import { Form } from './EmailForm';
import * as Yup from "yup"

import * as actions from '../actions'


const validationSchema = Yup.object({
  email: Yup.string("Enter your email")
  .email("Enter a valid email")
  .required("Email is required")
})

class EmailForm extends Component {
 constructor(props) {
   super(props);
   this.state = {};
 }

 render() {
    const values = { email: "" }
    const { passwordReset, loading, errorsApi } = this.props
    return (
     <React.Fragment>
      <div
        style={{
          maxWidth: "300px"
        }}
      >
        <Formik
          render={props => <Form 
            { ...props } 
            loading={loading} 
            errorsApi={errorsApi}
        />}
          initialValues={values}
          validationSchema={validationSchema}      
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              passwordReset(values.email)
              setSubmitting(false)
            }, 400);
          }}
        />
      </div>
     </React.Fragment>
   );
 }
}


const mapStateToProps = state => ({
  loading: state.passwordReset.loading,
  errorsApi: state.passwordReset.error
})

const mapDispatchToProps = dispatch => ({
  passwordReset: email => dispatch(actions.passwordReset(email))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps)(EmailForm)