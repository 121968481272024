import * as types from '../types'

export const passwordReset = email => ({
    type: types.PASSWORD_RESET,
    email: email
})

export const passwordResetSuccess = payload => ({
    type: types.PASSWORD_RESET_SUCCESS,
    payload
})

export const passwordResetFailure = payload => ({
    type: types.PASSWORD_RESET_FAILURE,
    payload
})