import * as Icon from '../components/Svg/Icons'

export const API_ENDPOINTS = {
  reportDaily: 'report/daily',
  reportWeekly: 'report/weekly',
  profile: 'api/profile',
  emailAndUserName: 'api/profile/emailAndUserName',
  currencies: 'currencies ',
  tasks: 'api/tasks/',
  taskByPeriod: 'api/tasks/byPeriod',
  passwordResetRequest: 'api/auth/password/requestReset',
  passwordReset: 'api/auth/password/reset',
  authGoogleOrFacebook: 'api/auth/googleOrFacebook',
  appleAuth: 'api/auth/appleid',
  authSignUp: 'api/auth/email/sign-up',
  authSignIn: 'api/auth/email/sign-in',
  deleteAccount: 'api/auth/delete',
  oauth: 'api/oauth',
  authEmailSendVerification: 'api/auth/email/sendVerification',
  exportXlsx: 'api/export/xlsx',
  exportCsv: 'api/export/csv',
  firmwareUpload: 'api/firmware/upload',
  firmwareGetVersion: 'api/firmware/version',
  tasksAllocateTime: 'tasks/allocateTime'
}

export const INITALIZE_APPLICATION = 'INITALIZE_APPLICATION'
export const CHANGE = 'CHANGE'
export const ADD = 'ADD'
export const SUB = 'SUB'
export const SAVE = 'SAVE'

export const GOOGLE_CLIENT_ID =
  '227763039618-n5ca7ojetv8o44k86au0bhg8gg04frcp.apps.googleusercontent.com'
export const FACEBOOK_APP_ID = '1152477785371063'

export const TASK_COLORS = [
  '#FF3736',
  '#FF5473',
  '#FF94A7',
  '#FF8569',
  '#FFBB74',
  '#D17D00',
  '#CF4700',
  '#FD8C07',
  '#E8CCB8',
  '#DFB91E',
  '#FEEB16',
  '#FFEE8B',
  '#CEFB3A',
  '#00C700',
  '#54EB89',
  '#08B5F2',
  '#7A00A2',
  '#DC3193',
  '#9190F8'
]

export const EXCLUDE_ICONS = {
  BRAINSTORMING: Icon.ideas,
  STUDYING: Icon.learning,
  MONEY: Icon.money,
  READING: Icon.read,
  INTERNET: Icon.science,
  BUGS: Icon.beettle,
  MAKETING: Icon.marketing,
}

export const EXCLUDE_KEYS = Object.keys(EXCLUDE_ICONS)

export const ICONS = {
  DEBUG: Icon.beettle,
  CALLS: Icon.calls,
  SALES: Icon.cart,
  BREAK: Icon.coffee,
  CLIENT: Icon.deals,
  FACEBOOK: Icon.fb,
  FITNESS: Icon.fitness,
  GAMES: Icon.game,
  BRAINSTORM: Icon.ideas,
  INSTAGRAM: Icon.insta,
  OFFICE: Icon.invest,
  AGILE: Icon.laps,
  STUDY: Icon.learning,
  EMAILS: Icon.mail,
  MEETING: Icon.meeting,
  CHAT: Icon.messaging,
  FINANCES: Icon.money,
  MUSIC: Icon.music,
  DESIGN: Icon.painting,
  PROJECT: Icon.project,
  CAMERA: Icon.photo,
  READ: Icon.read,
  WEB: Icon.science,
  ADMIN: Icon.secure,
  TEST: Icon.settings,
  REPORT: Icon.stats,
  URGENT: Icon.storm,
  TV: Icon.tv,
  TWITTER: Icon.tw,
  YOUTUBE: Icon.youtube,
  CODE: Icon.code,
  CONSULT: Icon.consult,
  DOCUMENT: Icon.document,
  EDIT: Icon.edit,
  LOGISTICS: Icon.logistics,
  MARKETING: Icon.marketing,
  MEDIA: Icon.media,
  PRESENTATION: Icon.presentation,
  QUOTATION: Icon.quotation,
  SUPPORT: Icon.support,
  UX: Icon.ux,
  WRITE: Icon.write,
  NONE: Icon.none,
  // old
  ...EXCLUDE_ICONS,
}

export const DISPLAY_FORMAT = 'L'
export const ISO_FORMAT = 'YYYY-MM-DD'
export const ISO_MONTH_FORMAT = 'YYYY-MM' // TODO delete this line of dead code on next breaking change

export const START_DATE = 'startDate'
export const END_DATE = 'endDate'

export const HORIZONTAL_ORIENTATION = 'horizontal'
export const VERTICAL_ORIENTATION = 'vertical'
export const VERTICAL_SCROLLABLE = 'verticalScrollable'

export const ICON_BEFORE_POSITION = 'before'
export const ICON_AFTER_POSITION = 'after'

export const INFO_POSITION_TOP = 'top'
export const INFO_POSITION_BOTTOM = 'bottom'
export const INFO_POSITION_BEFORE = 'before'
export const INFO_POSITION_AFTER = 'after'

export const ANCHOR_LEFT = 'left'
export const ANCHOR_RIGHT = 'right'

export const OPEN_DOWN = 'down'
export const OPEN_UP = 'up'

export const DAY_SIZE = 39
export const BLOCKED_MODIFIER = 'blocked'
export const WEEKDAYS = [0, 1, 2, 3, 4, 5, 6]

export const FANG_WIDTH_PX = 20
export const FANG_HEIGHT_PX = 10
export const DEFAULT_VERTICAL_SPACING = 22

export const MODIFIER_KEY_NAMES = new Set(['Shift', 'Control', 'Alt', 'Meta'])

export const CURRENCYES_SYMBOLS = {
  POUND: {
    symbol: '£',
    name: 'Pound'
  },
  EURO: {
    symbol: '€',
    name: 'Euro'
  },
  DOLLAR: {
    symbol: '$',
    name: 'Dollar'
  },
  YUAN: {
    symbol: '¥',
    name: 'Yuan'
  },
  RUBLE: {
    symbol: '₽',
    name: 'Ruble'
  }
}

export const DATE_MOMENT_FORMAT = 'DD/MM/YYYY'
export const NO_DATA_EXCEPTION = 'NO_DATA_EXCEPTION'
