import React from 'react'

const PomodoroIcon = (props) => (
  <svg
    {...props}
    width='11'
    height='12'
    viewBox='0 0 11 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.30679 3.57501C5.77368 3.57501 5.23346 3.57501 4.69325 3.57501C3.691 3.57501 2.77405 3.98728 2.113 4.64834C1.45194 5.30939 1.03967 6.22634 1.03967 7.22858C1.03967 8.23083 1.45194 9.14778 2.113 9.80883C2.77405 10.4699 3.691 10.8822 4.69325 10.8822H6.30679C7.31614 10.8822 8.22598 10.4699 8.88703 9.80883C9.54809 9.14778 9.96036 8.23083 9.96036 7.22858C9.96036 6.22634 9.54809 5.30939 8.88703 4.64834C8.22598 3.98728 7.31614 3.57501 6.30679 3.57501Z'
      fill='white'
      fillOpacity='0.2'
    />
    <path
      d='M5.00597 2.92106C4.99887 2.90684 4.99887 2.88551 4.99887 2.8713V0.987648C4.99887 0.710431 5.22633 0.482971 5.50354 0.482971C5.78076 0.482971 6.00111 0.710431 6.00111 0.987648V2.8713C6.00111 2.88551 6.00111 2.90684 6.00111 2.92106C6.29254 2.86419 6.57687 2.76468 6.82565 2.62251C7.23792 2.38795 7.57911 2.05387 7.8279 1.66292C7.97006 1.42124 8.27571 1.35016 8.51028 1.49232C8.74484 1.63449 8.82303 1.94724 8.67376 2.18181C8.42498 2.58697 8.09801 2.94949 7.70706 3.23381C8.28282 3.45416 8.80171 3.78825 9.2353 4.21473C10.003 4.98952 10.4863 6.05574 10.4863 7.22858C10.4863 8.40141 10.003 9.46052 9.2353 10.2353C8.46052 11.0101 7.3943 11.4863 6.22146 11.4863H4.77851C3.60568 11.4863 2.53946 11.0101 1.77178 10.2353C0.996996 9.46052 0.520752 8.40141 0.520752 7.22858C0.520752 6.05574 0.996996 4.98952 1.77178 4.21473C2.19827 3.78825 2.71716 3.45416 3.29292 3.23381C2.90908 2.94949 2.575 2.58697 2.32621 2.18181C2.18405 1.94014 2.25513 1.63449 2.4897 1.49232C2.72427 1.35016 3.03703 1.42124 3.17919 1.65581C3.42086 2.05387 3.76916 2.38795 4.17432 2.61541C4.43022 2.76468 4.70743 2.86419 5.00597 2.92106ZM6.22146 3.96595C5.73811 3.96595 5.26187 3.96595 4.77851 3.96595C3.88289 3.96595 3.06546 4.33557 2.47548 4.92554C1.88551 5.51552 1.51589 6.33295 1.51589 7.22858C1.51589 8.1242 1.88551 8.93452 2.47548 9.53161C3.06546 10.1216 3.88289 10.4841 4.77851 10.4841H6.22146C7.11709 10.4841 7.93452 10.1216 8.52449 9.53161C9.11447 8.93452 9.48409 8.1242 9.48409 7.22858C9.48409 6.33295 9.11447 5.51552 8.52449 4.92554C7.93452 4.33557 7.11709 3.96595 6.22146 3.96595Z'
      fill='white'
    />
  </svg>
)

export default PomodoroIcon
