import * as types from '../types'

export const passwordChange = data => ({
    type: types.PASSWORD_CHANGE,
    data: data
})

export const passwordChangeSuccess = payload => ({
    type: types.PASSWORD_CHANGE_SUCCESS,
    payload
})

export const passwordChangeFailure = payload => ({
    type: types.PASSWORD_CHANGE_FAILURE,
    payload
})