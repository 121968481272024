import { takeLatest, put, call, all, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import * as types from '../types'
import * as actions from '../actions'
import Api from '../../../../services/Api'
import { API_ENDPOINTS } from '../../../../constants/index'

function* completeUserInfoWorker({ info }) {
  try {
    const appleIdentity = yield select(state => state.signIn.appleError)
    if (!appleIdentity.email) {
      appleIdentity.email = info.email
    }
    if (!appleIdentity.user) {
      appleIdentity.user = {
        firstName: info.firstName,
        lastName: info.lastName
      }
    }
    const {
      data: { user }
    } = yield call(Api.post, API_ENDPOINTS.appleAuth, '', appleIdentity)
    yield put(push('/'))
    yield put(actions.completeUserInfoSuccess(user))
  } catch (error) {
    yield put(actions.completeUserInfoFailure(error))
  }
}

export default function* completeUserInfoSaga() {
  yield all([
    takeLatest(types.COMPLETE_USER_INFORMATION_REQUEST, completeUserInfoWorker)
  ])
}
