import React from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import classNames from 'classnames/bind'


const TimeMoneyControls = ({ onClick, time, money, disabled, mode, ...props }) => (
  <div
    className={classNames('controls')}
  >
    <div className='main_controls_header'>
      <Button
        style={{ fontSize: '15px', height: '36px', cursor: 'pointer' }}
        {...props}
        className={classNames('button', { time })}
        active={mode === 'time'}
        onClick={onClick}>
        Time
      </Button>
      <Button
        style={{ fontSize: '15px', height: '36px' }}
        {...props}
        className={classNames('button', { money })}
        active={mode === 'money'}
        onClick={onClick}>
        Money
      </Button>
    </div>
  </div>
)

TimeMoneyControls.propTypes = {
  onClick: PropTypes.func
}

TimeMoneyControls.defaultProps = {
  onClick: () => {
  }
}


export default TimeMoneyControls
