import React from 'react'
import { connect } from 'react-redux'
import AutosizeInput from 'react-input-autosize'
import { Tooltip } from 'react-tippy'
import classNames from 'classnames'

import ButtonEdit from '../../../../../UI/ButtonEdit'
import Preloader from '../../../../../UI/Preloader'
import * as actions from '../../actions'

import './style.scss'

class EditTaskName extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      readonly: null,
      taskName: '',
      isLengthLimitExceeded: false
    }
  }

  componentDidMount = () => {
    this.setState(state => {
      return {
        readonly: true,
        taskName: this.props.taskName || ''
      }
    })
  }

  removeErrorMessage = () => {
    this.setState({ isLengthLimitExceeded: false })
  }

  handleClick = () => {
    this.setState(state => {
      return {
        readonly: false
      }
    })
  }
  handleSubmit = () => {
    if (this.props.taskName !== this.state.taskName) {
      this.props.editTaskName(this.state.taskName)
    }
    this.setState(state => {
      return { readonly: true }
    })
  }

  handleOnChange = value => {
    if (value.length < 16)
      this.setState({ taskName: value, isLengthLimitExceeded: false })
    else this.setState({ isLengthLimitExceeded: true })
  }

  render() {
    const { loading } = this.props
    const { taskName, isLengthLimitExceeded, readonly } = this.state
    return (
      <div
        className={classNames('task_settings_item', {
          task_settings_item_with_error: isLengthLimitExceeded
        })}>
        <div className="task_settings_item_left">
          <p className="task_settings_item_left_label">Task name</p>
        </div>
        <div className="task_settings_item_right">
          <div className="task_settings_item_form">
            {readonly ? (
              <div className="truncate">
                <Tooltip
                  style={{ width: '100%' }}
                  html={<div>{taskName}</div>}
                  position="top"
                  arrow={true}
                  animation="perspective"
                  theme={'truncate-tooltip'}>
                  <div
                    className="task_settings_item_form_name"
                    style={{
                      display: 'block',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis'
                    }}>
                    {taskName}
                  </div>
                </Tooltip>
              </div>
            ) : (
              <AutosizeInput
                style={{ boxSizing: 'boeder-box' }}
                className="task_settings_item_form_name"
                name="form-field-name"
                readOnly={readonly}
                value={taskName}
                onChange={event => this.handleOnChange(event.target.value)}
                onBlur={this.removeErrorMessage}
              />
            )}
            {loading && <Preloader mini />}
            {!loading &&
              (readonly ? (
                <ButtonEdit onClick={this.handleClick} />
              ) : (
                <ButtonEdit
                  active
                  onClick={this.handleSubmit}
                  disabled={this.state.isSubmitting}
                />
              ))}
          </div>
        </div>
        <span
          className={classNames('task_settings_item_error', {
            task_settings_item_error_shown: isLengthLimitExceeded
          })}>
          Task name must not be longer than 15 characters
        </span>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  taskName: state.app.appState.selectedTaskToEditObj.task.name,
  loading: state.app.appState.editNameLoading
})

const mapDispatchToProps = dispatch => ({
  editTaskName: newName => dispatch(actions.editTaskName(newName))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditTaskName)
