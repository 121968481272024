import React from 'react'
import { connect } from 'react-redux'
import AutosizeInput from 'react-input-autosize'
import { Tooltip } from 'react-tippy'
import classNames from 'classnames'

import ButtonEdit from '../../../../../UI/ButtonEdit'
import Preloader from '../../../../../UI/Preloader'
import * as actions from '../../actions'
import './style.scss'

class EditTaskIcon extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      readonly: null,
      pomodoroTime: '',
      isLengthLimitExceeded: false
    }
  }

  componentDidMount = () => {
    this.setState({
      readonly: true,
      pomodoroTime: this.props.pomodoroTime || ''
    })
  }

  handleClick = () => {
    this.setState({
      readonly: false
    })
  }
  handleSubmit = () => {
    const prevRate = this.props.pomodoroTime
    const nextRate = parseFloat(String(this.state.pomodoroTime))
    if (prevRate !== nextRate) {
      this.props.editTaskPomodoroTime(nextRate)
    }
    this.setState({
      readonly: true,
      pomodoroTime: nextRate
    })
  }

  handleOnChange = e => {
    e.persist()
    const value = e.target.value
    if (value.length < 3 && value.match(/^\d{0,2}$/)) {
      const newValue = value.replace(/[\D]/, '')
      const minutesRegexp = new RegExp(/^([0-5][0-9]||[0-6]0||[1-9])$/)
      const isValid = minutesRegexp.test(value)
      this.setState({
        pomodoroTime: newValue,
        isLengthLimitExceeded: !isValid
      })
    }
  }

  render() {
    const { loading } = this.props
    const { readonly, pomodoroTime, isLengthLimitExceeded } = this.state

    return (
      <div
        className={classNames('task_settings_item', {
          task_settings_item_with_error: isLengthLimitExceeded
        })}>
        <div className="task_settings_item_left">
          <p className="task_settings_item_left_label">Pomodoro</p>
        </div>
        <div className="task_settings_item_right">
          <div className="task_settings_item_form">
            {!readonly && (
              <AutosizeInput
                className="task_settings_item_form_name"
                name="form-field-name"
                readOnly={readonly}
                value={pomodoroTime}
                type="text"
                onChange={this.handleOnChange}
                placeholder={'1 - 60 min'}
              />
            )}
            {readonly && pomodoroTime && (
              <Tooltip
                html={<div>1 - 60 min</div>}
                position="top"
                arrow={true}
                animation="perspective"
                theme="truncate-tooltip">
                <span className="task_settings_pomodoro_display">
                  <span className="digit">
                    {pomodoroTime} <span className="min">min</span>
                  </span>
                </span>
              </Tooltip>
            )}
            {loading && <Preloader mini />}
            {!loading &&
              (this.state.readonly ? (
                <ButtonEdit onClick={this.handleClick} />
              ) : (
                <ButtonEdit
                  active
                  onClick={this.handleSubmit}
                  style={{ background: '#4b5a6c' }}
                  disabled={
                    this.state.isSubmitting ||
                    isLengthLimitExceeded ||
                    !pomodoroTime
                  }
                />
              ))}
          </div>
        </div>
        <span
          className={classNames('task_settings_item_error', {
            task_settings_item_error_shown: isLengthLimitExceeded
          })}>
          Enter a number from 1 to 60
        </span>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  pomodoroTime: state.app.appState.selectedTaskToEditObj.task.pomodoroTime,
  currencyId: state.app.appState.selectedTaskToEditObj.task.currencyId,
  loading: state.app.appState.editTaskPomodoroTimeLoading
})

const mapDispatchToProps = dispatch => ({
  editTaskPomodoroTime: newPomodoroTime =>
    dispatch(actions.editTaskPomodoroTime(newPomodoroTime))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditTaskIcon)
