import React from 'react'
import { connect } from 'react-redux'

import EditTotalTime from './components/EditTotalTime'
import EditStatus from './components/EditStatus'
import EditTaskName from './components/EditTaskName'
import EditTaskIcon from './components/EditTaskIcon'
import EditTaskColor from './components/EditTaskColor'
import EditTaskCurrency from './components/EditTaskCurrency'
import EditTaskHourRate from './components/EditTaskHourRate'
import EditTaskPomodoroTime from './components/EditTaskPomodoroTime'

import clockIcon from '../../../../assets/clock.svg'
import moneyIcon from '../../../../assets/money_icon.svg'
import * as appActions from '../../../../App/actions'
import EditTaskTag from './components/EditTaskTag'

class SettingsTaskPanel extends React.Component {
  handleClick = (payload) => {
    this.props.selectTask(payload)
  }

  render() {
    const {
      pomodoro,
      billable,
      loading,
      mode,
      currencyes,
      selectedTaskToEditObj,
      avgTime,
      avgPayment,
    } = this.props
    let taskSymbol = currencyes.length
      ? currencyes.filter(
          (currency) => currency.id === selectedTaskToEditObj.task.currencyId
        )
      : ''
    if (taskSymbol.length) {
      taskSymbol = taskSymbol[0].symbol
    }
    return (
      <div>
        <div className='tasks_panel_placeholder'>
          <div className='tasks_panel_info'>
            <span className='report_tasks_panel_avg_label'>
              <img
                src={clockIcon}
                alt='hours'
                style={{ width: '20px', height: '20px', marginRight: '4px' }}
              />
              Average per day
            </span>
            {mode === 'time' ? (
              <span className='tasks_panel_selected_hours'>
                <img
                  src={clockIcon}
                  alt='clock-icon'
                  style={{ marginRight: '8px' }}
                />
                {avgTime[0]}
                <span className='mutted'>h&nbsp;</span>
                {avgTime[1]}
                <span className='mutted'>m&nbsp;</span>
              </span>
            ) : (
              <span className='tasks_panel_selected_hours'>
                <img
                  src={moneyIcon}
                  alt='clock-icon'
                  style={{ marginRight: '8px' }}
                />
                <span className='mutted'>{taskSymbol}&nbsp;</span>
                {typeof avgPayment === 'object'
                  ? avgPayment.length
                    ? avgPayment[0].value
                    : 0
                  : avgPayment}
              </span>
            )}
          </div>
          {!loading && <EditTotalTime />}
          {!loading && <EditStatus />}
        </div>
        <div className='tasks_panel_placeholder'>
          {!loading && <EditTaskName />}
          {!loading && <EditTaskTag />}
          {!loading && <EditTaskIcon />}
          {!loading && <EditTaskColor />}
        </div>
        {billable && (
          <div className='tasks_panel_placeholder'>
            {!loading && pomodoro && <EditTaskPomodoroTime />}
            {!loading && <EditTaskCurrency />}
            {!loading && <EditTaskHourRate />}
          </div>
        )}
        {!billable && pomodoro && (
          <div className='tasks_panel_placeholder'>
            {!loading && <EditTaskPomodoroTime />}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  avgTime: state.app.database.avgTime,
  avgPayment: state.app.database.avgPayment,
  billable: state.app.appState.selectedTaskToEditObj.task.billable,
  pomodoro: state.app.appState.selectedTaskToEditObj.task.pomodoro,
  appLoading: state.app.appState.loading,
  loading: state.app.appState.taskLoading,
  mode: state.filtersPanel.tasksDisplayMode,
  currencyes: state.app.database.currencyes,
  selectedTaskToEditObj: state.app.appState.selectedTaskToEditObj,
})

const mapDispatchToProps = (dispatch) => ({
  selectTask: (data) => dispatch(appActions.selectTask(data)),
  getReportTasksRequest: () => dispatch(appActions.getReportTasksRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SettingsTaskPanel)
