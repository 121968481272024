import React from 'react'
import { connect } from 'react-redux'
import AutosizeInput from 'react-input-autosize'
import { Tooltip } from 'react-tippy'
import Truncate from 'react-truncate'

import ButtonEdit from '../../../../../UI/ButtonEdit'
import Preloader from '../../../../../UI/Preloader'
import * as actions from '../../actions'

import './style.scss'
import classNames from 'classnames'

class EditTaskTag extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      readonly: null,
      taskTag: '',
      isLengthLimitExceeded: false
    }
  }

  componentDidMount = () => {
    this.setState(state => {
      return {
        readonly: true,
        taskTag: this.props.taskTag || ''
      }
    })
  }

  removeErrorMessage = () => {
    this.setState({ isLengthLimitExceeded: false })
  }

  handleClick = () => {
    this.setState(state => {
      return {
        readonly: false
      }
    })
  }
  handleSubmit = () => {
    if (this.props.taskTag !== this.state.taskTag) {
      this.props.editTaskTag(this.state.taskTag)
    }
    this.setState(state => {
      return { readonly: true }
    })
  }

  handleOnChange = value => {
    const valueSlicedFirstHashChar = value.slice(1)
    if (valueSlicedFirstHashChar.length < 7)
      this.setState({
        taskTag: valueSlicedFirstHashChar,
        isLengthLimitExceeded: false
      })
    else this.setState({ isLengthLimitExceeded: true })
  }

  render() {
    const { loading } = this.props
    const { taskTag, readonly, isLengthLimitExceeded } = this.state
    return (
      <div
        className={classNames('task_settings_item', {
          task_settings_item_with_error: isLengthLimitExceeded
        })}>
        <div className="task_settings_item_left">
          <p className="task_settings_item_left_label">Tag</p>
        </div>
        <div className="task_settings_item_right">
          <div className="task_settings_item_form">
            {this.state.readonly ? (
              <div className="truncate">
                <Tooltip
                  html={<div style={{ width: 200 }}>{`#${taskTag}`}</div>}
                  position="top"
                  arrow={true}
                  animation="perspective"
                  theme={'truncate-tooltip'}>
                  <Truncate
                    className="task_settings_item_form_name"
                    style={{ display: 'block' }}
                    lines={1}
                    width={165}>
                    {`#${taskTag}`}
                  </Truncate>
                </Tooltip>
              </div>
            ) : (
              <AutosizeInput
                className="task_settings_item_form_name"
                name="form-field-name"
                readOnly={readonly}
                value={`#${taskTag}`}
                onChange={event => this.handleOnChange(event.target.value)}
                autoFocus
                onBlur={this.removeErrorMessage}
              />
            )}
            {loading && <Preloader mini />}
            {!loading &&
              (readonly ? (
                <ButtonEdit onClick={this.handleClick} />
              ) : (
                <ButtonEdit
                  active
                  onClick={this.handleSubmit}
                  disabled={this.state.isSubmitting}
                />
              ))}
          </div>
        </div>
        <span
          className={classNames('task_settings_item_error', {
            task_settings_item_error_shown: isLengthLimitExceeded
          })}>
          Tag must not be longer than 6 characters
        </span>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  taskTag: state.app.appState.selectedTaskToEditObj.task.tag,
  loading: state.app.appState.editTagLoading
})

const mapDispatchToProps = dispatch => ({
  editTaskTag: newTag => dispatch(actions.editTaskTag(newTag))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditTaskTag)
